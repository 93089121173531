:root {
  --flix-primary-brand-color: #73d700;
  --flix-secondary-brand-color: #ffad00;
  --flix-primary-ui-color: #73d700;
  --flix-primary-ui-light-color: #a6ec55;
  --flix-primary-ui-dark-color: #5cac00;
  --flix-secondary-ui-color: #ffad00;
  --flix-secondary-ui-light-color: #ffcd64;
  --flix-secondary-ui-dark-color: #c68600;
  --flix-grayscale-0-color: #fff;
  --flix-grayscale-10-color: #f7f7f7;
  --flix-grayscale-30-color: #e1e1e1;
  --flix-grayscale-50-color: #c8c8c8;
  --flix-grayscale-70-color: #8b8b8b;
  --flix-grayscale-90-color: #757575;
  --flix-grayscale-100-color: #444;
  --flix-neutral-color: #00b5e2;
  --flix-success-color: #73d700;
  --flix-success-dark-color: #5cac00;
  --flix-warning-color: #ffad00;
  --flix-warning-dark-color: #c68600;
  --flix-danger-color: #ff5700;
  --flix-danger-dark-color: #c64300;
  --flix-primary-bg-color: #fff;
  --flix-secondary-bg-color: #f7f7f7;
  --flix-box-bg-color: #fff;
  --flix-strong-highlight-color: #ffad00;
  --flix-primary-content-color: #444;
  --flix-secondary-content-color: #757575;
  --flix-heading-font-color: #444;
  --flix-link-color: #00b5e2;
  --flix-primary-line-color: #e1e1e1;
  --flix-primary-icon-color: #8b8b8b;
  --flix-disabled-element-opacity: 0.5;
  --flix-primary-border-radius: 6px;
  --flix-spacing-xxxs: 3px;
  --flix-spacing-xxs: 6px;
  --flix-spacing-xs: 12px;
  --flix-spacing-smxs: 18px;
  --flix-spacing-sm: 24px;
  --flix-spacing-md: 36px;
  --flix-spacing-lg: 48px;
  --flix-spacing-xl: 72px;
  --flix-page-min-width: 320px;
  --flix-page-max-width: 1200px;
  --flix-box-shadow-color: rgba(0, 0, 0, 0.12);
  --flix-box-shadow-subtle-color: rgba(0, 0, 0, 0.1);
  --flix-primary-box-shadow: 0 0 12px rgba(0, 0, 0, 0.12);
  --flix-icon-arrow-down-active: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M485.3 618L279.2 411.7A20.8 20.8 0 1 1 308.3 382.2L500 573.7 691.7 382A20.8 20.8 0 0 1 721.1 411.5L515.3 617.3C515.1 617.3 515.1 617.7 514.9 617.9A20.8 20.8 0 0 1 485.3 618Z' fill='rgba(255, 173, 0, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-arrow-down: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M485.3 618L279.2 411.7A20.8 20.8 0 1 1 308.3 382.2L500 573.7 691.7 382A20.8 20.8 0 0 1 721.1 411.5L515.3 617.3C515.1 617.3 515.1 617.7 514.9 617.9A20.8 20.8 0 0 1 485.3 618Z' fill='rgba(139, 139, 139, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-arrow-left-active: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M382 485.3L588.3 279.2A20.8 20.8 0 1 1 617.8 308.6L426.3 500 618 691.7A20.8 20.8 0 0 1 588.5 721.1L382.7 515.3C382.7 515.1 382.3 515.1 382.1 514.9A20.8 20.8 0 0 1 382 485.3Z' fill='rgba(255, 173, 0, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-arrow-left: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M382 485.3L588.3 279.2A20.8 20.8 0 1 1 617.8 308.6L426.3 500 618 691.7A20.8 20.8 0 0 1 588.5 721.1L382.7 515.3C382.7 515.1 382.3 515.1 382.1 514.9A20.8 20.8 0 0 1 382 485.3Z' fill='rgba(139, 139, 139, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-arrow-right-active: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M618 514.7L411.7 720.8A20.8 20.8 0 0 1 382.2 691.4L573.7 500 382 308.3A20.8 20.8 0 0 1 411.5 279.2L617.3 485C617.3 485.2 617.7 485.2 618 485.4A20.8 20.8 0 0 1 618 514.7Z' fill='rgba(255, 173, 0, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-arrow-right: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M618 514.7L411.7 720.8A20.8 20.8 0 0 1 382.2 691.4L573.7 500 382 308.3A20.8 20.8 0 0 1 411.5 279.2L617.3 485C617.3 485.2 617.7 485.2 618 485.4A20.8 20.8 0 0 1 618 514.7Z' fill='rgba(139, 139, 139, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-attention-solid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM479.2 270.8A20.8 20.8 0 0 1 520.8 270.8V562.5A20.8 20.8 0 0 1 479.2 562.5ZM500 750A41.7 41.7 0 1 1 541.7 708.3 41.7 41.7 0 0 1 500 750Z' fill='rgba(255, 87, 0, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-calendar: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M458.3 489.4A41.7 41.7 0 1 1 416.7 447.7 41.7 41.7 0 0 1 458.3 489.4ZM583.3 447.7A41.7 41.7 0 1 0 625 489.4 41.7 41.7 0 0 0 583.3 447.7ZM750 447.7A41.7 41.7 0 1 0 791.7 489.4 41.7 41.7 0 0 0 750 447.7ZM416.7 572.7A41.7 41.7 0 1 0 458.3 614.4 41.7 41.7 0 0 0 416.7 572.7ZM583.3 572.7A41.7 41.7 0 1 0 625 614.4 41.7 41.7 0 0 0 583.3 572.7ZM750 572.7A41.7 41.7 0 1 0 791.7 614.4 41.7 41.7 0 0 0 750 572.7ZM250 572.7A41.7 41.7 0 1 0 291.7 614.4 41.7 41.7 0 0 0 250 572.7ZM416.7 697.7A41.7 41.7 0 1 0 458.3 739.4 41.7 41.7 0 0 0 416.7 697.7ZM250 697.7A41.7 41.7 0 1 0 291.7 739.4 41.7 41.7 0 0 0 250 697.7ZM917.1 738.7A125.4 125.4 0 0 1 792.3 864L209 864.8A125.4 125.4 0 0 1 83.7 740L82.9 323.4A125.4 125.4 0 0 1 207.7 198.1H229.2V156A20.8 20.8 0 0 1 270.8 156V198L729.2 197.4V156A20.8 20.8 0 0 1 770.8 156V197.3H791A125.4 125.4 0 0 1 916.2 322L916.2 362.7C916.2 363.3 916.6 363.8 916.6 364.4S916.3 365.5 916.2 366ZM124.6 343.5H874.6V322.1A83.3 83.3 0 0 0 791.3 238.8H770.8V280.9A20.8 20.8 0 1 1 729.2 280.9V239L270.8 239.7V281A20.8 20.8 0 1 1 229.2 281V239.7H207.7A83.3 83.3 0 0 0 124.4 323.1ZM875.4 738.8L874.7 385.2H124.7L125.4 740A83.3 83.3 0 0 0 208.8 823.3L792.1 822.5A83.3 83.3 0 0 0 875.4 738.8Z' fill='rgba(139, 139, 139, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-checkmark-solid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM723.1 368.9L431.4 660.6A21.1 21.1 0 0 1 424.6 665 20.1 20.1 0 0 1 417 666.6L416.7 666.7 416.3 666.7A20 20 0 0 1 408.7 665.1 20.8 20.8 0 0 1 401.9 660.7L276.9 535.7A20.8 20.8 0 0 1 306.4 506.2H306.4L416.7 616.4 693.6 339.4H693.6A20.8 20.8 0 0 1 723.1 368.9Z' fill='rgba(115, 215, 0, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-checkmark: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M21,4.5a.988.988,0,0,0-.707.293L8,17.086,3.708,12.794A.989.989,0,0,0,3,12.5a1,1,0,0,0-.707,1.707l5,5a1,1,0,0,0,1.414,0l13-13A1,1,0,0,0,21,4.5Zm0,1h0Z' style='fill:%2373d700'/%3E%3C/svg%3E");
  --flix-icon-checkbox-indeterminate: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M21,11H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Z' style='fill:%2373d700'/%3E%3C/svg%3E");
  --flix-icon-close: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M827.2 797.8A20.8 20.8 0 0 1 797.8 827.2L500 529.5 202.3 827.2A20.8 20.8 0 0 1 172.8 797.8H172.8L470.5 500 172.8 202.3A20.8 20.8 0 0 1 202.3 172.8H202.3L500 470.5 797.8 172.7H797.8A20.8 20.8 0 0 1 827.2 202.2L529.5 500 827.2 797.8Z' fill='rgba(139, 139, 139, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-radio-checkmark: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3Z' fill='rgba(115, 215, 0, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-danger-solid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM702.2 672.8H702.2A20.8 20.8 0 0 1 672.8 702.2L500 529.5 327.3 702.2A20.8 20.8 0 0 1 297.8 672.8H297.8L470.5 500 297.8 327.3A20.8 20.8 0 0 1 327.3 297.8H327.3L500 470.5 672.8 297.7H672.8A20.8 20.8 0 0 1 702.2 327.2L529.5 500Z' fill='rgba(255, 87, 0, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-danger: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM500 875A375 375 0 1 1 875 500 375.4 375.4 0 0 1 500 875ZM702.2 327.3L529.5 500 702.2 672.8H702.2A20.8 20.8 0 0 1 672.8 702.2L500 529.5 327.3 702.2A20.8 20.8 0 0 1 297.8 672.8H297.8L470.5 500 297.8 327.3A20.8 20.8 0 0 1 327.3 297.8H327.3L500 470.5 672.8 297.7H672.8A20.8 20.8 0 0 1 702.2 327.2Z' fill='rgba(255, 87, 0, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-header-arrow-down: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M485.3 618L279.2 411.7A20.8 20.8 0 1 1 308.3 382.2L500 573.7 691.7 382A20.8 20.8 0 0 1 721.1 411.5L515.3 617.3C515.1 617.3 515.1 617.7 514.9 617.9A20.8 20.8 0 0 1 485.3 618Z' fill='rgba(255, 255, 255, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-info-solid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM520.8 729.2A20.8 20.8 0 0 1 479.2 729.2V437.5A20.8 20.8 0 0 1 520.8 437.5ZM500 333.3A41.7 41.7 0 1 1 541.7 291.7 41.7 41.7 0 0 1 500 333.3Z' fill='rgba(0, 181, 226, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-info: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM500 875A375 375 0 1 1 875 500 375.4 375.4 0 0 1 500 875ZM458.3 291.7A41.7 41.7 0 1 1 500 333.3 41.7 41.7 0 0 1 458.3 291.7ZM520.8 437.5V729.2A20.8 20.8 0 0 1 479.2 729.2V437.5A20.8 20.8 0 0 1 520.8 437.5Z' fill='rgba(0, 181, 226, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-minus: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M916.7 500A20.8 20.8 0 0 1 895.8 520.8H104.2A20.8 20.8 0 0 1 104.2 479.2H895.8A20.8 20.8 0 0 1 916.7 500Z' fill='rgba(139, 139, 139, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-pager-arrow-left-active: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M382 485.3L588.3 279.2A20.8 20.8 0 1 1 617.8 308.6L426.3 500 618 691.7A20.8 20.8 0 0 1 588.5 721.1L382.7 515.3C382.7 515.1 382.3 515.1 382.1 514.9A20.8 20.8 0 0 1 382 485.3Z' fill='rgba(255, 255, 255, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-panel-close: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M827.2 797.8A20.8 20.8 0 0 1 797.8 827.2L500 529.5 202.3 827.2A20.8 20.8 0 0 1 172.8 797.8H172.8L470.5 500 172.8 202.3A20.8 20.8 0 0 1 202.3 172.8H202.3L500 470.5 797.8 172.7H797.8A20.8 20.8 0 0 1 827.2 202.2L529.5 500 827.2 797.8Z' fill='rgba(255, 255, 255, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-plus: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M916.7 500A20.8 20.8 0 0 1 895.8 520.8H520.8V895.8A20.8 20.8 0 0 1 479.2 895.8V520.8H104.2A20.8 20.8 0 0 1 104.2 479.2H479.2V104.2A20.8 20.8 0 0 1 520.8 104.2V479.2H895.8A20.8 20.8 0 0 1 916.7 500Z' fill='rgba(139, 139, 139, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-success-solid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM723.1 368.9L431.4 660.6A21.1 21.1 0 0 1 424.6 665 20.1 20.1 0 0 1 417 666.6L416.7 666.7 416.3 666.7A20 20 0 0 1 408.7 665.1 20.8 20.8 0 0 1 401.9 660.7L276.9 535.7A20.8 20.8 0 0 1 306.4 506.2H306.4L416.7 616.4 693.6 339.4H693.6A20.8 20.8 0 0 1 723.1 368.9Z' fill='rgba(115, 215, 0, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-success: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM500 875A375 375 0 1 1 875 500 375.4 375.4 0 0 1 500 875ZM729.2 354.2A20.8 20.8 0 0 1 723.1 368.9L431.4 660.6A21.1 21.1 0 0 1 424.6 665 20.1 20.1 0 0 1 417 666.6L416.7 666.7 416.3 666.7A20 20 0 0 1 408.7 665.1 20.8 20.8 0 0 1 401.9 660.7L276.9 535.7A20.8 20.8 0 0 1 306.4 506.2H306.4L416.7 616.4 693.6 339.4H693.6A20.8 20.8 0 0 1 729.2 354.2Z' fill='rgba(115, 215, 0, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-time: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM500 875A375 375 0 1 1 875 500 375.4 375.4 0 0 1 500 875ZM639.7 610.3A20.8 20.8 0 0 1 610.2 639.8L485.2 514.8 485 514.3A20 20 0 0 1 480.8 508 20.3 20.3 0 0 1 479.2 500V208.3A20.8 20.8 0 0 1 520.8 208.3V491.4L639.7 610.2Z' fill='rgba(139, 139, 139, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-warning-solid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM479.2 270.8A20.8 20.8 0 0 1 520.8 270.8V562.5A20.8 20.8 0 0 1 479.2 562.5ZM500 750A41.7 41.7 0 1 1 541.7 708.3 41.7 41.7 0 0 1 500 750Z' fill='rgba(255, 173, 0, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-warning: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM500 875A375 375 0 1 1 875 500 375.4 375.4 0 0 1 500 875ZM541.7 708.3A41.7 41.7 0 1 1 500 666.7 41.7 41.7 0 0 1 541.7 708.3ZM479.2 562.5V270.8A20.8 20.8 0 0 1 520.8 270.8V562.5A20.8 20.8 0 0 1 479.2 562.5Z' fill='rgba(255, 173, 0, 0.99)'/%3E%3C/svg%3E");
  --flix-font-family-primary: 'Roboto', 'Arial', sans-serif;
  --flix-font-weight-normal: 400;
  --flix-font-weight-semibold: 400;
  --flix-font-weight-bold: 700;
  --flix-font-size-button: 16px;
  --flix-font-size-fineprint: 12px;
  --flix-font-size-h1: 27px;
  --flix-font-size-h2: 21px;
  --flix-font-size-h3: 18px;
  --flix-font-size-h4: 16px;
  --flix-font-size-primary: 16px;
  --flix-font-size-small: 14px;
  --flix-line-height-fineprint: 15px;
  --flix-line-height-h1: 33px;
  --flix-line-height-h2: 27px;
  --flix-line-height-h3: 24px;
  --flix-line-height-h4: 24px;
  --flix-line-height-primary: 1.5em;
  --flix-line-height-small: 18px;
  --flix-header-bg-color: #73d700;
  --flix-header-bottom-border-color: #73d700;
  --flix-header-nav-bg-color-tablet: #5cac00;
  --flix-header-nav-border-color-tablet: #5cac00;
  --flix-header-color: #fff;
  --flix-data-table-row-select-bg-color: #e5f9c0;
  --flix-input-height-mobile: 44px;
  --flix-input-height-desktop: 36px;
  --flix-input-border-radius: 6px;
  --flix-input-bg-color: #fff;
  --flix-input-info-color: #444;
  --flix-input-border-color: #c8c8c8;
  --flix-input-active-border-color: #757575;
  --flix-input-error-border-color: #ff5700;
  --flix-input-placeholder-color: #757575;
  --flix-input-error-text-color: #ff5700;
  --flix-input-error-info-color: #c64300; }

:root {
  color: #444;
  color: var(--flix-primary-content-color);
  font-family: 'Roboto', 'Arial', sans-serif;
  font-family: var(--flix-font-family-primary);
  font-size: 16px;
  font-size: var(--flix-font-size-primary);
  font-weight: 400;
  font-weight: var(--flix-font-weight-normal);
  line-height: 1.5em;
  line-height: var(--flix-line-height-primary); }

.flix-theme-dark {
  --flix-grayscale-0-color: #353535;
  --flix-grayscale-10-color: #444;
  --flix-grayscale-30-color: #8b8b8b;
  --flix-grayscale-50-color: #c8c8c8;
  --flix-grayscale-70-color: #e1e1e1;
  --flix-grayscale-90-color: #f7f7f7;
  --flix-grayscale-100-color: #fff;
  --flix-primary-bg-color: #353535;
  --flix-secondary-bg-color: #757575;
  --flix-box-bg-color: #444;
  --flix-strong-highlight-color: #ffad00;
  --flix-primary-content-color: #fff;
  --flix-secondary-content-color: #c8c8c8;
  --flix-heading-font-color: #fff;
  --flix-primary-line-color: #757575;
  --flix-primary-icon-color: #fff;
  --flix-disabled-element-opacity: 0.5;
  --flix-box-shadow-color: rgba(0, 0, 0, 0.15);
  --flix-box-shadow-subtle-color: rgba(0, 0, 0, 0.1);
  --flix-primary-box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  --flix-icon-arrow-down: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M485.3 618L279.2 411.7A20.8 20.8 0 1 1 308.3 382.2L500 573.7 691.7 382A20.8 20.8 0 0 1 721.1 411.5L515.3 617.3C515.1 617.3 515.1 617.7 514.9 617.9A20.8 20.8 0 0 1 485.3 618Z' fill='rgba(255, 255, 255, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-arrow-left: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M382 485.3L588.3 279.2A20.8 20.8 0 1 1 617.8 308.6L426.3 500 618 691.7A20.8 20.8 0 0 1 588.5 721.1L382.7 515.3C382.7 515.1 382.3 515.1 382.1 514.9A20.8 20.8 0 0 1 382 485.3Z' fill='rgba(255, 255, 255, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-arrow-right: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M618 514.7L411.7 720.8A20.8 20.8 0 0 1 382.2 691.4L573.7 500 382 308.3A20.8 20.8 0 0 1 411.5 279.2L617.3 485C617.3 485.2 617.7 485.2 618 485.4A20.8 20.8 0 0 1 618 514.7Z' fill='rgba(255, 255, 255, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-calendar: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M458.3 489.4A41.7 41.7 0 1 1 416.7 447.7 41.7 41.7 0 0 1 458.3 489.4ZM583.3 447.7A41.7 41.7 0 1 0 625 489.4 41.7 41.7 0 0 0 583.3 447.7ZM750 447.7A41.7 41.7 0 1 0 791.7 489.4 41.7 41.7 0 0 0 750 447.7ZM416.7 572.7A41.7 41.7 0 1 0 458.3 614.4 41.7 41.7 0 0 0 416.7 572.7ZM583.3 572.7A41.7 41.7 0 1 0 625 614.4 41.7 41.7 0 0 0 583.3 572.7ZM750 572.7A41.7 41.7 0 1 0 791.7 614.4 41.7 41.7 0 0 0 750 572.7ZM250 572.7A41.7 41.7 0 1 0 291.7 614.4 41.7 41.7 0 0 0 250 572.7ZM416.7 697.7A41.7 41.7 0 1 0 458.3 739.4 41.7 41.7 0 0 0 416.7 697.7ZM250 697.7A41.7 41.7 0 1 0 291.7 739.4 41.7 41.7 0 0 0 250 697.7ZM917.1 738.7A125.4 125.4 0 0 1 792.3 864L209 864.8A125.4 125.4 0 0 1 83.7 740L82.9 323.4A125.4 125.4 0 0 1 207.7 198.1H229.2V156A20.8 20.8 0 0 1 270.8 156V198L729.2 197.4V156A20.8 20.8 0 0 1 770.8 156V197.3H791A125.4 125.4 0 0 1 916.2 322L916.2 362.7C916.2 363.3 916.6 363.8 916.6 364.4S916.3 365.5 916.2 366ZM124.6 343.5H874.6V322.1A83.3 83.3 0 0 0 791.3 238.8H770.8V280.9A20.8 20.8 0 1 1 729.2 280.9V239L270.8 239.7V281A20.8 20.8 0 1 1 229.2 281V239.7H207.7A83.3 83.3 0 0 0 124.4 323.1ZM875.4 738.8L874.7 385.2H124.7L125.4 740A83.3 83.3 0 0 0 208.8 823.3L792.1 822.5A83.3 83.3 0 0 0 875.4 738.8Z' fill='rgba(255, 255, 255, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-close: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M827.2 797.8A20.8 20.8 0 0 1 797.8 827.2L500 529.5 202.3 827.2A20.8 20.8 0 0 1 172.8 797.8H172.8L470.5 500 172.8 202.3A20.8 20.8 0 0 1 202.3 172.8H202.3L500 470.5 797.8 172.7H797.8A20.8 20.8 0 0 1 827.2 202.2L529.5 500 827.2 797.8Z' fill='rgba(255, 255, 255, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-plus: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M916.7 500A20.8 20.8 0 0 1 895.8 520.8H520.8V895.8A20.8 20.8 0 0 1 479.2 895.8V520.8H104.2A20.8 20.8 0 0 1 104.2 479.2H479.2V104.2A20.8 20.8 0 0 1 520.8 104.2V479.2H895.8A20.8 20.8 0 0 1 916.7 500Z' fill='rgba(255, 255, 255, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-minus: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M916.7 500A20.8 20.8 0 0 1 895.8 520.8H104.2A20.8 20.8 0 0 1 104.2 479.2H895.8A20.8 20.8 0 0 1 916.7 500Z' fill='rgba(255, 255, 255, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-time: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM500 875A375 375 0 1 1 875 500 375.4 375.4 0 0 1 500 875ZM639.7 610.3A20.8 20.8 0 0 1 610.2 639.8L485.2 514.8 485 514.3A20 20 0 0 1 480.8 508 20.3 20.3 0 0 1 479.2 500V208.3A20.8 20.8 0 0 1 520.8 208.3V491.4L639.7 610.2Z' fill='rgba(255, 255, 255, 0.99)'/%3E%3C/svg%3E");
  --flix-header-bg-color: #444;
  --flix-header-bottom-border-color: #757575;
  --flix-header-nav-bg-color-tablet: #444;
  --flix-header-nav-border-color-tablet: #757575;
  --flix-data-table-row-select-bg-color: #136b00;
  --flix-input-bg-color: #353535;
  --flix-input-info-color: #fff;
  --flix-input-border-color: #c8c8c8;
  --flix-input-active-border-color: #f7f7f7;
  --flix-input-error-border-color: #ff5700;
  --flix-input-placeholder-color: #c8c8c8;
  --flix-input-error-text-color: #ff5700;
  --flix-input-error-info-color: #c64300; }

.flix-theme-dark {
  color: #444;
  color: var(--flix-primary-content-color);
  font-family: 'Roboto', 'Arial', sans-serif;
  font-family: var(--flix-font-family-primary);
  font-size: 16px;
  font-size: var(--flix-font-size-primary);
  font-weight: 400;
  font-weight: var(--flix-font-weight-normal);
  line-height: 1.5em;
  line-height: var(--flix-line-height-primary); }

.flix-theme-kamil {
  --flix-primary-brand-color: #082967;
  --flix-secondary-brand-color: #ffb300;
  --flix-primary-ui-color: #082967;
  --flix-primary-ui-light-color: #3eb5f1;
  --flix-primary-ui-dark-color: #00003c;
  --flix-secondary-ui-color: #ffb300;
  --flix-secondary-ui-light-color: #ffdf4b;
  --flix-secondary-ui-dark-color: #c67e00;
  --flix-neutral-color: #00b5e2;
  --flix-success-color: #44db5e;
  --flix-success-dark-color: #5cac00;
  --flix-warning-color: #ffb300;
  --flix-warning-dark-color: #c68600;
  --flix-danger-color: #ff250e;
  --flix-danger-dark-color: #c64300;
  --flix-strong-highlight-color: #ffb300;
  --flix-icon-checkmark: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M21,4.5a.988.988,0,0,0-.707.293L8,17.086,3.708,12.794A.989.989,0,0,0,3,12.5a1,1,0,0,0-.707,1.707l5,5a1,1,0,0,0,1.414,0l13-13A1,1,0,0,0,21,4.5Zm0,1h0Z' style='fill:%23082967'/%3E%3C/svg%3E");
  --flix-icon-checkbox-indeterminate: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M21,11H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Z' style='fill:%23082967'/%3E%3C/svg%3E");
  --flix-icon-attention-solid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM479.2 270.8A20.8 20.8 0 0 1 520.8 270.8V562.5A20.8 20.8 0 0 1 479.2 562.5ZM500 750A41.7 41.7 0 1 1 541.7 708.3 41.7 41.7 0 0 1 500 750Z' fill='rgba(255, 37, 14, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-checkmark-solid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM723.1 368.9L431.4 660.6A21.1 21.1 0 0 1 424.6 665 20.1 20.1 0 0 1 417 666.6L416.7 666.7 416.3 666.7A20 20 0 0 1 408.7 665.1 20.8 20.8 0 0 1 401.9 660.7L276.9 535.7A20.8 20.8 0 0 1 306.4 506.2H306.4L416.7 616.4 693.6 339.4H693.6A20.8 20.8 0 0 1 723.1 368.9Z' fill='rgba(68, 219, 94, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-success: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM500 875A375 375 0 1 1 875 500 375.4 375.4 0 0 1 500 875ZM729.2 354.2A20.8 20.8 0 0 1 723.1 368.9L431.4 660.6A21.1 21.1 0 0 1 424.6 665 20.1 20.1 0 0 1 417 666.6L416.7 666.7 416.3 666.7A20 20 0 0 1 408.7 665.1 20.8 20.8 0 0 1 401.9 660.7L276.9 535.7A20.8 20.8 0 0 1 306.4 506.2H306.4L416.7 616.4 693.6 339.4H693.6A20.8 20.8 0 0 1 729.2 354.2Z' fill='rgba(68, 219, 94, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-danger: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM500 875A375 375 0 1 1 875 500 375.4 375.4 0 0 1 500 875ZM702.2 327.3L529.5 500 702.2 672.8H702.2A20.8 20.8 0 0 1 672.8 702.2L500 529.5 327.3 702.2A20.8 20.8 0 0 1 297.8 672.8H297.8L470.5 500 297.8 327.3A20.8 20.8 0 0 1 327.3 297.8H327.3L500 470.5 672.8 297.7H672.8A20.8 20.8 0 0 1 702.2 327.2Z' fill='rgba(255, 37, 14, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-success-solid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM723.1 368.9L431.4 660.6A21.1 21.1 0 0 1 424.6 665 20.1 20.1 0 0 1 417 666.6L416.7 666.7 416.3 666.7A20 20 0 0 1 408.7 665.1 20.8 20.8 0 0 1 401.9 660.7L276.9 535.7A20.8 20.8 0 0 1 306.4 506.2H306.4L416.7 616.4 693.6 339.4H693.6A20.8 20.8 0 0 1 723.1 368.9Z' fill='rgba(68, 219, 94, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-danger-solid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM702.2 672.8H702.2A20.8 20.8 0 0 1 672.8 702.2L500 529.5 327.3 702.2A20.8 20.8 0 0 1 297.8 672.8H297.8L470.5 500 297.8 327.3A20.8 20.8 0 0 1 327.3 297.8H327.3L500 470.5 672.8 297.7H672.8A20.8 20.8 0 0 1 702.2 327.2L529.5 500Z' fill='rgba(255, 37, 14, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-radio-checkmark: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3Z' fill='rgba(8, 41, 103, 0.99)'/%3E%3C/svg%3E");
  --flix-header-bg-color: #082967;
  --flix-header-bottom-border-color: #082967;
  --flix-header-nav-bg-color-tablet: #00003c;
  --flix-header-nav-border-color-tablet: #00003c;
  --flix-input-height-mobile: 44px;
  --flix-input-height-desktop: 36px;
  --flix-input-border-radius: 5px;
  --flix-input-info-color: #444;
  --flix-input-border-color: #c8c8c8;
  --flix-input-active-border-color: #757575;
  --flix-input-error-border-color: #ff250e;
  --flix-input-placeholder-color: #757575;
  --flix-input-error-text-color: #ff250e;
  --flix-input-error-info-color: #c64300; }

.flix-theme-kamil {
  color: #444;
  color: var(--flix-primary-content-color);
  font-family: 'Roboto', 'Arial', sans-serif;
  font-family: var(--flix-font-family-primary);
  font-size: 16px;
  font-size: var(--flix-font-size-primary);
  font-weight: 400;
  font-weight: var(--flix-font-weight-normal);
  line-height: 1.5em;
  line-height: var(--flix-line-height-primary); }

.flix-theme-meadow {
  --flix-primary-ui-color: #44b300;
  --flix-primary-ui-light-color: #73d700;
  --flix-primary-ui-dark-color: #228f00;
  --flix-secondary-ui-color: #ff8206;
  --flix-secondary-ui-light-color: #ffad00;
  --flix-secondary-ui-dark-color: #ff5704;
  --flix-neutral-color: #016ae7;
  --flix-success-color: #5cc500;
  --flix-success-dark-color: #31a100;
  --flix-warning-color: #ff9806;
  --flix-warning-dark-color: #ff6c07;
  --flix-danger-color: #dd2828;
  --flix-danger-dark-color: #b31414;
  --flix-strong-highlight-color: #44b300;
  --flix-primary-content-color: #444;
  --flix-secondary-content-color: #757575;
  --flix-heading-font-color: #444;
  --flix-link-color: #0047de;
  --flix-icon-checkmark: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M21,4.5a.988.988,0,0,0-.707.293L8,17.086,3.708,12.794A.989.989,0,0,0,3,12.5a1,1,0,0,0-.707,1.707l5,5a1,1,0,0,0,1.414,0l13-13A1,1,0,0,0,21,4.5Zm0,1h0Z' style='fill:%2344b300'/%3E%3C/svg%3E");
  --flix-icon-checkbox-indeterminate: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M21,11H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Z' style='fill:%2344b300'/%3E%3C/svg%3E");
  --flix-icon-attention-solid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM479.2 270.8A20.8 20.8 0 0 1 520.8 270.8V562.5A20.8 20.8 0 0 1 479.2 562.5ZM500 750A41.7 41.7 0 1 1 541.7 708.3 41.7 41.7 0 0 1 500 750Z' fill='rgba(221, 40, 40, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-checkmark-solid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM723.1 368.9L431.4 660.6A21.1 21.1 0 0 1 424.6 665 20.1 20.1 0 0 1 417 666.6L416.7 666.7 416.3 666.7A20 20 0 0 1 408.7 665.1 20.8 20.8 0 0 1 401.9 660.7L276.9 535.7A20.8 20.8 0 0 1 306.4 506.2H306.4L416.7 616.4 693.6 339.4H693.6A20.8 20.8 0 0 1 723.1 368.9Z' fill='rgba(92, 197, 0, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-arrow-down-active: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M485.3 618L279.2 411.7A20.8 20.8 0 1 1 308.3 382.2L500 573.7 691.7 382A20.8 20.8 0 0 1 721.1 411.5L515.3 617.3C515.1 617.3 515.1 617.7 514.9 617.9A20.8 20.8 0 0 1 485.3 618Z' fill='rgba(68, 179, 0, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-arrow-left-active: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M382 485.3L588.3 279.2A20.8 20.8 0 1 1 617.8 308.6L426.3 500 618 691.7A20.8 20.8 0 0 1 588.5 721.1L382.7 515.3C382.7 515.1 382.3 515.1 382.1 514.9A20.8 20.8 0 0 1 382 485.3Z' fill='rgba(68, 179, 0, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-arrow-right-active: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M618 514.7L411.7 720.8A20.8 20.8 0 0 1 382.2 691.4L573.7 500 382 308.3A20.8 20.8 0 0 1 411.5 279.2L617.3 485C617.3 485.2 617.7 485.2 618 485.4A20.8 20.8 0 0 1 618 514.7Z' fill='rgba(68, 179, 0, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-info: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM500 875A375 375 0 1 1 875 500 375.4 375.4 0 0 1 500 875ZM458.3 291.7A41.7 41.7 0 1 1 500 333.3 41.7 41.7 0 0 1 458.3 291.7ZM520.8 437.5V729.2A20.8 20.8 0 0 1 479.2 729.2V437.5A20.8 20.8 0 0 1 520.8 437.5Z' fill='rgba(1, 106, 231, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-success: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM500 875A375 375 0 1 1 875 500 375.4 375.4 0 0 1 500 875ZM729.2 354.2A20.8 20.8 0 0 1 723.1 368.9L431.4 660.6A21.1 21.1 0 0 1 424.6 665 20.1 20.1 0 0 1 417 666.6L416.7 666.7 416.3 666.7A20 20 0 0 1 408.7 665.1 20.8 20.8 0 0 1 401.9 660.7L276.9 535.7A20.8 20.8 0 0 1 306.4 506.2H306.4L416.7 616.4 693.6 339.4H693.6A20.8 20.8 0 0 1 729.2 354.2Z' fill='rgba(92, 197, 0, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-warning: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM500 875A375 375 0 1 1 875 500 375.4 375.4 0 0 1 500 875ZM541.7 708.3A41.7 41.7 0 1 1 500 666.7 41.7 41.7 0 0 1 541.7 708.3ZM479.2 562.5V270.8A20.8 20.8 0 0 1 520.8 270.8V562.5A20.8 20.8 0 0 1 479.2 562.5Z' fill='rgba(247, 145, 0, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-danger: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM500 875A375 375 0 1 1 875 500 375.4 375.4 0 0 1 500 875ZM702.2 327.3L529.5 500 702.2 672.8H702.2A20.8 20.8 0 0 1 672.8 702.2L500 529.5 327.3 702.2A20.8 20.8 0 0 1 297.8 672.8H297.8L470.5 500 297.8 327.3A20.8 20.8 0 0 1 327.3 297.8H327.3L500 470.5 672.8 297.7H672.8A20.8 20.8 0 0 1 702.2 327.2Z' fill='rgba(221, 40, 40, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-header-arrow-down: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M485.3 618L279.2 411.7A20.8 20.8 0 1 1 308.3 382.2L500 573.7 691.7 382A20.8 20.8 0 0 1 721.1 411.5L515.3 617.3C515.1 617.3 515.1 617.7 514.9 617.9A20.8 20.8 0 0 1 485.3 618Z' fill='rgba(92, 172, 0, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-info-solid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM520.8 729.2A20.8 20.8 0 0 1 479.2 729.2V437.5A20.8 20.8 0 0 1 520.8 437.5ZM500 333.3A41.7 41.7 0 1 1 541.7 291.7 41.7 41.7 0 0 1 500 333.3Z' fill='rgba(1, 106, 231, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-success-solid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM723.1 368.9L431.4 660.6A21.1 21.1 0 0 1 424.6 665 20.1 20.1 0 0 1 417 666.6L416.7 666.7 416.3 666.7A20 20 0 0 1 408.7 665.1 20.8 20.8 0 0 1 401.9 660.7L276.9 535.7A20.8 20.8 0 0 1 306.4 506.2H306.4L416.7 616.4 693.6 339.4H693.6A20.8 20.8 0 0 1 723.1 368.9Z' fill='rgba(92, 197, 0, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-warning-solid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM479.2 270.8A20.8 20.8 0 0 1 520.8 270.8V562.5A20.8 20.8 0 0 1 479.2 562.5ZM500 750A41.7 41.7 0 1 1 541.7 708.3 41.7 41.7 0 0 1 500 750Z' fill='rgba(247, 145, 0, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-danger-solid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM702.2 672.8H702.2A20.8 20.8 0 0 1 672.8 702.2L500 529.5 327.3 702.2A20.8 20.8 0 0 1 297.8 672.8H297.8L470.5 500 297.8 327.3A20.8 20.8 0 0 1 327.3 297.8H327.3L500 470.5 672.8 297.7H672.8A20.8 20.8 0 0 1 702.2 327.2L529.5 500Z' fill='rgba(221, 40, 40, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-radio-checkmark: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3Z' fill='rgba(68, 179, 0, 0.99)'/%3E%3C/svg%3E");
  --flix-header-bg-color: #fff;
  --flix-header-bottom-border-color: #e1e1e1;
  --flix-header-nav-bg-color-tablet: #f7f7f7;
  --flix-header-nav-border-color-tablet: #e1e1e1;
  --flix-header-color: #5cac00;
  --flix-data-table-row-select-bg-color: #e5f9c0;
  --flix-input-height-mobile: 44px;
  --flix-input-height-desktop: 36px;
  --flix-input-border-radius: 6px;
  --flix-input-bg-color: #fff;
  --flix-input-info-color: #444;
  --flix-input-border-color: #c8c8c8;
  --flix-input-active-border-color: #757575;
  --flix-input-error-border-color: #dd2828;
  --flix-input-placeholder-color: #757575;
  --flix-input-error-text-color: #b31414;
  --flix-input-error-info-color: #dd2828; }

.flix-theme-meadow {
  color: #444;
  color: var(--flix-primary-content-color);
  font-family: 'Roboto', 'Arial', sans-serif;
  font-family: var(--flix-font-family-primary);
  font-size: 16px;
  font-size: var(--flix-font-size-primary);
  font-weight: 400;
  font-weight: var(--flix-font-weight-normal);
  line-height: 1.5em;
  line-height: var(--flix-line-height-primary); }

.flix-theme-mfb {
  --flix-primary-brand-color: #73d700;
  --flix-secondary-brand-color: #ffad00;
  --flix-primary-ui-color: #73d700;
  --flix-primary-ui-light-color: #a6ec55;
  --flix-primary-ui-dark-color: #5cac00;
  --flix-secondary-ui-color: #ffad00;
  --flix-secondary-ui-light-color: #ffcd64;
  --flix-secondary-ui-dark-color: #c68600;
  --flix-grayscale-0-color: #fff;
  --flix-grayscale-10-color: #f7f7f7;
  --flix-grayscale-30-color: #e1e1e1;
  --flix-grayscale-50-color: #c8c8c8;
  --flix-grayscale-70-color: #8b8b8b;
  --flix-grayscale-90-color: #757575;
  --flix-grayscale-100-color: #444;
  --flix-neutral-color: #00b5e2;
  --flix-success-color: #73d700;
  --flix-success-dark-color: #5cac00;
  --flix-warning-color: #ffad00;
  --flix-warning-dark-color: #c68600;
  --flix-danger-color: #ff5700;
  --flix-danger-dark-color: #c64300;
  --flix-primary-bg-color: #fff;
  --flix-secondary-bg-color: #f7f7f7;
  --flix-box-bg-color: #fff;
  --flix-strong-highlight-color: #ffad00;
  --flix-primary-content-color: #444;
  --flix-secondary-content-color: #757575;
  --flix-heading-font-color: #444;
  --flix-link-color: #00b5e2;
  --flix-primary-line-color: #e1e1e1;
  --flix-primary-icon-color: #8b8b8b;
  --flix-disabled-element-opacity: 0.5;
  --flix-primary-border-radius: 6px;
  --flix-spacing-xxxs: 3px;
  --flix-spacing-xxs: 6px;
  --flix-spacing-xs: 12px;
  --flix-spacing-smxs: 18px;
  --flix-spacing-sm: 24px;
  --flix-spacing-md: 36px;
  --flix-spacing-lg: 48px;
  --flix-spacing-xl: 72px;
  --flix-page-min-width: 320px;
  --flix-page-max-width: 1200px;
  --flix-box-shadow-color: rgba(0, 0, 0, 0.12);
  --flix-box-shadow-subtle-color: rgba(0, 0, 0, 0.1);
  --flix-primary-box-shadow: 0 0 12px rgba(0, 0, 0, 0.12);
  --flix-icon-arrow-down-active: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M485.3 618L279.2 411.7A20.8 20.8 0 1 1 308.3 382.2L500 573.7 691.7 382A20.8 20.8 0 0 1 721.1 411.5L515.3 617.3C515.1 617.3 515.1 617.7 514.9 617.9A20.8 20.8 0 0 1 485.3 618Z' fill='rgba(255, 173, 0, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-arrow-down: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M485.3 618L279.2 411.7A20.8 20.8 0 1 1 308.3 382.2L500 573.7 691.7 382A20.8 20.8 0 0 1 721.1 411.5L515.3 617.3C515.1 617.3 515.1 617.7 514.9 617.9A20.8 20.8 0 0 1 485.3 618Z' fill='rgba(139, 139, 139, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-arrow-left-active: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M382 485.3L588.3 279.2A20.8 20.8 0 1 1 617.8 308.6L426.3 500 618 691.7A20.8 20.8 0 0 1 588.5 721.1L382.7 515.3C382.7 515.1 382.3 515.1 382.1 514.9A20.8 20.8 0 0 1 382 485.3Z' fill='rgba(255, 173, 0, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-arrow-left: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M382 485.3L588.3 279.2A20.8 20.8 0 1 1 617.8 308.6L426.3 500 618 691.7A20.8 20.8 0 0 1 588.5 721.1L382.7 515.3C382.7 515.1 382.3 515.1 382.1 514.9A20.8 20.8 0 0 1 382 485.3Z' fill='rgba(139, 139, 139, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-arrow-right-active: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M618 514.7L411.7 720.8A20.8 20.8 0 0 1 382.2 691.4L573.7 500 382 308.3A20.8 20.8 0 0 1 411.5 279.2L617.3 485C617.3 485.2 617.7 485.2 618 485.4A20.8 20.8 0 0 1 618 514.7Z' fill='rgba(255, 173, 0, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-arrow-right: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M618 514.7L411.7 720.8A20.8 20.8 0 0 1 382.2 691.4L573.7 500 382 308.3A20.8 20.8 0 0 1 411.5 279.2L617.3 485C617.3 485.2 617.7 485.2 618 485.4A20.8 20.8 0 0 1 618 514.7Z' fill='rgba(139, 139, 139, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-attention-solid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM479.2 270.8A20.8 20.8 0 0 1 520.8 270.8V562.5A20.8 20.8 0 0 1 479.2 562.5ZM500 750A41.7 41.7 0 1 1 541.7 708.3 41.7 41.7 0 0 1 500 750Z' fill='rgba(255, 87, 0, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-calendar: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M458.3 489.4A41.7 41.7 0 1 1 416.7 447.7 41.7 41.7 0 0 1 458.3 489.4ZM583.3 447.7A41.7 41.7 0 1 0 625 489.4 41.7 41.7 0 0 0 583.3 447.7ZM750 447.7A41.7 41.7 0 1 0 791.7 489.4 41.7 41.7 0 0 0 750 447.7ZM416.7 572.7A41.7 41.7 0 1 0 458.3 614.4 41.7 41.7 0 0 0 416.7 572.7ZM583.3 572.7A41.7 41.7 0 1 0 625 614.4 41.7 41.7 0 0 0 583.3 572.7ZM750 572.7A41.7 41.7 0 1 0 791.7 614.4 41.7 41.7 0 0 0 750 572.7ZM250 572.7A41.7 41.7 0 1 0 291.7 614.4 41.7 41.7 0 0 0 250 572.7ZM416.7 697.7A41.7 41.7 0 1 0 458.3 739.4 41.7 41.7 0 0 0 416.7 697.7ZM250 697.7A41.7 41.7 0 1 0 291.7 739.4 41.7 41.7 0 0 0 250 697.7ZM917.1 738.7A125.4 125.4 0 0 1 792.3 864L209 864.8A125.4 125.4 0 0 1 83.7 740L82.9 323.4A125.4 125.4 0 0 1 207.7 198.1H229.2V156A20.8 20.8 0 0 1 270.8 156V198L729.2 197.4V156A20.8 20.8 0 0 1 770.8 156V197.3H791A125.4 125.4 0 0 1 916.2 322L916.2 362.7C916.2 363.3 916.6 363.8 916.6 364.4S916.3 365.5 916.2 366ZM124.6 343.5H874.6V322.1A83.3 83.3 0 0 0 791.3 238.8H770.8V280.9A20.8 20.8 0 1 1 729.2 280.9V239L270.8 239.7V281A20.8 20.8 0 1 1 229.2 281V239.7H207.7A83.3 83.3 0 0 0 124.4 323.1ZM875.4 738.8L874.7 385.2H124.7L125.4 740A83.3 83.3 0 0 0 208.8 823.3L792.1 822.5A83.3 83.3 0 0 0 875.4 738.8Z' fill='rgba(139, 139, 139, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-checkmark-solid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM723.1 368.9L431.4 660.6A21.1 21.1 0 0 1 424.6 665 20.1 20.1 0 0 1 417 666.6L416.7 666.7 416.3 666.7A20 20 0 0 1 408.7 665.1 20.8 20.8 0 0 1 401.9 660.7L276.9 535.7A20.8 20.8 0 0 1 306.4 506.2H306.4L416.7 616.4 693.6 339.4H693.6A20.8 20.8 0 0 1 723.1 368.9Z' fill='rgba(115, 215, 0, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-checkmark: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M21,4.5a.988.988,0,0,0-.707.293L8,17.086,3.708,12.794A.989.989,0,0,0,3,12.5a1,1,0,0,0-.707,1.707l5,5a1,1,0,0,0,1.414,0l13-13A1,1,0,0,0,21,4.5Zm0,1h0Z' style='fill:%2373d700'/%3E%3C/svg%3E");
  --flix-icon-checkbox-indeterminate: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M21,11H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Z' style='fill:%2373d700'/%3E%3C/svg%3E");
  --flix-icon-close: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M827.2 797.8A20.8 20.8 0 0 1 797.8 827.2L500 529.5 202.3 827.2A20.8 20.8 0 0 1 172.8 797.8H172.8L470.5 500 172.8 202.3A20.8 20.8 0 0 1 202.3 172.8H202.3L500 470.5 797.8 172.7H797.8A20.8 20.8 0 0 1 827.2 202.2L529.5 500 827.2 797.8Z' fill='rgba(139, 139, 139, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-radio-checkmark: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3Z' fill='rgba(115, 215, 0, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-danger-solid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM702.2 672.8H702.2A20.8 20.8 0 0 1 672.8 702.2L500 529.5 327.3 702.2A20.8 20.8 0 0 1 297.8 672.8H297.8L470.5 500 297.8 327.3A20.8 20.8 0 0 1 327.3 297.8H327.3L500 470.5 672.8 297.7H672.8A20.8 20.8 0 0 1 702.2 327.2L529.5 500Z' fill='rgba(255, 87, 0, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-danger: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM500 875A375 375 0 1 1 875 500 375.4 375.4 0 0 1 500 875ZM702.2 327.3L529.5 500 702.2 672.8H702.2A20.8 20.8 0 0 1 672.8 702.2L500 529.5 327.3 702.2A20.8 20.8 0 0 1 297.8 672.8H297.8L470.5 500 297.8 327.3A20.8 20.8 0 0 1 327.3 297.8H327.3L500 470.5 672.8 297.7H672.8A20.8 20.8 0 0 1 702.2 327.2Z' fill='rgba(255, 87, 0, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-header-arrow-down: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M485.3 618L279.2 411.7A20.8 20.8 0 1 1 308.3 382.2L500 573.7 691.7 382A20.8 20.8 0 0 1 721.1 411.5L515.3 617.3C515.1 617.3 515.1 617.7 514.9 617.9A20.8 20.8 0 0 1 485.3 618Z' fill='rgba(255, 255, 255, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-info-solid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM520.8 729.2A20.8 20.8 0 0 1 479.2 729.2V437.5A20.8 20.8 0 0 1 520.8 437.5ZM500 333.3A41.7 41.7 0 1 1 541.7 291.7 41.7 41.7 0 0 1 500 333.3Z' fill='rgba(0, 181, 226, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-info: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM500 875A375 375 0 1 1 875 500 375.4 375.4 0 0 1 500 875ZM458.3 291.7A41.7 41.7 0 1 1 500 333.3 41.7 41.7 0 0 1 458.3 291.7ZM520.8 437.5V729.2A20.8 20.8 0 0 1 479.2 729.2V437.5A20.8 20.8 0 0 1 520.8 437.5Z' fill='rgba(0, 181, 226, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-minus: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M916.7 500A20.8 20.8 0 0 1 895.8 520.8H104.2A20.8 20.8 0 0 1 104.2 479.2H895.8A20.8 20.8 0 0 1 916.7 500Z' fill='rgba(139, 139, 139, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-pager-arrow-left-active: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M382 485.3L588.3 279.2A20.8 20.8 0 1 1 617.8 308.6L426.3 500 618 691.7A20.8 20.8 0 0 1 588.5 721.1L382.7 515.3C382.7 515.1 382.3 515.1 382.1 514.9A20.8 20.8 0 0 1 382 485.3Z' fill='rgba(255, 255, 255, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-panel-close: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M827.2 797.8A20.8 20.8 0 0 1 797.8 827.2L500 529.5 202.3 827.2A20.8 20.8 0 0 1 172.8 797.8H172.8L470.5 500 172.8 202.3A20.8 20.8 0 0 1 202.3 172.8H202.3L500 470.5 797.8 172.7H797.8A20.8 20.8 0 0 1 827.2 202.2L529.5 500 827.2 797.8Z' fill='rgba(255, 255, 255, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-plus: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M916.7 500A20.8 20.8 0 0 1 895.8 520.8H520.8V895.8A20.8 20.8 0 0 1 479.2 895.8V520.8H104.2A20.8 20.8 0 0 1 104.2 479.2H479.2V104.2A20.8 20.8 0 0 1 520.8 104.2V479.2H895.8A20.8 20.8 0 0 1 916.7 500Z' fill='rgba(139, 139, 139, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-success-solid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM723.1 368.9L431.4 660.6A21.1 21.1 0 0 1 424.6 665 20.1 20.1 0 0 1 417 666.6L416.7 666.7 416.3 666.7A20 20 0 0 1 408.7 665.1 20.8 20.8 0 0 1 401.9 660.7L276.9 535.7A20.8 20.8 0 0 1 306.4 506.2H306.4L416.7 616.4 693.6 339.4H693.6A20.8 20.8 0 0 1 723.1 368.9Z' fill='rgba(115, 215, 0, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-success: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM500 875A375 375 0 1 1 875 500 375.4 375.4 0 0 1 500 875ZM729.2 354.2A20.8 20.8 0 0 1 723.1 368.9L431.4 660.6A21.1 21.1 0 0 1 424.6 665 20.1 20.1 0 0 1 417 666.6L416.7 666.7 416.3 666.7A20 20 0 0 1 408.7 665.1 20.8 20.8 0 0 1 401.9 660.7L276.9 535.7A20.8 20.8 0 0 1 306.4 506.2H306.4L416.7 616.4 693.6 339.4H693.6A20.8 20.8 0 0 1 729.2 354.2Z' fill='rgba(115, 215, 0, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-time: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM500 875A375 375 0 1 1 875 500 375.4 375.4 0 0 1 500 875ZM639.7 610.3A20.8 20.8 0 0 1 610.2 639.8L485.2 514.8 485 514.3A20 20 0 0 1 480.8 508 20.3 20.3 0 0 1 479.2 500V208.3A20.8 20.8 0 0 1 520.8 208.3V491.4L639.7 610.2Z' fill='rgba(139, 139, 139, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-warning-solid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM479.2 270.8A20.8 20.8 0 0 1 520.8 270.8V562.5A20.8 20.8 0 0 1 479.2 562.5ZM500 750A41.7 41.7 0 1 1 541.7 708.3 41.7 41.7 0 0 1 500 750Z' fill='rgba(255, 173, 0, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-warning: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM500 875A375 375 0 1 1 875 500 375.4 375.4 0 0 1 500 875ZM541.7 708.3A41.7 41.7 0 1 1 500 666.7 41.7 41.7 0 0 1 541.7 708.3ZM479.2 562.5V270.8A20.8 20.8 0 0 1 520.8 270.8V562.5A20.8 20.8 0 0 1 479.2 562.5Z' fill='rgba(255, 173, 0, 0.99)'/%3E%3C/svg%3E");
  --flix-font-family-primary: 'Roboto Condensed', 'Arial', sans-serif;
  --flix-font-weight-normal: 300;
  --flix-font-weight-semibold: 400;
  --flix-font-weight-bold: 700;
  --flix-font-size-button: 18px;
  --flix-font-size-fineprint: 14px;
  --flix-font-size-h1: 28px;
  --flix-font-size-h2: 22px;
  --flix-font-size-h3: 18px;
  --flix-font-size-h4: 16px;
  --flix-font-size-primary: 16px;
  --flix-font-size-small: 14px;
  --flix-line-height-fineprint: 17px;
  --flix-line-height-h1: 34px;
  --flix-line-height-h2: 28px;
  --flix-line-height-h3: 24px;
  --flix-line-height-h4: 22px;
  --flix-line-height-primary: 1.375em;
  --flix-line-height-small: 17px;
  --flix-header-bg-color: #73d700;
  --flix-header-bottom-border-color: #73d700;
  --flix-header-nav-bg-color-tablet: #5cac00;
  --flix-header-nav-border-color-tablet: #5cac00;
  --flix-header-color: #fff;
  --flix-data-table-row-select-bg-color: #e5f9c0;
  --flix-input-height-mobile: 44px;
  --flix-input-height-desktop: 36px;
  --flix-input-border-radius: 6px;
  --flix-input-bg-color: #fff;
  --flix-input-info-color: #444;
  --flix-input-border-color: #c8c8c8;
  --flix-input-active-border-color: #757575;
  --flix-input-error-border-color: #ff5700;
  --flix-input-placeholder-color: #757575;
  --flix-input-error-text-color: #ff5700;
  --flix-input-error-info-color: #c64300; }

.flix-theme-mfb {
  color: #444;
  color: var(--flix-primary-content-color);
  font-family: 'Roboto', 'Arial', sans-serif;
  font-family: var(--flix-font-family-primary);
  font-size: 16px;
  font-size: var(--flix-font-size-primary);
  font-weight: 400;
  font-weight: var(--flix-font-weight-normal);
  line-height: 1.5em;
  line-height: var(--flix-line-height-primary); }

* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

[class^='flix-'] {
  margin: 0;
  padding: 0; }
  [class^='flix-']::before, [class^='flix-']::after {
    -webkit-box-sizing: border-box;
            box-sizing: border-box; }

@font-face {
  font-family: 'flixicons';
  font-style: normal;
  font-weight: normal;
  src: url("../font/flix.woff2?1635175954183") format("woff2"), url("../font/flix.woff?1635175954183") format("woff"), url("../font/flix.ttf?1635175954183") format("truetype");
  font-display: block; }

.flix-icon-account-attention-solid:before {
  content: '\e800'; }

.flix-icon-account-attention:before {
  content: '\e801'; }

.flix-icon-account-settings-solid:before {
  content: '\e802'; }

.flix-icon-account-settings:before {
  content: '\e803'; }

.flix-icon-account-solid:before {
  content: '\e804'; }

.flix-icon-account:before {
  content: '\e805'; }

.flix-icon-airport-solid:before {
  content: '\e806'; }

.flix-icon-airport:before {
  content: '\e807'; }

.flix-icon-anti-virus-solid:before {
  content: '\e808'; }

.flix-icon-anti-virus:before {
  content: '\e809'; }

.flix-icon-apps-solid:before {
  content: '\e80a'; }

.flix-icon-apps:before {
  content: '\e80b'; }

.flix-icon-archive-solid:before {
  content: '\e80c'; }

.flix-icon-archive:before {
  content: '\e80d'; }

.flix-icon-arrow-big-dots-right:before {
  content: '\e80e'; }

.flix-icon-arrow-big-down-left:before {
  content: '\e80f'; }

.flix-icon-arrow-big-down-right:before {
  content: '\e810'; }

.flix-icon-arrow-big-down:before {
  content: '\e811'; }

.flix-icon-arrow-big-left:before {
  content: '\e812'; }

.flix-icon-arrow-big-right:before {
  content: '\e813'; }

.flix-icon-arrow-big-up-left:before {
  content: '\e814'; }

.flix-icon-arrow-big-up-right:before {
  content: '\e815'; }

.flix-icon-arrow-big-up:before {
  content: '\e816'; }

.flix-icon-arrow-down:before {
  content: '\e817'; }

.flix-icon-arrow-left:before {
  content: '\e818'; }

.flix-icon-arrow-right:before {
  content: '\e819'; }

.flix-icon-arrow-up:before {
  content: '\e81a'; }

.flix-icon-attention-solid:before {
  content: '\e81b'; }

.flix-icon-attention:before {
  content: '\e81c'; }

.flix-icon-audio-solid:before {
  content: '\e81d'; }

.flix-icon-audio:before {
  content: '\e81e'; }

.flix-icon-bar-graph-solid:before {
  content: '\e81f'; }

.flix-icon-battery-solid:before {
  content: '\e820'; }

.flix-icon-battery:before {
  content: '\e821'; }

.flix-icon-beach-solid:before {
  content: '\e822'; }

.flix-icon-beach:before {
  content: '\e823'; }

.flix-icon-beer-solid:before {
  content: '\e824'; }

.flix-icon-beer:before {
  content: '\e825'; }

.flix-icon-bike-solid:before {
  content: '\e826'; }

.flix-icon-bike:before {
  content: '\e827'; }

.flix-icon-birthday-solid:before {
  content: '\e828'; }

.flix-icon-birthday:before {
  content: '\e829'; }

.flix-icon-book-solid:before {
  content: '\e82a'; }

.flix-icon-book:before {
  content: '\e82b'; }

.flix-icon-booster-solid:before {
  content: '\e82c'; }

.flix-icon-booster:before {
  content: '\e82d'; }

.flix-icon-bulb-solid:before {
  content: '\e82e'; }

.flix-icon-bulb:before {
  content: '\e82f'; }

.flix-icon-burger:before {
  content: '\e830'; }

.flix-icon-bus-clean-solid:before {
  content: '\e831'; }

.flix-icon-bus-clean:before {
  content: '\e832'; }

.flix-icon-bus-double-solid:before {
  content: '\e833'; }

.flix-icon-bus-double:before {
  content: '\e834'; }

.flix-icon-bus-nicht-solid:before {
  content: '\e835'; }

.flix-icon-bus-night:before {
  content: '\e836'; }

.flix-icon-bus-service-solid:before {
  content: '\e837'; }

.flix-icon-bus-service:before {
  content: '\e838'; }

.flix-icon-bus-solid:before {
  content: '\e839'; }

.flix-icon-bus-stop-no-solid:before {
  content: '\e83a'; }

.flix-icon-bus-stop-no:before {
  content: '\e83b'; }

.flix-icon-bus-stop-solid:before {
  content: '\e83c'; }

.flix-icon-bus-stop:before {
  content: '\e83d'; }

.flix-icon-bus-time-solid:before {
  content: '\e83e'; }

.flix-icon-bus-time:before {
  content: '\e83f'; }

.flix-icon-bus:before {
  content: '\e840'; }

.flix-icon-buses-solid:before {
  content: '\e841'; }

.flix-icon-buses:before {
  content: '\e842'; }

.flix-icon-calendar-solid:before {
  content: '\e843'; }

.flix-icon-calendar:before {
  content: '\e844'; }

.flix-icon-call-solid:before {
  content: '\e845'; }

.flix-icon-call:before {
  content: '\e846'; }

.flix-icon-camera-solid:before {
  content: '\e847'; }

.flix-icon-camera:before {
  content: '\e848'; }

.flix-icon-car-seat-solid:before {
  content: '\e84d'; }

.flix-icon-car-seat:before {
  content: '\e84e'; }

.flix-icon-car-solid:before {
  content: '\e84f'; }

.flix-icon-car:before {
  content: '\e850'; }

.flix-icon-card-swipe-solid:before {
  content: '\e851'; }

.flix-icon-card-swipe:before {
  content: '\e852'; }

.flix-icon-cart-solid:before {
  content: '\e853'; }

.flix-icon-cart:before {
  content: '\e854'; }

.flix-icon-cash-solid:before {
  content: '\e855'; }

.flix-icon-cash:before {
  content: '\e856'; }

.flix-icon-chat-alt-solid:before {
  content: '\e857'; }

.flix-icon-chat-alt:before {
  content: '\e858'; }

.flix-icon-chat-solid:before {
  content: '\e859'; }

.flix-icon-chat:before {
  content: '\e85a'; }

.flix-icon-check-solid:before {
  content: '\e85b'; }

.flix-icon-check:before {
  content: '\e85c'; }

.flix-icon-checkmark-strong-solid:before {
  content: '\e85d'; }

.flix-icon-checkmark-strong:before {
  content: '\e85e'; }

.flix-icon-checkmark:before {
  content: '\e85f'; }

.flix-icon-child-solid:before {
  content: '\e860'; }

.flix-icon-child:before {
  content: '\e861'; }

.flix-icon-circle-solid:before {
  content: '\e862'; }

.flix-icon-circle:before {
  content: '\e863'; }

.flix-icon-city-solid:before {
  content: '\e864'; }

.flix-icon-city:before {
  content: '\e865'; }

.flix-icon-close:before {
  content: '\e866'; }

.flix-icon-collaboration-solid:before {
  content: '\e867'; }

.flix-icon-collaboration:before {
  content: '\e868'; }

.flix-icon-collapse:before {
  content: '\e869'; }

.flix-icon-compass-solid:before {
  content: '\e86a'; }

.flix-icon-compass:before {
  content: '\e86b'; }

.flix-icon-copy:before {
  content: '\e86c'; }

.flix-icon-credit-card-solid:before {
  content: '\e86d'; }

.flix-icon-credit-card:before {
  content: '\e86e'; }

.flix-icon-crossed-solid:before {
  content: '\e86f'; }

.flix-icon-crossed:before {
  content: '\e870'; }

.flix-icon-customer-solid:before {
  content: '\e871'; }

.flix-icon-customer:before {
  content: '\e872'; }

.flix-icon-delete-solid:before {
  content: '\e873'; }

.flix-icon-delete:before {
  content: '\e874'; }

.flix-icon-desktop-solid:before {
  content: '\e875'; }

.flix-icon-desktop:before {
  content: '\e876'; }

.flix-icon-directions-solid:before {
  content: '\e877'; }

.flix-icon-directions:before {
  content: '\e878'; }

.flix-icon-discount-solid:before {
  content: '\e879'; }

.flix-icon-discount:before {
  content: '\e87a'; }

.flix-icon-disinfected-solid:before {
  content: '\e87b'; }

.flix-icon-disinfected:before {
  content: '\e87c'; }

.flix-icon-document-solid:before {
  content: '\e87d'; }

.flix-icon-document:before {
  content: '\e87e'; }

.flix-icon-dog-solid:before {
  content: '\e87f'; }

.flix-icon-dog:before {
  content: '\e880'; }

.flix-icon-download:before {
  content: '\e881'; }

.flix-icon-drinks-solid:before {
  content: '\e882'; }

.flix-icon-drinks:before {
  content: '\e883'; }

.flix-icon-driver-no-talk:before {
  content: '\e884'; }

.flix-icon-driver-solid:before {
  content: '\e885'; }

.flix-icon-driver:before {
  content: '\e886'; }

.flix-icon-ebus-solid:before {
  content: '\e887'; }

.flix-icon-ebus:before {
  content: '\e888'; }

.flix-icon-edit-solid:before {
  content: '\e889'; }

.flix-icon-edit:before {
  content: '\e88a'; }

.flix-icon-entertainment-solid:before {
  content: '\e88b'; }

.flix-icon-entertainment:before {
  content: '\e88c'; }

.flix-icon-expand:before {
  content: '\e88d'; }

.flix-icon-eye-no-solid:before {
  content: '\e88e'; }

.flix-icon-eye-no:before {
  content: '\e88f'; }

.flix-icon-eye-solid:before {
  content: '\e890'; }

.flix-icon-eye:before {
  content: '\e891'; }

.flix-icon-face-touch:before {
  content: '\e892'; }

.flix-icon-facebook:before {
  content: '\e893'; }

.flix-icon-female:before {
  content: '\e894'; }

.flix-icon-ferry-solid:before {
  content: '\e895'; }

.flix-icon-ferry:before {
  content: '\e896'; }

.flix-icon-filter:before {
  content: '\e897'; }

.flix-icon-flag-solid:before {
  content: '\e898'; }

.flix-icon-flag:before {
  content: '\e899'; }

.flix-icon-flixtech-solid:before {
  content: '\e89a'; }

.flix-icon-flixtech:before {
  content: '\e89b'; }

.flix-icon-forbidden-solid:before {
  content: '\e89c'; }

.flix-icon-forbidden:before {
  content: '\e89d'; }

.flix-icon-forrest-solid:before {
  content: '\e89e'; }

.flix-icon-forrest:before {
  content: '\e89f'; }

.flix-icon-full-screen-solid:before {
  content: '\e8a0'; }

.flix-icon-full-screen:before {
  content: '\e8a1'; }

.flix-icon-games-solid:before {
  content: '\e8a2'; }

.flix-icon-games:before {
  content: '\e8a3'; }

.flix-icon-gas-pump-solid:before {
  content: '\e8a4'; }

.flix-icon-gas-pump:before {
  content: '\e8a5'; }

.flix-icon-gears-solid:before {
  content: '\e8a6'; }

.flix-icon-gears-time-solid:before {
  content: '\e8a7'; }

.flix-icon-gears-time:before {
  content: '\e8a8'; }

.flix-icon-gears:before {
  content: '\e8a9'; }

.flix-icon-graphs-solid:before {
  content: '\e8aa'; }

.flix-icon-graphs:before {
  content: '\e8ab'; }

.flix-icon-group-big-solid:before {
  content: '\e8ac'; }

.flix-icon-group-business-solid:before {
  content: '\e8ad'; }

.flix-icon-group-discussion-solid:before {
  content: '\e8ae'; }

.flix-icon-group-small-solid:before {
  content: '\e8af'; }

.flix-icon-group-solid:before {
  content: '\e8b0'; }

.flix-icon-group-success-solid:before {
  content: '\e8b1'; }

.flix-icon-group:before {
  content: '\e8b2'; }

.flix-icon-guitar-solid:before {
  content: '\e8b3'; }

.flix-icon-guitar:before {
  content: '\e8b4'; }

.flix-icon-headphones-solid:before {
  content: '\e8b5'; }

.flix-icon-headphones:before {
  content: '\e8b6'; }

.flix-icon-heart-solid:before {
  content: '\e8b7'; }

.flix-icon-heart:before {
  content: '\e8b8'; }

.flix-icon-hierachy-solid:before {
  content: '\e8b9'; }

.flix-icon-home-solid:before {
  content: '\e8ba'; }

.flix-icon-home:before {
  content: '\e8bb'; }

.flix-icon-info-solid:before {
  content: '\e8bc'; }

.flix-icon-info:before {
  content: '\e8bd'; }

.flix-icon-injected:before {
  content: '\e8be'; }

.flix-icon-instagram:before {
  content: '\e8bf'; }

.flix-icon-interconnection-solid:before {
  content: '\e8c0'; }

.flix-icon-interconnection:before {
  content: '\e8c1'; }

.flix-icon-invoice-solid:before {
  content: '\e8c2'; }

.flix-icon-invoice:before {
  content: '\e8c3'; }

.flix-icon-kebab:before {
  content: '\e8c4'; }

.flix-icon-label-attention-solid:before {
  content: '\e8c5'; }

.flix-icon-label-attention:before {
  content: '\e8c6'; }

.flix-icon-label-solid:before {
  content: '\e8c7'; }

.flix-icon-label:before {
  content: '\e8c8'; }

.flix-icon-leaf-solid:before {
  content: '\e8c9'; }

.flix-icon-leaf:before {
  content: '\e8ca'; }

.flix-icon-link:before {
  content: '\e8cb'; }

.flix-icon-linkedin:before {
  content: '\e8cc'; }

.flix-icon-list:before {
  content: '\e8cd'; }

.flix-icon-location-solid:before {
  content: '\e8ce'; }

.flix-icon-location:before {
  content: '\e8cf'; }

.flix-icon-lock-solid:before {
  content: '\e8d0'; }

.flix-icon-lock:before {
  content: '\e8d1'; }

.flix-icon-logout:before {
  content: '\e8d2'; }

.flix-icon-lost-solid:before {
  content: '\e8d3'; }

.flix-icon-lost:before {
  content: '\e8d4'; }

.flix-icon-luggage-additional-alt-solid:before {
  content: '\e8d5'; }

.flix-icon-luggage-additional-alt:before {
  content: '\e8d6'; }

.flix-icon-luggage-additional-solid:before {
  content: '\e8d7'; }

.flix-icon-luggage-additional:before {
  content: '\e8d8'; }

.flix-icon-luggage-bulky-solid:before {
  content: '\e8d9'; }

.flix-icon-luggage-bulky:before {
  content: '\e8da'; }

.flix-icon-luggage-group-solid:before {
  content: '\e8db'; }

.flix-icon-luggage-group:before {
  content: '\e8dc'; }

.flix-icon-luggage-hand-solid:before {
  content: '\e8dd'; }

.flix-icon-luggage-hand:before {
  content: '\e8de'; }

.flix-icon-luggage-solid:before {
  content: '\e8df'; }

.flix-icon-luggage:before {
  content: '\e8e0'; }

.flix-icon-magnifier-solid:before {
  content: '\e8e1'; }

.flix-icon-magnifier:before {
  content: '\e8e2'; }

.flix-icon-male:before {
  content: '\e8e3'; }

.flix-icon-map-solid:before {
  content: '\e8e4'; }

.flix-icon-map:before {
  content: '\e8e5'; }

.flix-icon-mask:before {
  content: '\e8e6'; }

.flix-icon-meatball:before {
  content: '\e8e7'; }

.flix-icon-men-women-solid:before {
  content: '\e8e8'; }

.flix-icon-men-women:before {
  content: '\e8e9'; }

.flix-icon-microphone-solid:before {
  content: '\e8ea'; }

.flix-icon-microphone:before {
  content: '\e8eb'; }

.flix-icon-minus-strong-solid:before {
  content: '\e8ec'; }

.flix-icon-minus-strong:before {
  content: '\e8ed'; }

.flix-icon-minus:before {
  content: '\e8ee'; }

.flix-icon-mountains-solid:before {
  content: '\e8ef'; }

.flix-icon-mountains:before {
  content: '\e8f0'; }

.flix-icon-new-tab:before {
  content: '\e8f1'; }

.flix-icon-newsletter-solid:before {
  content: '\e8f2'; }

.flix-icon-newsletter:before {
  content: '\e8f3'; }

.flix-icon-night-solid:before {
  content: '\e8f4'; }

.flix-icon-night:before {
  content: '\e8f5'; }

.flix-icon-no-entry-solid:before {
  content: '\e8f6'; }

.flix-icon-no-entry:before {
  content: '\e8f7'; }

.flix-icon-notification-solid:before {
  content: '\e8f8'; }

.flix-icon-notification:before {
  content: '\e8f9'; }

.flix-icon-offer-solid:before {
  content: '\e8fa'; }

.flix-icon-offer:before {
  content: '\e8fb'; }

.flix-icon-org-chart-solid:before {
  content: '\e8fc'; }

.flix-icon-organization-solid:before {
  content: '\e8fd'; }

.flix-icon-partner-solid:before {
  content: '\e8fe'; }

.flix-icon-partner:before {
  content: '\e8ff'; }

.flix-icon-passport-solid:before {
  content: '\e900'; }

.flix-icon-passport:before {
  content: '\e901'; }

.flix-icon-pause-solid:before {
  content: '\e902'; }

.flix-icon-pause:before {
  content: '\e903'; }

.flix-icon-person-business-solid:before {
  content: '\e904'; }

.flix-icon-person-business:before {
  content: '\e905'; }

.flix-icon-person-female-solid:before {
  content: '\e906'; }

.flix-icon-person-male-solid:before {
  content: '\e907'; }

.flix-icon-person-solid:before {
  content: '\e908'; }

.flix-icon-person:before {
  content: '\e909'; }

.flix-icon-phone-solid:before {
  content: '\e90a'; }

.flix-icon-phone-tablet-solid:before {
  content: '\e90b'; }

.flix-icon-phone-tablet:before {
  content: '\e90c'; }

.flix-icon-phone:before {
  content: '\e90d'; }

.flix-icon-pie-chart-solid:before {
  content: '\e90e'; }

.flix-icon-pie-chart:before {
  content: '\e90f'; }

.flix-icon-pin-solid:before {
  content: '\e910'; }

.flix-icon-pin:before {
  content: '\e911'; }

.flix-icon-plane-solid:before {
  content: '\e912'; }

.flix-icon-plane:before {
  content: '\e913'; }

.flix-icon-play-solid:before {
  content: '\e914'; }

.flix-icon-play:before {
  content: '\e915'; }

.flix-icon-playing-card-solid:before {
  content: '\e916'; }

.flix-icon-playing-card:before {
  content: '\e917'; }

.flix-icon-plus:before {
  content: '\e918'; }

.flix-icon-profile-solid:before {
  content: '\e919'; }

.flix-icon-profile:before {
  content: '\e91a'; }

.flix-icon-public-solid:before {
  content: '\e91b'; }

.flix-icon-public:before {
  content: '\e91c'; }

.flix-icon-q-and-a-solid:before {
  content: '\e91d'; }

.flix-icon-q-and-a:before {
  content: '\e91e'; }

.flix-icon-qr-code:before {
  content: '\e91f'; }

.flix-icon-question-solid:before {
  content: '\e920'; }

.flix-icon-question:before {
  content: '\e921'; }

.flix-icon-real-time-solid:before {
  content: '\e922'; }

.flix-icon-real-time:before {
  content: '\e923'; }

.flix-icon-rear-exit:before {
  content: '\e924'; }

.flix-icon-rebook-solid:before {
  content: '\e925'; }

.flix-icon-rebook:before {
  content: '\e926'; }

.flix-icon-ride-arrival:before {
  content: '\e929'; }

.flix-icon-ride-departure:before {
  content: '\e92a'; }

.flix-icon-ride-interconnection:before {
  content: '\e92b'; }

.flix-icon-ride-line:before {
  content: '\e92c'; }

.flix-icon-ride-share-solid:before {
  content: '\e92d'; }

.flix-icon-ride-share:before {
  content: '\e92e'; }

.flix-icon-ride-stop:before {
  content: '\e92f'; }

.flix-icon-ride-transfer:before {
  content: '\e930'; }

.flix-icon-save-solid:before {
  content: '\e931'; }

.flix-icon-save:before {
  content: '\e932'; }

.flix-icon-seat-belt-solid:before {
  content: '\e933'; }

.flix-icon-seat-belt:before {
  content: '\e934'; }

.flix-icon-seat-solid:before {
  content: '\e935'; }

.flix-icon-seat:before {
  content: '\e936'; }

.flix-icon-secure-payment-solid:before {
  content: '\e937'; }

.flix-icon-secure-payment:before {
  content: '\e938'; }

.flix-icon-security-solid:before {
  content: '\e939'; }

.flix-icon-security:before {
  content: '\e93a'; }

.flix-icon-settings-solid:before {
  content: '\e93b'; }

.flix-icon-settings:before {
  content: '\e93c'; }

.flix-icon-share-file-solid:before {
  content: '\e93d'; }

.flix-icon-share-file:before {
  content: '\e93e'; }

.flix-icon-share-solid:before {
  content: '\e93f'; }

.flix-icon-share:before {
  content: '\e940'; }

.flix-icon-signpost-solid:before {
  content: '\e941'; }

.flix-icon-signpost:before {
  content: '\e942'; }

.flix-icon-sit-solid:before {
  content: '\e943'; }

.flix-icon-sit:before {
  content: '\e944'; }

.flix-icon-ski-box-solid:before {
  content: '\e945'; }

.flix-icon-ski-box:before {
  content: '\e946'; }

.flix-icon-ski-solid:before {
  content: '\e947'; }

.flix-icon-ski:before {
  content: '\e948'; }

.flix-icon-smoking-no-solid:before {
  content: '\e949'; }

.flix-icon-smoking-no:before {
  content: '\e94a'; }

.flix-icon-smoking-solid:before {
  content: '\e94b'; }

.flix-icon-smoking:before {
  content: '\e94c'; }

.flix-icon-snacks-solid:before {
  content: '\e94d'; }

.flix-icon-snacks:before {
  content: '\e94e'; }

.flix-icon-sneezing:before {
  content: '\e94f'; }

.flix-icon-social-distancing-solid:before {
  content: '\e950'; }

.flix-icon-social-distancing:before {
  content: '\e951'; }

.flix-icon-socket-solid:before {
  content: '\e952'; }

.flix-icon-socket:before {
  content: '\e953'; }

.flix-icon-sort-up:before {
  content: '\e954'; }

.flix-icon-sort:before {
  content: '\e955'; }

.flix-icon-speedometer-slow-fast:before {
  content: '\e956'; }

.flix-icon-speedometer-slow:before {
  content: '\e957'; }

.flix-icon-star-solid:before {
  content: '\e958'; }

.flix-icon-star:before {
  content: '\e959'; }

.flix-icon-station-solid:before {
  content: '\e95a'; }

.flix-icon-station:before {
  content: '\e95b'; }

.flix-icon-stopwatch-solid:before {
  content: '\e95c'; }

.flix-icon-stopwatch:before {
  content: '\e95d'; }

.flix-icon-stroller-solid:before {
  content: '\e95e'; }

.flix-icon-stroller:before {
  content: '\e95f'; }

.flix-icon-suitcase-solid:before {
  content: '\e960'; }

.flix-icon-suitcase:before {
  content: '\e961'; }

.flix-icon-switch-vertical:before {
  content: '\e962'; }

.flix-icon-switch:before {
  content: '\e963'; }

.flix-icon-tablet-solid:before {
  content: '\e964'; }

.flix-icon-tablet:before {
  content: '\e965'; }

.flix-icon-target:before {
  content: '\e966'; }

.flix-icon-thermometer-solid:before {
  content: '\e967'; }

.flix-icon-thermometer:before {
  content: '\e968'; }

.flix-icon-ticket-solid:before {
  content: '\e969'; }

.flix-icon-ticket-special-solid:before {
  content: '\e96a'; }

.flix-icon-ticket-special:before {
  content: '\e96b'; }

.flix-icon-ticket:before {
  content: '\e96c'; }

.flix-icon-tie-solid:before {
  content: '\e96d'; }

.flix-icon-tie:before {
  content: '\e96e'; }

.flix-icon-time-solid:before {
  content: '\e96f'; }

.flix-icon-time:before {
  content: '\e970'; }

.flix-icon-toilet-solid:before {
  content: '\e971'; }

.flix-icon-toilet:before {
  content: '\e972'; }

.flix-icon-toolbox-solid:before {
  content: '\e973'; }

.flix-icon-toolbox:before {
  content: '\e974'; }

.flix-icon-train-night-solid:before {
  content: '\e975'; }

.flix-icon-train-night:before {
  content: '\e976'; }

.flix-icon-train-service-solid:before {
  content: '\e977'; }

.flix-icon-train-service:before {
  content: '\e978'; }

.flix-icon-train-solid:before {
  content: '\e979'; }

.flix-icon-train-stop-no-solid:before {
  content: '\e97a'; }

.flix-icon-train-stop-no:before {
  content: '\e97b'; }

.flix-icon-train-stop-solid:before {
  content: '\e97c'; }

.flix-icon-train-stop:before {
  content: '\e97d'; }

.flix-icon-train-time-solid:before {
  content: '\e97e'; }

.flix-icon-train-time:before {
  content: '\e97f'; }

.flix-icon-train-wagon-solid:before {
  content: '\e980'; }

.flix-icon-train-wagon:before {
  content: '\e981'; }

.flix-icon-train:before {
  content: '\e982'; }

.flix-icon-tram-solid:before {
  content: '\e983'; }

.flix-icon-tram:before {
  content: '\e984'; }

.flix-icon-transfer-solid:before {
  content: '\e985'; }

.flix-icon-transfer:before {
  content: '\e986'; }

.flix-icon-trash:before {
  content: '\e987'; }

.flix-icon-tree-solid:before {
  content: '\e988'; }

.flix-icon-tree:before {
  content: '\e989'; }

.flix-icon-trip-solid:before {
  content: '\e98a'; }

.flix-icon-trip:before {
  content: '\e98b'; }

.flix-icon-tunnel:before {
  content: '\e98c'; }

.flix-icon-twitter:before {
  content: '\e98d'; }

.flix-icon-upload:before {
  content: '\e98e'; }

.flix-icon-user-add-group-solid:before {
  content: '\e98f'; }

.flix-icon-user-add-group:before {
  content: '\e990'; }

.flix-icon-user-add-solid:before {
  content: '\e991'; }

.flix-icon-user-add:before {
  content: '\e992'; }

.flix-icon-user-minus-solid:before {
  content: '\e993'; }

.flix-icon-user-minus:before {
  content: '\e994'; }

.flix-icon-user-private-solid:before {
  content: '\e995'; }

.flix-icon-user-private:before {
  content: '\e996'; }

.flix-icon-video-camera-solid:before {
  content: '\e997'; }

.flix-icon-video-camera:before {
  content: '\e998'; }

.flix-icon-voucher-solid:before {
  content: '\e999'; }

.flix-icon-voucher:before {
  content: '\e99a'; }

.flix-icon-warning:before {
  content: '\e99b'; }

.flix-icon-watch-solid:before {
  content: '\e99c'; }

.flix-icon-watch:before {
  content: '\e99d'; }

.flix-icon-wheel-solid:before {
  content: '\e99e'; }

.flix-icon-wheel:before {
  content: '\e99f'; }

.flix-icon-wheelchair-info-solid:before {
  content: '\e9a0'; }

.flix-icon-wheelchair-info:before {
  content: '\e9a1'; }

.flix-icon-wheelchair-solid:before {
  content: '\e9a2'; }

.flix-icon-wheelchair:before {
  content: '\e9a3'; }

.flix-icon-width-bigger:before {
  content: '\e9a4'; }

.flix-icon-width-smaller:before {
  content: '\e9a5'; }

.flix-icon-wifi-no-solid:before {
  content: '\e9a6'; }

.flix-icon-wifi-no:before {
  content: '\e9a7'; }

.flix-icon-wifi-solid:before {
  content: '\e9a8'; }

.flix-icon-wifi:before {
  content: '\e9a9'; }

.flix-icon-windmill-solid:before {
  content: '\e9aa'; }

.flix-icon-youtube:before {
  content: '\e9ab'; }

.flix-icon-zoom-in:before {
  content: '\e9ac'; }

.flix-icon-zoom-out:before {
  content: '\e9ad'; }

.flix-icon-badge-ft-one-solid:before {
  content: '\e9ae'; }

.flix-icon-badge-ft-solid:before {
  content: '\e9af'; }

.flix-icon-badge-ft-two-solid:before {
  content: '\e9b0'; }

.flix-icon-edit-mass-solid:before {
  content: '\e9b1'; }

.flix-icon-edit-mass:before {
  content: '\e9b2'; }

.flix-icon-ruler:before {
  content: '\e9b8'; }

.flix-icon-timeline-alt-solid:before {
  content: '\e9b9'; }

.flix-icon-timeline-alt:before {
  content: '\e9ba'; }

.flix-icon-timeline-stop-solid:before {
  content: '\e9bb'; }

.flix-icon-timeline-stop:before {
  content: '\e9bc'; }

.flix-icon-timetable-solid:before {
  content: '\e9bd'; }

.flix-icon-timetable:before {
  content: '\e9be'; }

.flix-icon-validation:before {
  content: '\e9bf'; }

.flix-icon-view-graph:before {
  content: '\e9c0'; }

.flix-icon-view-horizontal-solid:before {
  content: '\e9c1'; }

.flix-icon-view-horizontal:before {
  content: '\e9c2'; }

.flix-icon-view-vertical-solid:before {
  content: '\e9c3'; }

.flix-icon-view-vertical:before {
  content: '\e9c4'; }

.flix-icon-link-un:before {
  content: '\e9b3'; }

.flix-icon-publish:before {
  content: '\e9b4'; }

.flix-icon-queue-solid:before {
  content: '\e9b5'; }

.flix-icon-queue:before {
  content: '\e9b6'; }

.flix-icon-ruler-solid:before {
  content: '\e9b7'; }

.flix-icon-arrow-down-l:before {
  content: '\e9c5'; }

.flix-icon-arrow-left-l:before {
  content: '\e9c6'; }

.flix-icon-usb:before {
  content: '\e9cc'; }

.flix-icon-arrow-right-l:before {
  content: '\e9c7'; }

.flix-icon-arrow-up-l:before {
  content: '\e9c8'; }

.flix-icon-ticket-shiny-solid:before {
  content: '\e9c9'; }

.flix-icon-ticket-shiny:before {
  content: '\e9ca'; }

.flix-icon-usb-solid:before {
  content: '\e9cb'; }

.flix-icon-calculator-solid:before {
  content: '\e9cd'; }

.flix-icon-calculator:before {
  content: '\e9ce'; }

.flix-icon-bus-airport-solid:before {
  content: '\e927'; }

.flix-icon-bus-airport:before {
  content: '\e928'; }

.flix-icon-bus-leaf-solid:before {
  content: '\e9cf'; }

.flix-icon-bus-leaf:before {
  content: '\e9d0'; }

.flix-icon-bus-no-solid:before {
  content: '\e9d1'; }

.flix-icon-bus-no:before {
  content: '\e9d2'; }

.flix-icon-hovercraft-solid:before {
  content: '\e9d3'; }

.flix-icon-hovercraft:before {
  content: '\e9d4'; }

.flix-icon-minibus-solid:before {
  content: '\e9d5'; }

.flix-icon-minibus:before {
  content: '\e9d6'; }

.flix-icon-refresh:before {
  content: '\e9d7'; }

.flix-icon-reset:before {
  content: '\e9d8'; }

.flix-icon-surveillance-solid:before {
  content: '\e9d9'; }

.flix-icon-train-airport-solid:before {
  content: '\e9db'; }

.flix-icon-train-airport:before {
  content: '\e9dc'; }

.flix-icon-train-no-solid:before {
  content: '\e9dd'; }

.flix-icon-train-no:before {
  content: '\e9de'; }

.flix-icon-update:before {
  content: '\e9df'; }

.flix-icon-surveillance:before {
  content: '\e9e0'; }

.flix-icon-zoom-out-solid:before {
  content: '\e9f7'; }

.flix-icon-zoom-in-solid:before {
  content: '\e9f6'; }

.flix-icon-thumbtack:before {
  content: '\e9f5'; }

.flix-icon-thumbtack-solid:before {
  content: '\e9f4'; }

.flix-icon-print:before {
  content: '\e9f3'; }

.flix-icon-print-solid:before {
  content: '\e9f2'; }

.flix-icon-power:before {
  content: '\e9f1'; }

.flix-icon-pin-star:before {
  content: '\e9f0'; }

.flix-icon-pin-star-solid:before {
  content: '\e9ef'; }

.flix-icon-pin-select:before {
  content: '\e9ee'; }

.flix-icon-pin-select-solid:before {
  content: '\e9ed'; }

.flix-icon-gps:before {
  content: '\e9ec'; }

.flix-icon-dot:before {
  content: '\e9eb'; }

.flix-icon-departure:before {
  content: '\e9ea'; }

.flix-icon-departure-solid:before {
  content: '\e9e9'; }

.flix-icon-change:before {
  content: '\e9e8'; }

.flix-icon-change-solid:before {
  content: '\e9e7'; }

.flix-icon-capacity-zero:before {
  content: '\e9e6'; }

.flix-icon-capacity-two:before {
  content: '\e9e5'; }

.flix-icon-capacity-three:before {
  content: '\e9e4'; }

.flix-icon-capacity-one:before {
  content: '\e9e3'; }

.flix-icon-bike-no:before {
  content: '\e9e2'; }

.flix-icon-bike-no-solid:before {
  content: '\e9e1'; }

.flix-icon-bed-seat:before {
  content: '\e849'; }

.flix-icon-bed-seat-solid:before {
  content: '\e84a'; }

.flix-icon-arrival:before {
  content: '\e84b'; }

.flix-icon-arrival-solid:before {
  content: '\e84c'; }

.flix-icon-ac:before {
  content: '\e9da'; }

[class^='flix-icon-']:before,
[class*=' flix-icon-']:before {
  display: inline-block;
  width: 1em;
  margin-right: .2em;
  margin-left: .2em;
  font-family: 'flixicons';
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1em;
  text-align: center;
  text-decoration: inherit;
  text-transform: none;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.flix-icon {
  display: inline-block;
  font-size: 1.2em; }
  .flix-icon--sm {
    font-size: 24px;
    font-size: var(--flix-spacing-sm);
    line-height: 24px;
    line-height: var(--flix-spacing-sm); }
  .flix-icon--lg {
    font-size: 48px;
    font-size: var(--flix-spacing-lg);
    line-height: 48px;
    line-height: var(--flix-spacing-lg); }

.flix-accordion {
  display: block;
  border-top: 1px solid #e1e1e1;
  border-top: 1px solid var(--flix-primary-line-color);
  border-bottom: 1px solid #e1e1e1;
  border-bottom: 1px solid var(--flix-primary-line-color);
  font-family: inherit; }
  .flix-accordion__title {
    color: #444;
    color: var(--flix-heading-font-color);
    font-size: 16px;
    font-size: var(--flix-font-size-h4);
    font-weight: 700;
    font-weight: var(--flix-font-weight-bold);
    line-height: 24px;
    line-height: var(--flix-line-height-h4);
    display: block;
    position: relative;
    padding: 12px;
    padding: var(--flix-spacing-xs);
    outline: 0;
    cursor: pointer; }
    .flix-accordion__title .flix-icon {
      margin-right: 3px;
      margin-right: var(--flix-spacing-xxxs); }
      .flix-accordion__title .flix-icon::before {
        margin: 0; }
    .flix-accordion__title::-webkit-details-marker {
      display: none; }
    .flix-accordion__title::after {
      display: inline-block;
      width: 24px;
      width: var(--flix-spacing-sm);
      height: 24px;
      height: var(--flix-spacing-sm);
      content: '';
      vertical-align: middle;
      position: absolute;
      top: 50%;
      right: 12px;
      right: var(--flix-spacing-xs);
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M485.3 618L279.2 411.7A20.8 20.8 0 1 1 308.3 382.2L500 573.7 691.7 382A20.8 20.8 0 0 1 721.1 411.5L515.3 617.3C515.1 617.3 515.1 617.7 514.9 617.9A20.8 20.8 0 0 1 485.3 618Z' fill='rgba(139, 139, 139, 0.99)'/%3E%3C/svg%3E") no-repeat center;
      background: var(--flix-icon-arrow-down) no-repeat center; }
    .flix-accordion__title:focus {
      outline: 1px solid transparent;
      -webkit-box-shadow: 0 0 3px #00b5e2;
              box-shadow: 0 0 3px #00b5e2;
      -webkit-box-shadow: 0 0 3px var(--flix-neutral-color);
              box-shadow: 0 0 3px var(--flix-neutral-color); }
  .flix-accordion[open] > .flix-accordion__title::after, .flix-accordion--open > .flix-accordion__title::after {
    -webkit-transform: translateY(-50%) rotate(180deg);
            transform: translateY(-50%) rotate(180deg); }
  .flix-accordion[open] > .flix-accordion__content, .flix-accordion--open > .flix-accordion__content {
    display: block; }
  .flix-accordion__content {
    display: none;
    padding: 0 12px 12px;
    padding: 0 var(--flix-spacing-xs) var(--flix-spacing-xs); }
  .flix-accordion + .flix-accordion {
    border-top: 0; }

.flix-avatar {
  display: block;
  width: 36px;
  width: var(--flix-spacing-md);
  height: 36px;
  height: var(--flix-spacing-md); }
  .flix-avatar__image {
    width: 100%;
    height: 100%;
    border-radius: 100%; }
    .flix-avatar__image svg {
      width: 100%;
      height: 100%; }
  .flix-avatar--sm {
    width: 24px;
    width: var(--flix-spacing-sm);
    height: 24px;
    height: var(--flix-spacing-sm); }
  .flix-avatar--lg {
    width: 48px;
    width: var(--flix-spacing-lg);
    height: 48px;
    height: var(--flix-spacing-lg); }
  .flix-avatar--xl {
    width: calc(48px * 2);
    width: calc(var(--flix-spacing-lg) * 2);
    height: calc(48px * 2);
    height: calc(var(--flix-spacing-lg) * 2); }

.flix-blockquote, .flix-text blockquote {
  position: relative;
  margin: 0 0 12px;
  margin: 0 0 var(--flix-spacing-xs);
  padding: 12px 0 0 calc(22px + 12px);
  padding: var(--flix-spacing-xs) 0 0 calc(22px + var(--flix-spacing-xs));
  color: #444;
  color: var(--flix-primary-content-color);
  font-family: inherit; }
  @media (min-width: 600px) {
    .flix-blockquote, .flix-text blockquote {
      padding: 12px 0 0 calc(44px + 12px);
      padding: var(--flix-spacing-xs) 0 0 calc(44px + var(--flix-spacing-xs)); } }
  .flix-blockquote::before, .flix-text blockquote::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 22px;
    height: 14px;
    background: url("data:image/svg+xml,%3Csvg id='Ebene_1' data-name='Ebene 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 159.8 100'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%238c8c8c;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Equote1%3C/title%3E%3Cpath class='cls-1' d='M159.4,62.9A32.2,32.2,0,0,0,129,35.7c3.7-13.8,12.8-19.4,23.8-22.5a1.3,1.3,0,0,0,.9-1.4l-1.8-11s-0.2-.9-1.6-0.7c-38,4.3-63.7,33.2-58.9,68.3,4.4,24.3,23.4,33.7,41,31.2a32.4,32.4,0,0,0,27-36.7h0ZM38.1,35.7c3.6-13.8,13-19.4,23.8-22.5a1.3,1.3,0,0,0,.9-1.4l-1.6-11S61-.2,59.6,0C21.6,4.1-3.9,33,.5,68.2c4.6,24.5,23.8,33.9,41.2,31.4A32.1,32.1,0,0,0,68.5,62.9,31.7,31.7,0,0,0,38.1,35.7Z'/%3E%3C/svg%3E") no-repeat;
    content: ''; }
    @media (min-width: 600px) {
      .flix-blockquote::before, .flix-text blockquote::before {
        width: 44px;
        height: 28px; } }

.flix-box {
  margin-bottom: 12px;
  margin-bottom: var(--flix-spacing-xs);
  padding: 12px;
  padding: var(--flix-spacing-xs);
  border: 1px solid #e1e1e1;
  border: 1px solid var(--flix-primary-line-color);
  border-radius: 6px;
  border-radius: var(--flix-primary-border-radius);
  background: #fff;
  background: var(--flix-primary-bg-color); }
  @media (min-width: 600px) {
    .flix-box {
      padding: 24px;
      padding: var(--flix-spacing-sm); } }
  .flix-box--highlighted {
    border: 0;
    -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.12);
            box-shadow: 0 0 12px rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: var(--flix-primary-box-shadow);
            box-shadow: var(--flix-primary-box-shadow); }

.flix-breadcrumbs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 14px;
  font-size: var(--flix-font-size-small);
  font-weight: 400;
  font-weight: var(--flix-font-weight-semibold);
  line-height: 1.3em; }
  .flix-breadcrumbs__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .flix-breadcrumbs__item + .flix-breadcrumbs__item:before {
      display: inline-block;
      width: 1.286em;
      height: 1.286em;
      margin: 0 3px;
      margin: 0 var(--flix-spacing-xxxs);
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M618 514.7L411.7 720.8A20.8 20.8 0 0 1 382.2 691.4L573.7 500 382 308.3A20.8 20.8 0 0 1 411.5 279.2L617.3 485C617.3 485.2 617.7 485.2 618 485.4A20.8 20.8 0 0 1 618 514.7Z' fill='rgba(139, 139, 139, 0.99)'/%3E%3C/svg%3E");
      content: var(--flix-icon-arrow-right); }
  .flix-breadcrumbs__ellipsis:before {
    content: '...'; }
  .flix-breadcrumbs__link {
    display: inline-block;
    max-width: 150px;
    color: #444;
    color: var(--flix-primary-content-color);
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }
    .flix-breadcrumbs__link:hover {
      color: #5cac00;
      color: var(--flix-primary-ui-dark-color); }
    .flix-breadcrumbs__link--active {
      color: #73d700;
      color: var(--flix-primary-ui-color); }

.flix-btn {
  display: inline-block;
  position: relative;
  width: 100%;
  min-width: 142px;
  max-width: 100%;
  height: 44px;
  padding: 0 12px;
  padding: 0 var(--flix-spacing-xs);
  -webkit-transition: background 250ms linear;
  transition: background 250ms linear;
  border: 1px solid #c8c8c8;
  border: 1px solid var(--flix-grayscale-50-color);
  border-radius: 6px;
  border-radius: var(--flix-primary-border-radius);
  background: #fff;
  background: var(--flix-grayscale-0-color);
  color: #444;
  color: var(--flix-primary-content-color);
  font-family: inherit;
  font-size: 16px;
  font-size: var(--flix-font-size-button);
  font-weight: 400;
  font-weight: var(--flix-font-weight-semibold);
  line-height: 42px;
  text-align: center;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  overflow: hidden;
  vertical-align: middle;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }
  .flix-btn:enabled:hover, .flix-btn[href]:hover, .flix-btn:active, .flix-btn.flix-btn--loading {
    background: #f7f7f7;
    background: var(--flix-grayscale-10-color);
    text-decoration: none; }
  @media (min-width: 600px) {
    .flix-btn {
      width: auto; } }
  @media (min-width: 1200px) {
    .flix-btn {
      height: 36px;
      line-height: 34px; } }
  .flix-btn + .flix-btn {
    margin-top: 12px;
    margin-top: var(--flix-spacing-xs); }
    @media (min-width: 600px) {
      .flix-btn + .flix-btn {
        margin-top: 0;
        margin-left: 12px;
        margin-left: var(--flix-spacing-xs); } }
  .flix-btn--block {
    display: block;
    width: 100%; }
  .flix-btn--square {
    width: 44px;
    min-width: auto;
    padding: 0;
    overflow: hidden; }
    @media (min-width: 1200px) {
      .flix-btn--square {
        width: 36px; } }
    .flix-btn--square + .flix-btn--square {
      margin-top: 0;
      margin-left: 12px;
      margin-left: var(--flix-spacing-xs); }
  .flix-btn--primary {
    border: 0;
    background: #ffad00;
    background: var(--flix-secondary-ui-color);
    color: #fff;
    line-height: 44px; }
    @media (min-width: 1200px) {
      .flix-btn--primary {
        line-height: 36px; } }
    .flix-btn--primary:enabled:hover, .flix-btn--primary[href]:hover, .flix-btn--primary:active, .flix-btn--primary.flix-btn--loading {
      background: #c68600;
      background: var(--flix-secondary-ui-dark-color);
      text-decoration: none; }
    .flix-btn--primary.flix-btn--loading::before {
      margin-right: 6px;
      margin-right: var(--flix-spacing-xxs);
      margin-left: 6px;
      margin-left: var(--flix-spacing-xxs);
      -webkit-filter: invert(100%);
              filter: invert(100%); }
  .flix-btn--secondary {
    border: 0;
    background: #73d700;
    background: var(--flix-primary-ui-color);
    color: #fff;
    line-height: 44px; }
    @media (min-width: 1200px) {
      .flix-btn--secondary {
        line-height: 36px; } }
    .flix-btn--secondary:enabled:hover, .flix-btn--secondary[href]:hover, .flix-btn--secondary:active, .flix-btn--secondary.flix-btn--loading {
      background: #5cac00;
      background: var(--flix-primary-ui-dark-color);
      text-decoration: none; }
    .flix-btn--secondary.flix-btn--loading::before {
      margin-right: 6px;
      margin-right: var(--flix-spacing-xxs);
      margin-left: 6px;
      margin-left: var(--flix-spacing-xxs);
      -webkit-filter: invert(100%);
              filter: invert(100%); }
  .flix-btn--danger {
    border: 0;
    background: #ff5700;
    background: var(--flix-danger-color);
    color: #fff;
    line-height: 44px; }
    @media (min-width: 1200px) {
      .flix-btn--danger {
        line-height: 36px; } }
    .flix-btn--danger:enabled:hover, .flix-btn--danger[href]:hover, .flix-btn--danger:active, .flix-btn--danger.flix-btn--loading {
      background: #c64300;
      background: var(--flix-danger-dark-color);
      text-decoration: none; }
    .flix-btn--danger.flix-btn--loading::before {
      margin-right: 6px;
      margin-right: var(--flix-spacing-xxs);
      margin-left: 6px;
      margin-left: var(--flix-spacing-xxs);
      -webkit-filter: invert(100%);
              filter: invert(100%); }
  .flix-btn--tertiary {
    border: 1px solid #73d700;
    border: 1px solid var(--flix-primary-ui-color);
    background: transparent;
    color: #73d700;
    color: var(--flix-primary-ui-color); }
    .flix-btn--tertiary:enabled:hover, .flix-btn--tertiary[href]:hover, .flix-btn--tertiary:active, .flix-btn--tertiary.flix-btn--loading {
      background-color: #5cac00;
      background-color: var(--flix-primary-ui-dark-color);
      color: #fff;
      text-decoration: none; }
    .flix-btn--tertiary.flix-btn--loading::before {
      margin-right: calc(6px - 1px);
      margin-right: calc(var(--flix-spacing-xxs) - 1px);
      margin-left: calc(6px - 1px);
      margin-left: calc(var(--flix-spacing-xxs) - 1px);
      -webkit-filter: invert(100%);
              filter: invert(100%); }
  .flix-btn:disabled, .flix-btn--disabled {
    cursor: not-allowed;
    opacity: 0.5;
    opacity: var(--flix-disabled-element-opacity); }
  .flix-btn--loading::before {
    display: inline-block;
    width: 24px;
    width: var(--flix-spacing-sm);
    height: 24px;
    height: var(--flix-spacing-sm);
    margin-top: -2px;
    margin-right: calc(6px - 1px);
    margin-right: calc(var(--flix-spacing-xxs) - 1px);
    margin-left: calc(6px - 1px);
    margin-left: calc(var(--flix-spacing-xxs) - 1px);
    border: 4px solid rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    border-left-color: rgba(0, 0, 0, 0.6);
    content: '';
    vertical-align: middle;
    -webkit-animation: btn-spinner-rotate 1s infinite linear, btn-spinner-grow 250ms;
            animation: btn-spinner-rotate 1s infinite linear, btn-spinner-grow 250ms; }

@-webkit-keyframes btn-spinner-grow {
  0% {
    width: 0;
    height: 0;
    opacity: 0; }
  100% {
    width: 24px;
    width: var(--flix-spacing-sm);
    height: 24px;
    height: var(--flix-spacing-sm);
    opacity: 1; } }

@keyframes btn-spinner-grow {
  0% {
    width: 0;
    height: 0;
    opacity: 0; }
  100% {
    width: 24px;
    width: var(--flix-spacing-sm);
    height: 24px;
    height: var(--flix-spacing-sm);
    opacity: 1; } }

@-webkit-keyframes btn-spinner-rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes btn-spinner-rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }
  .flix-btn--has-icon.flix-btn--loading .flix-btn__icon {
    display: none; }
  .flix-btn--link {
    width: auto;
    min-width: 0;
    padding: 0;
    border: 0;
    background: transparent; }
    .flix-btn--link + .flix-btn--link {
      margin-top: 0;
      margin-left: 12px;
      margin-left: var(--flix-spacing-xs); }
    .flix-btn--link:enabled:hover, .flix-btn--link[href]:hover, .flix-btn--link:active, .flix-btn--link.flix-btn--loading {
      border: 0;
      background: transparent; }
    .flix-btn--link.flix-btn--loading::before {
      margin-left: 0;
      -webkit-filter: none;
              filter: none; }
    .flix-btn--link .flix-btn__icon::before {
      margin-right: 0;
      margin-left: 0; }
    .flix-btn--link.flix-btn--primary {
      color: #ffad00;
      color: var(--flix-secondary-ui-color); }
      .flix-btn--link.flix-btn--primary:enabled:hover, .flix-btn--link.flix-btn--primary[href]:hover, .flix-btn--link.flix-btn--primary:active, .flix-btn--link.flix-btn--primary.flix-btn--loading {
        color: #c68600;
        color: var(--flix-secondary-ui-dark-color); }
    .flix-btn--link.flix-btn--secondary, .flix-btn--link.flix-btn--tertiary {
      color: #73d700;
      color: var(--flix-primary-ui-color); }
      .flix-btn--link.flix-btn--secondary:enabled:hover, .flix-btn--link.flix-btn--secondary[href]:hover, .flix-btn--link.flix-btn--secondary:active, .flix-btn--link.flix-btn--secondary.flix-btn--loading, .flix-btn--link.flix-btn--tertiary:enabled:hover, .flix-btn--link.flix-btn--tertiary[href]:hover, .flix-btn--link.flix-btn--tertiary:active, .flix-btn--link.flix-btn--tertiary.flix-btn--loading {
        color: #5cac00;
        color: var(--flix-primary-ui-dark-color); }
    .flix-btn--link.flix-btn--danger {
      color: #ff5700;
      color: var(--flix-danger-color); }
      .flix-btn--link.flix-btn--danger:enabled:hover, .flix-btn--link.flix-btn--danger[href]:hover, .flix-btn--link.flix-btn--danger:active, .flix-btn--link.flix-btn--danger.flix-btn--loading {
        color: #c64300;
        color: var(--flix-danger-dark-color); }
  .flix-btn__icon {
    margin-top: -2px;
    vertical-align: middle; }
  .flix-btn__text {
    margin: 0 0 0 .2em; }
  .flix-btn__icon + .flix-btn__text {
    margin: 0 .2em 0 0; }

.flix-btn-arrow {
  display: inline-block;
  position: relative;
  min-height: 0;
  border: 0;
  background: transparent;
  color: #444;
  color: var(--flix-primary-content-color);
  font-family: inherit;
  font-size: 18px;
  font-weight: 400;
  font-weight: var(--flix-font-weight-semibold);
  line-height: 24px;
  line-height: var(--flix-spacing-sm);
  text-decoration: none;
  cursor: pointer; }
  .flix-btn-arrow:hover, .flix-btn-arrow:focus, .flix-btn-arrow:active {
    text-decoration: none; }
  .flix-btn-arrow::after {
    display: inline-block;
    width: 24px;
    width: var(--flix-spacing-sm);
    height: 24px;
    height: var(--flix-spacing-sm);
    content: '';
    vertical-align: middle;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M618 514.7L411.7 720.8A20.8 20.8 0 0 1 382.2 691.4L573.7 500 382 308.3A20.8 20.8 0 0 1 411.5 279.2L617.3 485C617.3 485.2 617.7 485.2 618 485.4A20.8 20.8 0 0 1 618 514.7Z' fill='rgba(139, 139, 139, 0.99)'/%3E%3C/svg%3E") no-repeat center;
    background: var(--flix-icon-arrow-right) no-repeat center; }
  .flix-btn-arrow--back::after {
    display: none; }
  .flix-btn-arrow--back::before {
    display: inline-block;
    width: 24px;
    width: var(--flix-spacing-sm);
    height: 24px;
    height: var(--flix-spacing-sm);
    content: '';
    vertical-align: middle;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M382 485.3L588.3 279.2A20.8 20.8 0 1 1 617.8 308.6L426.3 500 618 691.7A20.8 20.8 0 0 1 588.5 721.1L382.7 515.3C382.7 515.1 382.3 515.1 382.1 514.9A20.8 20.8 0 0 1 382 485.3Z' fill='rgba(139, 139, 139, 0.99)'/%3E%3C/svg%3E") no-repeat center;
    background: var(--flix-icon-arrow-left) no-repeat center; }
  .flix-btn-arrow--top::after {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg); }
  .flix-btn-arrow--bottom::after {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg); }
  .flix-btn-arrow--strong {
    color: #ffad00;
    color: var(--flix-strong-highlight-color); }
    .flix-btn-arrow--strong::after {
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M618 514.7L411.7 720.8A20.8 20.8 0 0 1 382.2 691.4L573.7 500 382 308.3A20.8 20.8 0 0 1 411.5 279.2L617.3 485C617.3 485.2 617.7 485.2 618 485.4A20.8 20.8 0 0 1 618 514.7Z' fill='rgba(255, 173, 0, 0.99)'/%3E%3C/svg%3E") no-repeat center;
      background: var(--flix-icon-arrow-right-active) no-repeat center; }
    .flix-btn-arrow--strong.flix-btn-arrow--back::before {
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M382 485.3L588.3 279.2A20.8 20.8 0 1 1 617.8 308.6L426.3 500 618 691.7A20.8 20.8 0 0 1 588.5 721.1L382.7 515.3C382.7 515.1 382.3 515.1 382.1 514.9A20.8 20.8 0 0 1 382 485.3Z' fill='rgba(255, 173, 0, 0.99)'/%3E%3C/svg%3E") no-repeat center;
      background: var(--flix-icon-arrow-left-active) no-repeat center; }

.flix-checkbox {
  position: relative; }
  .flix-checkbox--sm .flix-checkbox__label {
    min-height: 18px; }
    .flix-checkbox--sm .flix-checkbox__label::before {
      width: 18px;
      height: 18px; }
  .flix-checkbox--sm .flix-checkbox__input:checked + .flix-checkbox__label::before {
    background-size: 10px; }
  .flix-checkbox__label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    min-height: 30px;
    color: #444;
    color: var(--flix-primary-content-color);
    font-family: inherit;
    cursor: pointer; }
    .flix-checkbox__label::before {
      display: block;
      -ms-flex-negative: 0;
          flex-shrink: 0;
      -ms-flex-item-align: baseline;
          align-self: baseline;
      width: 30px;
      height: 30px;
      margin-right: 12px;
      margin-right: var(--flix-spacing-xs);
      -webkit-transition: ease-in-out 200ms background-size;
      transition: ease-in-out 200ms background-size;
      border: 2px solid #c8c8c8;
      border: 2px solid var(--flix-input-border-color);
      border-radius: 6px;
      border-radius: var(--flix-primary-border-radius);
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M21,4.5a.988.988,0,0,0-.707.293L8,17.086,3.708,12.794A.989.989,0,0,0,3,12.5a1,1,0,0,0-.707,1.707l5,5a1,1,0,0,0,1.414,0l13-13A1,1,0,0,0,21,4.5Zm0,1h0Z' style='fill:%2373d700'/%3E%3C/svg%3E") no-repeat center;
      background: var(--flix-icon-checkmark) no-repeat center;
      background-size: 0;
      content: ''; }
  .flix-checkbox__input:checked + .flix-checkbox__label::before {
    background-size: 24px; }
  .flix-checkbox__input {
    position: absolute;
    opacity: 0; }
  .flix-checkbox__input:focus + .flix-checkbox__label::before {
    outline: 1px solid transparent;
    -webkit-box-shadow: 0 0 3px #00b5e2;
            box-shadow: 0 0 3px #00b5e2;
    -webkit-box-shadow: 0 0 3px var(--flix-neutral-color);
            box-shadow: 0 0 3px var(--flix-neutral-color); }
  .flix-checkbox__input:checked + .flix-checkbox__label::before {
    border-color: #73d700;
    border-color: var(--flix-primary-ui-color); }
  .flix-checkbox__info {
    color: #444;
    color: var(--flix-primary-content-color);
    font-size: 14px;
    font-size: var(--flix-font-size-small);
    font-weight: 400;
    font-weight: var(--flix-font-weight-normal);
    line-height: 18px;
    line-height: var(--flix-line-height-small);
    display: block;
    padding-top: 3px;
    padding-top: var(--flix-spacing-xxxs); }
  .flix-checkbox--disabled .flix-checkbox__label {
    cursor: not-allowed;
    opacity: 0.5;
    opacity: var(--flix-disabled-element-opacity); }
  .flix-checkbox--error .flix-checkbox__label:before {
    border-color: #ff5700;
    border-color: var(--flix-danger-color); }
  .flix-checkbox--error .flix-checkbox__info {
    color: #c64300;
    color: var(--flix-input-error-info-color); }
  .flix-checkbox__input:indeterminate + .flix-checkbox__label::before,
  .flix-checkbox--indeterminate > .flix-checkbox__label::before {
    border-color: #73d700;
    border-color: var(--flix-primary-ui-color);
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M21,11H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Z' style='fill:%2373d700'/%3E%3C/svg%3E") no-repeat center;
    background: var(--flix-icon-checkbox-indeterminate) no-repeat center; }
  .flix-checkbox--sm .flix-checkbox__label::before {
    border-radius: 3px; }

.flix-choice-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: 0 0 24px;
  margin: 0 0 var(--flix-spacing-sm);
  border: 0; }
  .flix-choice-wrapper__item {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    border: solid #e1e1e1;
    border: solid var(--flix-primary-line-color); }
  .flix-choice-wrapper--error .flix-choice-wrapper__item {
    border-color: #ff5700;
    border-color: var(--flix-danger-color); }
  .flix-choice-wrapper--error .flix-choice-wrapper__info:first-of-type {
    color: #c64300;
    color: var(--flix-input-error-info-color); }
  .flix-choice-wrapper .flix-radio,
  .flix-choice-wrapper .flix-checkbox {
    height: 100%; }
    .flix-choice-wrapper .flix-radio__label,
    .flix-choice-wrapper .flix-checkbox__label {
      display: block;
      width: 100%;
      height: 100%;
      min-height: calc(30px + 12px * 2);
      min-height: calc(30px + var(--flix-spacing-xs) * 2);
      padding: 12px;
      padding: var(--flix-spacing-xs);
      padding-left: calc(30px + 12px * 2);
      padding-left: calc(30px + var(--flix-spacing-xs) * 2); }
      .flix-choice-wrapper .flix-radio__label:before,
      .flix-choice-wrapper .flix-checkbox__label:before {
        margin-left: calc(-1 * 30px - 12px);
        margin-left: calc(-1 * 30px - var(--flix-spacing-xs));
        float: left; }
  .flix-choice-wrapper__info {
    color: #444;
    color: var(--flix-primary-content-color);
    font-size: 14px;
    font-size: var(--flix-font-size-small);
    font-weight: 400;
    font-weight: var(--flix-font-weight-normal);
    line-height: 18px;
    line-height: var(--flix-line-height-small);
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    -ms-flex-negative: 0;
        flex-shrink: 0; }
  .flix-choice-wrapper:not(.flix-choice-wrapper--column) .flix-choice-wrapper__item {
    margin-bottom: 3px;
    margin-bottom: var(--flix-spacing-xxxs);
    border-width: 1px 1px 1px 0; }
    .flix-choice-wrapper:not(.flix-choice-wrapper--column) .flix-choice-wrapper__item:first-of-type {
      border-left-width: 1px;
      border-radius: 6px 0 0 6px;
      border-radius: var(--flix-primary-border-radius) 0 0 var(--flix-primary-border-radius);
      border-right-color: #e1e1e1;
      border-right-color: var(--flix-primary-line-color); }
    .flix-choice-wrapper:not(.flix-choice-wrapper--column) .flix-choice-wrapper__item:last-of-type {
      border-radius: 0 6px 6px 0;
      border-radius: 0 var(--flix-primary-border-radius) var(--flix-primary-border-radius) 0;
      border-left-color: #e1e1e1;
      border-left-color: var(--flix-primary-line-color); }
    .flix-choice-wrapper:not(.flix-choice-wrapper--column) .flix-choice-wrapper__item:not(:first-of-type):not(:last-of-type) {
      border-right-color: #e1e1e1;
      border-right-color: var(--flix-primary-line-color);
      border-left-color: #e1e1e1;
      border-left-color: var(--flix-primary-line-color); }
  .flix-choice-wrapper--column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
  .flix-choice-wrapper--column .flix-choice-wrapper__item {
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
    border-width: 0 1px 1px; }
    .flix-choice-wrapper--column .flix-choice-wrapper__item:first-of-type {
      border-top-width: 1px;
      border-radius: 6px 6px 0 0;
      border-radius: var(--flix-primary-border-radius) var(--flix-primary-border-radius) 0 0;
      border-bottom-color: #e1e1e1;
      border-bottom-color: var(--flix-primary-line-color); }
    .flix-choice-wrapper--column .flix-choice-wrapper__item:last-of-type {
      margin-bottom: 3px;
      margin-bottom: var(--flix-spacing-xxxs);
      border-radius: 0 0 6px 6px;
      border-radius: 0 0 var(--flix-primary-border-radius) var(--flix-primary-border-radius);
      border-top-color: #e1e1e1;
      border-top-color: var(--flix-primary-line-color); }
    .flix-choice-wrapper--column .flix-choice-wrapper__item:not(:first-of-type):not(:last-of-type) {
      border-top-color: #e1e1e1;
      border-top-color: var(--flix-primary-line-color);
      border-bottom-color: #e1e1e1;
      border-bottom-color: var(--flix-primary-line-color); }
    .flix-choice-wrapper--column .flix-choice-wrapper__item:not(:first-of-type) {
      margin-top: 0; }
  .flix-choice-wrapper__fieldset {
    border: 0; }

.flix-connection {
  width: 100%;
  margin-bottom: 24px;
  margin-bottom: var(--flix-spacing-sm);
  color: #444;
  color: var(--flix-primary-content-color);
  font-family: inherit;
  border-collapse: collapse; }
  .flix-connection__station, .flix-connection__station-info, .flix-connection__station-toggler {
    position: relative;
    margin-left: calc(24px + 5px);
    margin-left: calc(var(--flix-spacing-sm) + 5px); }
    .flix-connection__station::after, .flix-connection__station-info::after, .flix-connection__station-toggler::after {
      display: block;
      position: absolute;
      top: calc(-1 * 1.5em / 2);
      top: calc(-1 * var(--flix-line-height-primary) / 2);
      bottom: calc(-1 * 1.5em / 2);
      bottom: calc(-1 * var(--flix-line-height-primary) / 2);
      left: calc(-1 * 24px - 1px);
      left: calc(-1 * var(--flix-spacing-sm) - 1px);
      width: 1px;
      background: #8b8b8b;
      background: var(--flix-primary-icon-color);
      content: '';
      z-index: 1; }
  .flix-connection__station-info::after {
    top: 0; }
  .flix-connection__station-info {
    padding: 2px 4px;
    background: #f7f7f7;
    background: var(--flix-grayscale-10-color); }
  .flix-connection__station-toggler a {
    font-size: 16px;
    font-size: var(--flix-font-size-primary); }
  tr:last-child .flix-connection__station-info::after {
    display: none; }
  .flix-connection__station::before {
    position: absolute;
    top: calc(1.5em / 2);
    top: calc(var(--flix-line-height-primary) / 2);
    left: calc(-1 * 24px);
    left: calc(-1 * var(--flix-spacing-sm));
    width: 7px;
    height: 1px;
    background: #8b8b8b;
    background: var(--flix-primary-icon-color);
    content: '';
    vertical-align: middle;
    z-index: 2; }
  .flix-connection__title {
    margin-bottom: calc(12px / 2);
    margin-bottom: calc(var(--flix-spacing-xs) / 2);
    font-weight: 700;
    font-weight: var(--flix-font-weight-bold);
    text-align: left; }
    .flix-connection__title-arrival {
      font-weight: 400;
      font-weight: var(--flix-font-weight-normal); }
  .flix-connection__day {
    font-size: 10px;
    font-weight: 400;
    font-weight: var(--flix-font-weight-normal);
    line-height: 1;
    vertical-align: text-top; }
  .flix-connection__time .flix-connection__day {
    position: absolute; }
  .flix-connection__time {
    padding-right: calc(24px - 7px);
    padding-right: calc(var(--flix-spacing-sm) - 7px);
    white-space: nowrap; }
    .flix-connection__time--warning {
      color: #ffad00;
      color: var(--flix-warning-color); }
    .flix-connection__time--old {
      color: #8b8b8b;
      color: var(--flix-grayscale-70-color);
      text-decoration: line-through; }
    .flix-connection__time--danger {
      color: #ff5700;
      color: var(--flix-danger-color); }
  .flix-connection__ellipsis {
    width: 0;
    min-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }
  .flix-connection tr:first-child .flix-connection__station::before,
  .flix-connection tr:last-child .flix-connection__station::before {
    display: inline-block;
    top: 12px;
    top: var(--flix-spacing-xs);
    left: calc(-1 * 24px - 5px);
    left: calc(-1 * var(--flix-spacing-sm) - 5px);
    width: 9px;
    height: 9px;
    margin-top: calc(-1 * 5px);
    border: 1px solid #8b8b8b;
    border: 1px solid var(--flix-primary-icon-color);
    border-radius: 50%;
    background: #8b8b8b;
    background: var(--flix-primary-icon-color);
    content: ''; }
  .flix-connection tr:first-child .flix-connection__station::before {
    background: #fff;
    background: var(--flix-primary-bg-color); }
  .flix-connection tr:first-child .flix-connection__station::after {
    top: 12px;
    top: var(--flix-spacing-xs); }
  .flix-connection tr:last-child .flix-connection__station::after {
    bottom: calc(100% - 12px);
    bottom: calc(100% - var(--flix-spacing-xs)); }
  .flix-connection tr td:last-child {
    width: 100%; }
  .flix-connection td {
    min-width: 1px;
    vertical-align: top; }
  .flix-connection--live .flix-connection__time {
    padding: 0 0 0 12px;
    padding: 0 0 0 var(--flix-spacing-xs);
    text-align: right; }
    .flix-connection--live .flix-connection__time .flix-connection__day {
      position: relative; }
  .flix-connection--live .flix-connection__station::after {
    top: calc(-1 * 1.5em * 0.8);
    top: calc(-1 * var(--flix-line-height-primary) * 0.8);
    bottom: calc(-1 * 1.5em * 0.8);
    bottom: calc(-1 * var(--flix-line-height-primary) * 0.8); }
  .flix-connection--live tr:last-child .flix-connection__station,
  .flix-connection--live tr:first-child .flix-connection__station {
    font-weight: 700;
    font-weight: var(--flix-font-weight-bold); }
  .flix-connection--live tr:last-child td:last-child,
  .flix-connection--live tr:first-child td:last-child {
    font-weight: 700;
    font-weight: var(--flix-font-weight-bold); }
  .flix-connection--live tr td:first-child {
    padding: 0; }
  .flix-connection--live tr td:first-child,
  .flix-connection--live tr td:only-child {
    width: 100%; }
  .flix-connection--live td {
    padding: calc(12px / 2) 0;
    padding: calc(var(--flix-spacing-xs) / 2) 0;
    vertical-align: middle; }
  .flix-connection--live .flix-connection__station::before {
    top: 50%; }
  .flix-connection--hide-stops tr {
    display: none; }
  .flix-connection--hide-stops tr:first-child,
  .flix-connection--hide-stops tr:last-child {
    display: table-row; }

.flix-data-table-wrapper {
  max-width: 100%;
  height: 100%; }
  .flix-data-table-wrapper--responsive {
    overflow-x: auto; }
    @media (min-width: 600px) {
      .flix-data-table-wrapper--responsive {
        overflow-x: visible; } }
  .flix-data-table-wrapper--collapsed {
    display: inline-block; }
  .flix-data-table-wrapper--scrollable {
    border: 1px solid #e1e1e1;
    border: 1px solid var(--flix-primary-line-color);
    border-radius: 6px;
    border-radius: var(--flix-primary-border-radius);
    overflow-y: auto; }

.flix-data-table {
  width: 100%;
  border: 1px solid #e1e1e1;
  border: 1px solid var(--flix-primary-line-color);
  border-radius: 6px;
  border-radius: var(--flix-primary-border-radius);
  border-collapse: separate;
  border-spacing: 0; }
  .flix-data-table__header tr:first-of-type th:first-of-type,
  .flix-data-table__header tr:first-of-type td:first-of-type,
  .flix-data-table thead tr:first-of-type th:first-of-type,
  .flix-data-table thead tr:first-of-type td:first-of-type {
    border-radius: 6px 0 0;
    border-radius: var(--flix-primary-border-radius) 0 0; }
  .flix-data-table__header tr:first-of-type th:last-of-type,
  .flix-data-table__header tr:first-of-type td:last-of-type,
  .flix-data-table thead tr:first-of-type th:last-of-type,
  .flix-data-table thead tr:first-of-type td:last-of-type {
    border-radius: 0 6px 0 0;
    border-radius: 0 var(--flix-primary-border-radius) 0 0; }
  .flix-data-table__header th,
  .flix-data-table__header td {
    color: #444;
    color: var(--flix-heading-font-color);
    font-size: 16px;
    font-size: var(--flix-font-size-h4);
    font-weight: 700;
    font-weight: var(--flix-font-weight-bold);
    line-height: 24px;
    line-height: var(--flix-line-height-h4);
    background: #fff;
    background: var(--flix-primary-bg-color);
    white-space: nowrap; }
  .flix-data-table__header-btn {
    border: 0;
    background: transparent;
    font-size: 16px;
    font-size: var(--flix-font-size-primary);
    cursor: pointer;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    color: inherit; }
  .flix-data-table > tbody > tr > td {
    color: #444;
    color: var(--flix-primary-content-color);
    font-size: 16px;
    font-size: var(--flix-font-size-primary);
    font-weight: 400;
    font-weight: var(--flix-font-weight-normal);
    line-height: 1.5em;
    line-height: var(--flix-line-height-primary); }
  .flix-data-table > tbody > tr:last-child > td {
    border-bottom: 0; }
  .flix-data-table > tbody > tr:last-of-type td:first-of-type {
    border-radius: 0 0 0 6px;
    border-radius: 0 0 0 var(--flix-primary-border-radius); }
  .flix-data-table > tbody > tr:last-of-type td:last-of-type {
    border-radius: 0 0 6px;
    border-radius: 0 0 var(--flix-primary-border-radius); }
  .flix-data-table > tbody > tr:nth-of-type(odd) {
    background: #f7f7f7;
    background: var(--flix-grayscale-10-color); }
  .flix-data-table > tbody > tr:hover {
    background: #e1e1e1;
    background: var(--flix-grayscale-30-color); }
  .flix-data-table > tbody > tr.flix-data-table__row--selected,
  .flix-data-table > tbody > tr.flix-data-table__row--selected:hover {
    background: #e5f9c0;
    background: var(--flix-data-table-row-select-bg-color); }
  .flix-data-table--plain > tbody > tr:nth-of-type(odd) {
    background: #fff;
    background: var(--flix-primary-bg-color); }
  .flix-data-table--plain > tbody > tr:hover {
    background: #e1e1e1;
    background: var(--flix-grayscale-30-color); }
  .flix-data-table--sticky-header {
    position: relative;
    border: 0; }
    .flix-data-table--sticky-header .flix-data-table__header th,
    .flix-data-table--sticky-header .flix-data-table__header td {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      border-bottom: 3px solid #e1e1e1;
      border-bottom: var(--flix-spacing-xxxs) solid var(--flix-primary-line-color);
      z-index: 3; }
  .flix-data-table--small .flix-data-table__header th,
  .flix-data-table--small .flix-data-table__header td,
  .flix-data-table--small .flix-data-table__row td {
    padding: 6px;
    padding: var(--flix-spacing-xxs); }

.flix-data-table__header th,
.flix-data-table__header td, .flix-data-table > tbody > tr > td {
  padding: 12px;
  padding: var(--flix-spacing-xs);
  border-bottom: 1px solid #e1e1e1;
  border-bottom: 1px solid var(--flix-primary-line-color);
  text-align: left; }

.flix-divider {
  height: 1px;
  border-style: none;
  background-color: #e1e1e1;
  background-color: var(--flix-primary-line-color); }

.flix-dropdown {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative;
  font-family: inherit;
  cursor: pointer; }
  .flix-dropdown--active .flix-dropdown__items {
    display: block; }
  .flix-dropdown__items {
    display: none;
    position: absolute;
    top: 100%;
    left: 50%;
    width: 250px;
    max-height: none;
    margin-left: -125px;
    padding: 12px 0;
    padding: var(--flix-spacing-xs) 0;
    border-radius: 6px;
    border-radius: var(--flix-primary-border-radius);
    background: #fff;
    background: var(--flix-box-bg-color);
    list-style: none;
    text-align: left;
    -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.12);
            box-shadow: 0 0 12px rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: var(--flix-primary-box-shadow);
            box-shadow: var(--flix-primary-box-shadow);
    overflow: visible;
    z-index: 10;
    margin-top: 6px;
    margin-top: var(--flix-spacing-xxs); }
    .flix-dropdown__items--open {
      display: block; }
    .flix-dropdown__items::before {
      width: 0;
      height: 0;
      border-top: 0;
      border-right: 6px solid transparent;
      border-bottom: 6px solid #fff;
      border-bottom: 6px solid var(--flix-box-bg-color);
      border-left: 6px solid transparent;
      content: '';
      position: absolute;
      top: -6px;
      left: 50%;
      width: 0;
      height: 0;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
      content: ''; }
  .flix-dropdown-item {
    margin-bottom: 0;
    line-height: 1.5em;
    line-height: var(--flix-line-height-primary); }
    .flix-dropdown-item__link {
      display: block;
      position: relative;
      width: 100%;
      padding: 6px 24px;
      padding: var(--flix-spacing-xxs) var(--flix-spacing-sm);
      color: #444;
      color: var(--flix-primary-content-color);
      font-size: 16px;
      font-size: var(--flix-font-size-primary);
      font-weight: 400;
      font-weight: var(--flix-font-weight-semibold);
      text-decoration: none;
      cursor: pointer; }
      .flix-dropdown-item__link:hover, .flix-dropdown-item__link--active {
        color: #ffad00;
        color: var(--flix-strong-highlight-color); }
        .flix-dropdown-item__link:hover::before, .flix-dropdown-item__link--active::before {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          border-left: 3px solid #ffad00;
          border-left: var(--flix-spacing-xxxs) solid var(--flix-strong-highlight-color);
          content: ''; }
      .flix-dropdown-item__link .flix-icon:first-child {
        margin-right: 3px;
        margin-right: var(--flix-spacing-xxxs); }
      .flix-dropdown-item__link .flix-icon:last-child {
        margin-left: 3px;
        margin-left: var(--flix-spacing-xxxs); }
      .flix-dropdown-item__link .flix-icon::before {
        margin: 0; }
      .flix-dropdown-item__link .flix-icon {
        vertical-align: middle; }
  .flix-dropdown--top .flix-dropdown__items {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: 6px;
    margin-bottom: var(--flix-spacing-xxs); }
    .flix-dropdown--top .flix-dropdown__items::before {
      top: auto;
      bottom: calc(-1 * 6px);
      bottom: calc(-1 * var(--flix-spacing-xxs));
      -webkit-transform: translateX(-50%) rotate(180deg);
              transform: translateX(-50%) rotate(180deg); }
  .flix-dropdown--left .flix-dropdown__items {
    margin-left: calc(-1 * 250px + 24px);
    margin-left: calc(-1 * 250px + var(--flix-spacing-sm)); }
    .flix-dropdown--left .flix-dropdown__items::before {
      left: 100%;
      margin-left: calc(-1 * 24px);
      margin-left: calc(-1 * var(--flix-spacing-sm)); }
  .flix-dropdown--right .flix-dropdown__items {
    margin-left: calc(-1 * 24px);
    margin-left: calc(-1 * var(--flix-spacing-sm)); }
    .flix-dropdown--right .flix-dropdown__items::before {
      left: 24px;
      left: var(--flix-spacing-sm); }

.flix-fieldset {
  margin: 0 0 24px;
  margin: 0 0 var(--flix-spacing-sm);
  padding: 0;
  border: 0; }
  .flix-fieldset__info {
    color: #444;
    color: var(--flix-primary-content-color);
    font-size: 14px;
    font-size: var(--flix-font-size-small);
    font-weight: 400;
    font-weight: var(--flix-font-weight-normal);
    line-height: 18px;
    line-height: var(--flix-line-height-small);
    display: block;
    padding-top: 3px;
    padding-top: var(--flix-spacing-xxxs); }
  .flix-fieldset--error .flix-fieldset__info {
    color: #c64300;
    color: var(--flix-input-error-info-color); }
  .flix-fieldset__info ~ .flix-fieldset__info {
    color: #444;
    color: var(--flix-primary-content-color); }
  @media (min-width: 1200px) {
    .flix-fieldset {
      margin: 0 0 24px;
      margin: 0 0 var(--flix-spacing-sm); } }
  .flix-fieldset__info {
    width: 100%;
    clear: both; }
  .flix-fieldset::after {
    display: table;
    clear: both;
    content: ''; }
  .flix-fieldset--horizontal .flix-fieldset__item {
    margin-left: 24px;
    margin-left: var(--flix-spacing-sm);
    float: left; }
  .flix-fieldset--horizontal .flix-fieldset__item:first-of-type {
    margin-left: 0; }
  .flix-fieldset .flix-fieldset__item {
    margin-top: 12px;
    margin-top: var(--flix-spacing-xs);
    margin-bottom: 0; }

.flix-fineprint {
  color: #444;
  color: var(--flix-primary-content-color);
  font-size: 12px;
  font-size: var(--flix-font-size-fineprint);
  font-weight: 400;
  font-weight: var(--flix-font-weight-normal);
  line-height: 15px;
  line-height: var(--flix-line-height-fineprint);
  margin: 0 0 12px;
  margin: 0 0 var(--flix-spacing-xs); }

small.flix-fineprint {
  display: inline-block; }

.flix-footer-nav {
  position: relative;
  border-bottom: 1px solid #e1e1e1;
  border-bottom: 1px solid var(--flix-primary-line-color);
  font-family: inherit; }
  @media (min-width: 600px) {
    .flix-footer-nav {
      border-bottom: 0; } }
  .flix-footer-nav--first {
    border-top: 1px solid #e1e1e1;
    border-top: 1px solid var(--flix-primary-line-color); }
    @media (min-width: 600px) {
      .flix-footer-nav--first {
        border-top: 0; } }
  .flix-footer-nav__items {
    display: none;
    padding-right: 12px;
    padding-right: var(--flix-spacing-xs);
    padding-left: 12px;
    padding-left: var(--flix-spacing-xs);
    list-style: none; }
    @media (min-width: 600px) {
      .flix-footer-nav__items {
        display: block;
        padding-right: 0;
        padding-left: 0; } }
    .flix-footer-nav__items--open {
      display: block; }
  .flix-footer-nav__item {
    padding-bottom: 12px;
    padding-bottom: var(--flix-spacing-xs); }
  .flix-footer-nav__link {
    color: #444;
    color: var(--flix-primary-content-color);
    font-size: 16px;
    font-size: var(--flix-font-size-primary);
    font-weight: 400;
    font-weight: var(--flix-font-weight-normal);
    line-height: 1.5em;
    line-height: var(--flix-line-height-primary);
    font-weight: 400;
    font-weight: var(--flix-font-weight-semibold);
    text-decoration: none; }
    .flix-footer-nav__link .flix-icon:first-child {
      margin-right: 3px;
      margin-right: var(--flix-spacing-xxxs); }
    .flix-footer-nav__link .flix-icon:last-child {
      margin-left: 3px;
      margin-left: var(--flix-spacing-xxxs); }
    .flix-footer-nav__link .flix-icon::before {
      margin: 0; }
    .flix-footer-nav__link:hover {
      color: #ffad00;
      color: var(--flix-strong-highlight-color); }
  .flix-footer-nav__title {
    color: #444;
    color: var(--flix-heading-font-color);
    font-size: 16px;
    font-size: var(--flix-font-size-h4);
    font-weight: 700;
    font-weight: var(--flix-font-weight-bold);
    line-height: 24px;
    line-height: var(--flix-line-height-h4);
    position: relative;
    padding: 12px;
    padding: var(--flix-spacing-xs); }
    .flix-footer-nav__title .flix-icon:first-child {
      margin-right: 3px;
      margin-right: var(--flix-spacing-xxxs); }
    .flix-footer-nav__title .flix-icon:last-child {
      margin-left: 3px;
      margin-left: var(--flix-spacing-xxxs); }
    .flix-footer-nav__title .flix-icon::before {
      margin: 0; }
    .flix-footer-nav__title::after {
      display: inline-block;
      width: 24px;
      width: var(--flix-spacing-sm);
      height: 24px;
      height: var(--flix-spacing-sm);
      content: '';
      vertical-align: middle;
      position: absolute;
      top: 50%;
      right: 12px;
      right: var(--flix-spacing-xs);
      margin-top: -11px;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M485.3 618L279.2 411.7A20.8 20.8 0 1 1 308.3 382.2L500 573.7 691.7 382A20.8 20.8 0 0 1 721.1 411.5L515.3 617.3C515.1 617.3 515.1 617.7 514.9 617.9A20.8 20.8 0 0 1 485.3 618Z' fill='rgba(139, 139, 139, 0.99)'/%3E%3C/svg%3E") no-repeat center;
      background: var(--flix-icon-arrow-down) no-repeat center; }
    @media (min-width: 600px) {
      .flix-footer-nav__title {
        padding-right: 0;
        padding-left: 0; }
        .flix-footer-nav__title::after {
          display: none; } }
    .flix-footer-nav__title--open::after {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }

.flix-control {
  display: block;
  position: relative;
  width: 100%;
  margin-bottom: 24px;
  margin-bottom: var(--flix-spacing-sm); }
  .flix-control--is-last {
    margin-bottom: 0; }
  .flix-control--small {
    width: 50%; }

.flix-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding-right: 12px;
  padding-right: var(--flix-spacing-xs);
  padding-left: 12px;
  padding-left: var(--flix-spacing-xs);
  background: #73d700;
  background: var(--flix-header-bg-color);
  -webkit-box-shadow: inset 0 3px 0 #73d700, inset 0 -1px 0 #73d700;
          box-shadow: inset 0 3px 0 #73d700, inset 0 -1px 0 #73d700;
  -webkit-box-shadow: inset 0 3px 0 var(--flix-primary-brand-color), inset 0 -1px 0 var(--flix-header-bottom-border-color);
          box-shadow: inset 0 3px 0 var(--flix-primary-brand-color), inset 0 -1px 0 var(--flix-header-bottom-border-color);
  z-index: 1000;
  -webkit-font-smoothing: subpixel-antialiased; }
  @media (min-width: 1200px) {
    .flix-header {
      text-align: center; } }
  .flix-header--unfixed {
    position: static; }
  .flix-header__inner {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    width: calc(100% + 12px);
    margin-right: -6px;
    margin-left: -6px;
    position: relative;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    min-width: calc(320px - 12px);
    min-width: calc(var(--flix-page-min-width) - var(--flix-spacing-xs));
    max-width: calc(1200px - 12px);
    max-width: calc(var(--flix-page-max-width) - var(--flix-spacing-xs)); }
  .flix-header--fullwidth .flix-header__inner {
    max-width: none; }

.flix-header-nav-toggle {
  display: none;
  position: absolute;
  top: 0;
  width: 60px;
  height: 60px;
  padding: 0 6px;
  padding: 0 var(--flix-spacing-xxs);
  border: 0;
  background: none;
  color: #fff;
  color: var(--flix-header-color);
  font-size: 16px;
  font-size: var(--flix-font-size-primary);
  line-height: 60px;
  text-align: left;
  cursor: pointer; }

.flix-header-brand {
  width: 50%;
  padding-right: 6px;
  padding-left: 6px;
  margin-left: 25%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
  height: 60px; }
  @media (min-width: 1200px) {
    .flix-header-brand {
      width: 16.66667%;
      padding-right: 6px;
      padding-left: 6px;
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start;
      margin-left: 0; } }
  .flix-header-brand__link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 100%; }
    @media (min-width: 1200px) {
      .flix-header-brand__link {
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: start; } }
  .flix-header-brand__img {
    width: auto;
    height: 22px;
    border: 0; }
    .flix-header-brand--square .flix-header-brand__img {
      width: 36px;
      height: 36px; }

.flix-header-widgets {
  display: none;
  width: 25%;
  padding-right: 6px;
  padding-left: 6px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2; }
  @media (min-width: 600px) {
    .flix-header-widgets {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; } }
  @media (min-width: 1200px) {
    .flix-header-widgets {
      width: 16.66667%;
      padding-right: 6px;
      padding-left: 6px;
      -webkit-box-ordinal-group: 4;
          -ms-flex-order: 3;
              order: 3; } }
  .flix-header-widgets--sticky {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
  .flix-header-widgets--mobile {
    display: block;
    width: auto; }
    @media (min-width: 600px) {
      .flix-header-widgets--mobile {
        display: none; } }
  .flix-header-widgets--has-container {
    margin: 0 24px;
    margin: 0 var(--flix-spacing-sm);
    padding: 12px 0;
    padding: var(--flix-spacing-xs) 0;
    border-top: 1px solid #e1e1e1;
    border-top: 1px solid var(--flix-primary-line-color); }

.flix-header-user-widget {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .flix-header-user-widget__content {
    display: none;
    margin-left: 6px;
    margin-left: var(--flix-spacing-xxs); }
    @media (min-width: 600px) {
      .flix-header-user-widget__content {
        display: block; } }

.flix-header-nav-wrapper {
  position: fixed;
  top: 60px;
  bottom: 0;
  left: -250px;
  width: 250px;
  -webkit-transition: left 250ms ease;
  transition: left 250ms ease;
  background: #fff;
  background: var(--flix-box-bg-color);
  font-family: inherit;
  -webkit-box-shadow: none;
          box-shadow: none;
  z-index: 1000;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }
  @media (min-width: 600px) {
    .flix-header-nav-wrapper {
      position: static;
      top: auto;
      bottom: auto;
      left: auto;
      width: auto;
      padding: 0;
      background: #5cac00;
      background: var(--flix-header-nav-bg-color-tablet);
      -webkit-box-shadow: inset 0 -1px 0 #5cac00, inset 0 1px 0 #5cac00;
              box-shadow: inset 0 -1px 0 #5cac00, inset 0 1px 0 #5cac00;
      -webkit-box-shadow: inset 0 -1px 0 var(--flix-header-nav-border-color-tablet), inset 0 1px 0 var(--flix-header-nav-border-color-tablet);
              box-shadow: inset 0 -1px 0 var(--flix-header-nav-border-color-tablet), inset 0 1px 0 var(--flix-header-nav-border-color-tablet);
      overflow-y: visible; } }
  @media (min-width: 1200px) {
    .flix-header-nav-wrapper {
      background: transparent;
      -webkit-box-shadow: none;
              box-shadow: none; } }
  .flix-header-nav-wrapper--visible {
    left: 0; }
    .flix-header-nav-wrapper--visible + .flix-header-navbar__overlay {
      display: block;
      top: 60px;
      z-index: 999; }
    @media (min-width: 600px) {
      .flix-header-nav-wrapper--visible + .flix-header-navbar__overlay {
        display: none; } }

.flix-header-navbar {
  width: 100%;
  padding-right: 6px;
  padding-left: 6px;
  position: static;
  top: auto;
  -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
          order: 3;
  background: #5cac00;
  background: var(--flix-header-nav-bg-color-tablet);
  overflow-y: visible; }
  @media (min-width: 600px) {
    .flix-header-navbar {
      width: calc(100% + 12px);
      width: calc(100% + var(--flix-spacing-xs));
      margin-right: calc(-1 * 6px);
      margin-right: calc(-1 * var(--flix-spacing-xxs));
      margin-left: calc(-1 * 6px);
      margin-left: calc(-1 * var(--flix-spacing-xxs));
      -webkit-box-shadow: inset 0 -1px 0 #5cac00, inset 0 1px 0 #5cac00;
              box-shadow: inset 0 -1px 0 #5cac00, inset 0 1px 0 #5cac00;
      -webkit-box-shadow: inset 0 -1px 0 var(--flix-header-nav-border-color-tablet), inset 0 1px 0 var(--flix-header-nav-border-color-tablet);
              box-shadow: inset 0 -1px 0 var(--flix-header-nav-border-color-tablet), inset 0 1px 0 var(--flix-header-nav-border-color-tablet); } }
  @media (min-width: 1200px) {
    .flix-header-navbar {
      width: 66.66667%;
      padding-right: 6px;
      padding-left: 6px;
      -webkit-box-ordinal-group: 3;
          -ms-flex-order: 2;
              order: 2;
      margin-right: 0;
      margin-left: 0;
      background: transparent;
      -webkit-box-shadow: none;
              box-shadow: none; } }
  .flix-header-navbar__overlay {
    display: none; }

.flix-header-nav {
  margin: 0;
  padding: 0; }
  @media (min-width: 600px) {
    .flix-header-nav {
      text-align: center; } }
  @media (min-width: 1200px) {
    .flix-header-nav {
      text-align: left; } }
  .flix-header-nav__item {
    position: relative;
    min-height: 60px;
    padding: 0 24px;
    padding: 0 var(--flix-spacing-sm);
    list-style-type: none; }
    @media (min-width: 600px) {
      .flix-header-nav__item {
        display: inline-block;
        min-height: 0;
        padding: 0 12px;
        padding: 0 var(--flix-spacing-xs); } }
    @media (min-width: 600px) {
      .flix-header-nav__item:first-child {
        padding-left: 0; } }
    @media (min-width: 600px) {
      .flix-header-nav__item:last-child {
        padding-right: 0; } }
    @media (min-width: 600px) {
      .flix-header-nav__item {
        border-top: 0; } }
  .flix-header-nav__link {
    display: block;
    position: relative;
    width: 100%;
    height: 60px;
    border-top: 1px solid #e1e1e1;
    border-top: 1px solid var(--flix-primary-line-color);
    color: #444;
    color: var(--flix-primary-content-color);
    font-size: 16px;
    font-size: var(--flix-font-size-primary);
    font-weight: 400;
    font-weight: var(--flix-font-weight-semibold);
    line-height: 60px;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer; }
    .flix-header-nav__link .flix-icon:first-child {
      margin-right: 3px;
      margin-right: var(--flix-spacing-xxxs); }
    .flix-header-nav__link .flix-icon:last-child {
      margin-left: 3px;
      margin-left: var(--flix-spacing-xxxs); }
    .flix-header-nav__link .flix-icon::before {
      margin: 0; }
    @media (min-width: 600px) {
      .flix-header-nav__link {
        width: auto;
        height: 40px;
        border: 0;
        color: #fff;
        color: var(--flix-header-color);
        line-height: 40px; } }
    @media (min-width: 1200px) {
      .flix-header-nav__link {
        height: 60px;
        line-height: 60px; } }
  .flix-header-nav__link:hover::before, .flix-header-nav__link--active::before {
    position: absolute;
    left: calc(-1 * 24px);
    left: calc(-1 * var(--flix-spacing-sm));
    height: 100%;
    border-left: 3px solid #ffad00;
    border-left: var(--flix-spacing-xxxs) solid var(--flix-strong-highlight-color);
    content: ''; }
    @media (min-width: 600px) {
      .flix-header-nav__link:hover::before, .flix-header-nav__link--active::before {
        left: auto;
        width: 100%;
        border-bottom: 3px solid #fff;
        border-bottom: var(--flix-spacing-xxxs) solid var(--flix-header-color);
        border-left: 0; } }
  .flix-header-nav__item--has-subnav .flix-header-nav__link::after {
    display: inline-block;
    width: 24px;
    width: var(--flix-spacing-sm);
    height: 24px;
    height: var(--flix-spacing-sm);
    content: '';
    vertical-align: middle;
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: calc(-1 * 24px/2);
    margin-top: calc(-1 * var(--flix-spacing-sm)/2);
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M485.3 618L279.2 411.7A20.8 20.8 0 1 1 308.3 382.2L500 573.7 691.7 382A20.8 20.8 0 0 1 721.1 411.5L515.3 617.3C515.1 617.3 515.1 617.7 514.9 617.9A20.8 20.8 0 0 1 485.3 618Z' fill='rgba(139, 139, 139, 0.99)'/%3E%3C/svg%3E") no-repeat center;
    background: var(--flix-icon-arrow-down) no-repeat center; }
    @media (min-width: 600px) {
      .flix-header-nav__item--has-subnav .flix-header-nav__link::after {
        position: static;
        top: auto;
        right: auto;
        margin-top: 0;
        -webkit-transition: -webkit-transform .3s .1s;
        transition: -webkit-transform .3s .1s;
        transition: transform .3s .1s;
        transition: transform .3s .1s, -webkit-transform .3s .1s;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M485.3 618L279.2 411.7A20.8 20.8 0 1 1 308.3 382.2L500 573.7 691.7 382A20.8 20.8 0 0 1 721.1 411.5L515.3 617.3C515.1 617.3 515.1 617.7 514.9 617.9A20.8 20.8 0 0 1 485.3 618Z' fill='rgba(255, 255, 255, 0.99)'/%3E%3C/svg%3E") no-repeat center;
        background: var(--flix-icon-header-arrow-down) no-repeat center; } }
  .flix-header-nav__item--has-subnav:hover .flix-header-nav__link::after, .flix-header-nav__item--has-subnav__item--has-subnav-open .flix-header-nav__link::after {
    -webkit-transform: rotateX(180deg);
            transform: rotateX(180deg); }

.flix-header-subnav {
  max-height: 0;
  margin: 0;
  padding: 0;
  -webkit-transition: max-height 0.25s cubic-bezier(0.52, 0, 0.5, 1);
  transition: max-height 0.25s cubic-bezier(0.52, 0, 0.5, 1);
  list-style: none;
  text-align: left;
  overflow: hidden;
  will-change: max-height; }
  @media (min-width: 600px) {
    .flix-header-subnav {
      display: none;
      position: absolute;
      top: 100%;
      left: 50%;
      width: 250px;
      max-height: none;
      margin-left: -125px;
      padding: 12px 0;
      padding: var(--flix-spacing-xs) 0;
      border-radius: 6px;
      border-radius: var(--flix-primary-border-radius);
      background: #fff;
      background: var(--flix-box-bg-color);
      list-style: none;
      text-align: left;
      -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.12);
              box-shadow: 0 0 12px rgba(0, 0, 0, 0.12);
      -webkit-box-shadow: var(--flix-primary-box-shadow);
              box-shadow: var(--flix-primary-box-shadow);
      overflow: visible;
      z-index: 10;
      margin-top: -4px;
      margin-left: -135px;
      z-index: 1000; }
      .flix-header-subnav--open {
        display: block; } }
  @media (min-width: 1200px) {
    .flix-header-subnav {
      margin-top: -10px; } }
  @media (min-width: 600px) {
    .flix-header-subnav::before {
      width: 0;
      height: 0;
      border-top: 0;
      border-right: 6px solid transparent;
      border-bottom: 6px solid #fff;
      border-bottom: 6px solid var(--flix-box-bg-color);
      border-left: 6px solid transparent;
      content: '';
      position: absolute;
      top: -6px;
      left: 50%;
      width: 0;
      height: 0;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
      content: '';
      -webkit-transform: none;
              transform: none; } }
  .flix-header-nav__item:hover .flix-header-subnav {
    max-height: 2000px; }
    @media (min-width: 600px) {
      .flix-header-nav__item:hover .flix-header-subnav {
        display: block;
        max-height: none; } }
  .flix-header-subnav__item {
    margin-bottom: 12px;
    margin-bottom: var(--flix-spacing-xs); }
    @media (min-width: 600px) {
      .flix-header-subnav__item {
        margin-bottom: 0; } }
  .flix-header-subnav__link {
    display: block;
    position: relative;
    width: 100%;
    padding: 6px 24px;
    padding: var(--flix-spacing-xxs) var(--flix-spacing-sm);
    color: #444;
    color: var(--flix-primary-content-color);
    font-size: 16px;
    font-size: var(--flix-font-size-primary);
    font-weight: 400;
    font-weight: var(--flix-font-weight-semibold);
    text-decoration: none;
    cursor: pointer; }
    .flix-header-subnav__link:hover, .flix-header-subnav__link--active {
      color: #ffad00;
      color: var(--flix-strong-highlight-color); }
      .flix-header-subnav__link:hover::before, .flix-header-subnav__link--active::before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        border-left: 3px solid #ffad00;
        border-left: var(--flix-spacing-xxxs) solid var(--flix-strong-highlight-color);
        content: ''; }

.flix-header-nav-toggle {
  display: block; }
  @media (min-width: 600px) {
    .flix-header-nav-toggle {
      display: none; } }

.flix-header-bar {
  background: #73d700;
  background: var(--flix-header-bg-color);
  text-align: center;
  -webkit-box-shadow: inset 0 3px 0 #73d700, inset 0 -1px 0 #73d700;
          box-shadow: inset 0 3px 0 #73d700, inset 0 -1px 0 #73d700;
  -webkit-box-shadow: inset 0 3px 0 var(--flix-primary-brand-color), inset 0 -1px 0 var(--flix-header-bottom-border-color);
          box-shadow: inset 0 3px 0 var(--flix-primary-brand-color), inset 0 -1px 0 var(--flix-header-bottom-border-color);
  z-index: 1000;
  -webkit-font-smoothing: subpixel-antialiased; }
  .flix-header-bar--fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%; }
  .flix-header-bar__content {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    position: relative;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
    min-width: 320px;
    min-width: var(--flix-page-min-width);
    max-width: 1200px;
    max-width: var(--flix-page-max-width);
    height: 60px;
    padding: 0 12px;
    padding: 0 var(--flix-spacing-xs);
    text-align: left; }
  .flix-header-bar--fullwidth .flix-header-bar__content {
    max-width: none; }

.flix-h1, .flix-text h1 {
  color: #444;
  color: var(--flix-heading-font-color);
  font-size: 27px;
  font-size: var(--flix-font-size-h1);
  font-weight: 700;
  font-weight: var(--flix-font-weight-bold);
  line-height: 33px;
  line-height: var(--flix-line-height-h1);
  margin: 0;
  padding: 0 0 24px;
  padding: 0 0 var(--flix-spacing-sm); }

.flix-h2, .flix-text h2 {
  color: #444;
  color: var(--flix-heading-font-color);
  font-size: 21px;
  font-size: var(--flix-font-size-h2);
  font-weight: 700;
  font-weight: var(--flix-font-weight-bold);
  line-height: 27px;
  line-height: var(--flix-line-height-h2);
  margin: 0;
  padding: 12px 0;
  padding: var(--flix-spacing-xs) 0; }

.flix-h3, .flix-text h3 {
  color: #444;
  color: var(--flix-heading-font-color);
  font-size: 18px;
  font-size: var(--flix-font-size-h3);
  font-weight: 700;
  font-weight: var(--flix-font-weight-bold);
  line-height: 24px;
  line-height: var(--flix-line-height-h3);
  margin: 0;
  padding: 12px 0;
  padding: var(--flix-spacing-xs) 0; }

.flix-h4, .flix-text h4 {
  color: #444;
  color: var(--flix-heading-font-color);
  font-size: 16px;
  font-size: var(--flix-font-size-h4);
  font-weight: 700;
  font-weight: var(--flix-font-weight-bold);
  line-height: 24px;
  line-height: var(--flix-line-height-h4);
  margin: 0;
  padding: 12px 0;
  padding: var(--flix-spacing-xs) 0; }

.flix-h2--section-header, .flix-h3--section-header, .flix-h4--section-header {
  padding-top: 0; }

.flix-h1--space-flush, .flix-h2--space-flush, .flix-h3--space-flush, .flix-h4--space-flush {
  padding-top: 0;
  padding-bottom: 0; }

.flix-element-has-hint {
  position: relative; }
  .flix-element-has-hint::before, .flix-element-has-hint::after {
    display: none; }
  .flix-element-has-hint::before {
    position: absolute;
    width: 250px;
    min-height: 44px;
    padding: 12px;
    padding: var(--flix-spacing-xs);
    color: #444;
    color: var(--flix-primary-content-color);
    font-family: inherit;
    font-size: 16px;
    font-size: var(--flix-font-size-primary);
    text-decoration: none;
    z-index: 10;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 6px;
    border-radius: var(--flix-primary-border-radius);
    background: #fff;
    background: var(--flix-box-bg-color);
    -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.12);
            box-shadow: 0 0 12px rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: var(--flix-primary-box-shadow);
            box-shadow: var(--flix-primary-box-shadow);
    content: '';
    left: 100%;
    padding-left: 24px;
    padding-left: var(--flix-spacing-sm);
    top: 50%;
    bottom: auto;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: auto;
    min-width: 150px;
    margin: 0 0 0 6px;
    margin: 0 0 0 var(--flix-spacing-xxs);
    padding: 12px;
    padding: var(--flix-spacing-xs);
    content: attr(data-hint); }
    .flix-element-has-hint::before::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 6px;
      border-radius: var(--flix-primary-border-radius);
      background: #fff;
      background: var(--flix-box-bg-color);
      -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.12);
              box-shadow: 0 0 12px rgba(0, 0, 0, 0.12);
      -webkit-box-shadow: var(--flix-primary-box-shadow);
              box-shadow: var(--flix-primary-box-shadow);
      content: ''; }
    .flix-element-has-hint::before::before {
      left: 12px;
      left: var(--flix-spacing-xs); }
  .flix-element-has-hint::after {
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-right: 6px solid #fff;
    border-right: 6px solid var(--flix-box-bg-color);
    border-bottom: 6px solid transparent;
    border-left: 0;
    content: '';
    position: absolute;
    top: 50%;
    left: 100%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    z-index: 10; }
  .flix-element-has-hint--left::before {
    right: 100%;
    left: auto;
    padding-right: 24px;
    padding-right: var(--flix-spacing-sm);
    padding-left: 12px;
    padding-left: var(--flix-spacing-xs);
    margin: 0 6px 0 0;
    margin: 0 var(--flix-spacing-xxs) 0 0;
    padding: 12px;
    padding: var(--flix-spacing-xs); }
    .flix-element-has-hint--left::before::before {
      right: 12px;
      right: var(--flix-spacing-xs);
      left: 0; }
  .flix-element-has-hint--left::after {
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-right: 0;
    border-bottom: 6px solid transparent;
    border-left: 6px solid #fff;
    border-left: 6px solid var(--flix-box-bg-color);
    content: '';
    position: absolute;
    right: 100%;
    left: auto;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
  .flix-element-has-hint--top::before {
    top: auto;
    bottom: 100%;
    padding-bottom: 24px;
    padding-bottom: var(--flix-spacing-sm);
    padding-left: 12px;
    padding-left: var(--flix-spacing-xs);
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    margin: 0 0 6px;
    margin: 0 0 var(--flix-spacing-xxs);
    padding: 12px;
    padding: var(--flix-spacing-xs); }
    .flix-element-has-hint--top::before::before {
      bottom: 12px;
      bottom: var(--flix-spacing-xs);
      left: 0; }
  .flix-element-has-hint--top::after {
    width: 0;
    height: 0;
    border-top: 6px solid #fff;
    border-top: 6px solid var(--flix-box-bg-color);
    border-right: 6px solid transparent;
    border-bottom: 0;
    border-left: 6px solid transparent;
    content: '';
    top: auto;
    bottom: 100%;
    left: 50%; }
  .flix-element-has-hint--bottom::before {
    top: 100%;
    bottom: auto;
    padding-top: 24px;
    padding-top: var(--flix-spacing-sm);
    padding-left: 12px;
    padding-left: var(--flix-spacing-xs);
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    margin: 6px 0 0;
    margin: var(--flix-spacing-xxs) 0 0;
    padding: 12px;
    padding: var(--flix-spacing-xs); }
    .flix-element-has-hint--bottom::before::before {
      top: 12px;
      top: var(--flix-spacing-xs);
      left: 0; }
  .flix-element-has-hint--bottom::after {
    width: 0;
    height: 0;
    border-top: 0;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
    border-bottom: 6px solid var(--flix-box-bg-color);
    border-left: 6px solid transparent;
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    -webkit-transform: translateY(0%) translateX(-50%);
            transform: translateY(0%) translateX(-50%); }
  .flix-element-has-hint--error::before {
    background: #ff5700;
    background: var(--flix-danger-color);
    color: #fff;
    color: var(--flix-primary-bg-color); }
  .flix-element-has-hint--error::after {
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-right: 6px solid #ff5700;
    border-right: 6px solid var(--flix-danger-color);
    border-bottom: 6px solid transparent;
    border-left: 0;
    content: ''; }
  .flix-element-has-hint--error.flix-element-has-hint--left::after {
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-right: 0;
    border-bottom: 6px solid transparent;
    border-left: 6px solid #ff5700;
    border-left: 6px solid var(--flix-danger-color);
    content: ''; }
  .flix-element-has-hint--error.flix-element-has-hint--top::after {
    width: 0;
    height: 0;
    border-top: 6px solid #ff5700;
    border-top: 6px solid var(--flix-danger-color);
    border-right: 6px solid transparent;
    border-bottom: 0;
    border-left: 6px solid transparent;
    content: ''; }
  .flix-element-has-hint--error.flix-element-has-hint--bottom::after {
    width: 0;
    height: 0;
    border-top: 0;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #ff5700;
    border-bottom: 6px solid var(--flix-danger-color);
    border-left: 6px solid transparent;
    content: ''; }

.flix-element-has-hint:hover::before, .flix-element-has-hint:hover::after, .flix-element-has-hint:focus::before, .flix-element-has-hint:focus::after, .flix-element-has-hint--active::before, .flix-element-has-hint--active::after {
  display: block; }

.flix-img-link {
  display: block;
  margin-bottom: 12px;
  margin-bottom: var(--flix-spacing-xs);
  border: 1px solid #e1e1e1;
  border: 1px solid var(--flix-primary-line-color);
  border-radius: 6px;
  border-radius: var(--flix-primary-border-radius);
  font-family: inherit;
  text-decoration: none;
  overflow: hidden; }
  .flix-img-link:hover {
    -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.12);
            box-shadow: 0 0 12px rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: var(--flix-primary-box-shadow);
            box-shadow: var(--flix-primary-box-shadow); }
  .flix-img-link__img-wrapper {
    position: relative;
    min-height: 200px; }
    @media (min-width: 1200px) {
      .flix-img-link__img-wrapper {
        min-height: 160px; } }
  .flix-img-link__img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  @supports ((-o-object-fit: cover) or (object-fit: cover)) {
    .flix-img-link__img {
      height: 100%;
      -o-object-fit: cover;
         object-fit: cover; } }
  .flix-img-link__content {
    position: relative;
    padding: 12px 36px 12px 24px;
    padding: var(--flix-spacing-xs) var(--flix-spacing-md) var(--flix-spacing-xs) var(--flix-spacing-sm);
    background: #fff;
    background: var(--flix-primary-bg-color); }
    .flix-img-link__content::after {
      display: inline-block;
      width: 24px;
      width: var(--flix-spacing-sm);
      height: 24px;
      height: var(--flix-spacing-sm);
      content: '';
      vertical-align: middle;
      position: absolute;
      top: 50%;
      right: 12px;
      right: var(--flix-spacing-xs);
      margin-top: -11px;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M618 514.7L411.7 720.8A20.8 20.8 0 0 1 382.2 691.4L573.7 500 382 308.3A20.8 20.8 0 0 1 411.5 279.2L617.3 485C617.3 485.2 617.7 485.2 618 485.4A20.8 20.8 0 0 1 618 514.7Z' fill='rgba(139, 139, 139, 0.99)'/%3E%3C/svg%3E") no-repeat center;
      background: var(--flix-icon-arrow-right) no-repeat center; }
  .flix-img-link--sm {
    position: relative;
    min-height: 92px; }
    .flix-img-link--sm .flix-img-link__img-wrapper {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 90px;
      min-height: 90px; }
    .flix-img-link--sm .flix-img-link__content {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      width: calc(100% - 90px);
      min-height: 90px;
      margin-left: 90px; }

.flix-infobox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 12px;
  margin-bottom: var(--flix-spacing-xs);
  padding: 12px;
  padding: var(--flix-spacing-xs);
  border-radius: 6px;
  border-radius: var(--flix-primary-border-radius);
  background-color: #fff;
  background-color: var(--flix-box-bg-color);
  color: #444;
  color: var(--flix-primary-content-color);
  font-family: inherit;
  font-size: 16px;
  font-size: var(--flix-font-size-primary);
  font-weight: 400;
  font-weight: var(--flix-font-weight-normal);
  -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.12);
          box-shadow: 0 0 12px rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: var(--flix-primary-box-shadow);
          box-shadow: var(--flix-primary-box-shadow); }
  @media (min-width: 600px) {
    .flix-infobox {
      padding: 24px;
      padding: var(--flix-spacing-sm); } }
  .flix-infobox::before {
    display: inline-block;
    width: 48px;
    width: var(--flix-spacing-lg);
    height: 48px;
    height: var(--flix-spacing-lg);
    background-size: 48px;
    background-size: var(--flix-spacing-lg);
    content: '';
    vertical-align: middle;
    -ms-flex-item-align: start;
        align-self: flex-start;
    min-width: 48px;
    min-width: var(--flix-spacing-lg);
    margin-right: 15px;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM500 875A375 375 0 1 1 875 500 375.4 375.4 0 0 1 500 875ZM458.3 291.7A41.7 41.7 0 1 1 500 333.3 41.7 41.7 0 0 1 458.3 291.7ZM520.8 437.5V729.2A20.8 20.8 0 0 1 479.2 729.2V437.5A20.8 20.8 0 0 1 520.8 437.5Z' fill='rgba(0, 181, 226, 0.99)'/%3E%3C/svg%3E") no-repeat center;
    background: var(--flix-icon-info) no-repeat center;
    color: #00b5e2;
    color: var(--flix-link-color); }
  .flix-infobox--success::before {
    display: inline-block;
    width: 48px;
    width: var(--flix-spacing-lg);
    height: 48px;
    height: var(--flix-spacing-lg);
    background-size: 48px;
    background-size: var(--flix-spacing-lg);
    content: '';
    vertical-align: middle;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM500 875A375 375 0 1 1 875 500 375.4 375.4 0 0 1 500 875ZM729.2 354.2A20.8 20.8 0 0 1 723.1 368.9L431.4 660.6A21.1 21.1 0 0 1 424.6 665 20.1 20.1 0 0 1 417 666.6L416.7 666.7 416.3 666.7A20 20 0 0 1 408.7 665.1 20.8 20.8 0 0 1 401.9 660.7L276.9 535.7A20.8 20.8 0 0 1 306.4 506.2H306.4L416.7 616.4 693.6 339.4H693.6A20.8 20.8 0 0 1 729.2 354.2Z' fill='rgba(115, 215, 0, 0.99)'/%3E%3C/svg%3E") no-repeat center;
    background: var(--flix-icon-success) no-repeat center; }
  .flix-infobox--warning::before {
    display: inline-block;
    width: 48px;
    width: var(--flix-spacing-lg);
    height: 48px;
    height: var(--flix-spacing-lg);
    background-size: 48px;
    background-size: var(--flix-spacing-lg);
    content: '';
    vertical-align: middle;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM500 875A375 375 0 1 1 875 500 375.4 375.4 0 0 1 500 875ZM541.7 708.3A41.7 41.7 0 1 1 500 666.7 41.7 41.7 0 0 1 541.7 708.3ZM479.2 562.5V270.8A20.8 20.8 0 0 1 520.8 270.8V562.5A20.8 20.8 0 0 1 479.2 562.5Z' fill='rgba(255, 173, 0, 0.99)'/%3E%3C/svg%3E") no-repeat center;
    background: var(--flix-icon-warning) no-repeat center; }
  .flix-infobox--danger::before {
    display: inline-block;
    width: 48px;
    width: var(--flix-spacing-lg);
    height: 48px;
    height: var(--flix-spacing-lg);
    background-size: 48px;
    background-size: var(--flix-spacing-lg);
    content: '';
    vertical-align: middle;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM500 875A375 375 0 1 1 875 500 375.4 375.4 0 0 1 500 875ZM702.2 327.3L529.5 500 702.2 672.8H702.2A20.8 20.8 0 0 1 672.8 702.2L500 529.5 327.3 702.2A20.8 20.8 0 0 1 297.8 672.8H297.8L470.5 500 297.8 327.3A20.8 20.8 0 0 1 327.3 297.8H327.3L500 470.5 672.8 297.7H672.8A20.8 20.8 0 0 1 702.2 327.2Z' fill='rgba(255, 87, 0, 0.99)'/%3E%3C/svg%3E") no-repeat center;
    background: var(--flix-icon-danger) no-repeat center; }

.flix-input {
  position: relative; }
  .flix-input--error .flix-input__field,
  .flix-input--valid .flix-input__field {
    background-repeat: no-repeat;
    background-position: calc(100% - (12px)) center;
    background-position: calc(100% - (var(--flix-spacing-xs))) center;
    background-size: 24px 24px;
    background-size: var(--flix-spacing-sm) var(--flix-spacing-sm); }
  .flix-input--error .flix-input__field {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM479.2 270.8A20.8 20.8 0 0 1 520.8 270.8V562.5A20.8 20.8 0 0 1 479.2 562.5ZM500 750A41.7 41.7 0 1 1 541.7 708.3 41.7 41.7 0 0 1 500 750Z' fill='rgba(255, 87, 0, 0.99)'/%3E%3C/svg%3E");
    background-image: var(--flix-icon-attention-solid); }
  .flix-input--valid .flix-input__field {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM723.1 368.9L431.4 660.6A21.1 21.1 0 0 1 424.6 665 20.1 20.1 0 0 1 417 666.6L416.7 666.7 416.3 666.7A20 20 0 0 1 408.7 665.1 20.8 20.8 0 0 1 401.9 660.7L276.9 535.7A20.8 20.8 0 0 1 306.4 506.2H306.4L416.7 616.4 693.6 339.4H693.6A20.8 20.8 0 0 1 723.1 368.9Z' fill='rgba(115, 215, 0, 0.99)'/%3E%3C/svg%3E");
    background-image: var(--flix-icon-checkmark-solid); }
  .flix-input--disabled {
    opacity: 0.5;
    opacity: var(--flix-disabled-element-opacity); }
  .flix-input__info {
    color: #444;
    color: var(--flix-primary-content-color);
    font-size: 14px;
    font-size: var(--flix-font-size-small);
    font-weight: 400;
    font-weight: var(--flix-font-weight-normal);
    line-height: 18px;
    line-height: var(--flix-line-height-small);
    display: block;
    padding-top: 3px;
    padding-top: var(--flix-spacing-xxxs); }
  .flix-input--error .flix-input__info {
    color: #c64300;
    color: var(--flix-input-error-info-color); }
  .flix-input__info ~ .flix-input__info {
    color: #444;
    color: var(--flix-primary-content-color); }
  .flix-input [type='time']::-webkit-inner-spin-button,
  .flix-input [type='date']::-webkit-inner-spin-button,
  .flix-input [type='datetime-local']::-webkit-inner-spin-button,
  .flix-input [type='month']::-webkit-inner-spin-button,
  .flix-input [type='week']::-webkit-inner-spin-button {
    -webkit-appearance: none;
            appearance: none; }
  .flix-input [type='time']::-webkit-calendar-picker-indicator,
  .flix-input [type='date']::-webkit-calendar-picker-indicator,
  .flix-input [type='datetime-local']::-webkit-calendar-picker-indicator,
  .flix-input [type='month']::-webkit-calendar-picker-indicator,
  .flix-input [type='week']::-webkit-calendar-picker-indicator {
    display: block !important;
    -webkit-box-ordinal-group: 0;
            order: -1;
    width: 24px;
    width: var(--flix-spacing-sm);
    height: 24px;
    height: var(--flix-spacing-sm);
    margin-right: 6px;
    margin-right: var(--flix-spacing-xxs);
    margin-left: 0;
    padding: 0;
    color: transparent;
    opacity: 1; }
  .flix-input [type='time']::-webkit-clear-button,
  .flix-input [type='date']::-webkit-clear-button,
  .flix-input [type='datetime-local']::-webkit-clear-button,
  .flix-input [type='month']::-webkit-clear-button,
  .flix-input [type='week']::-webkit-clear-button {
    width: 24px;
    width: var(--flix-spacing-sm);
    height: 24px;
    height: var(--flix-spacing-sm);
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M827.2 797.8A20.8 20.8 0 0 1 797.8 827.2L500 529.5 202.3 827.2A20.8 20.8 0 0 1 172.8 797.8H172.8L470.5 500 172.8 202.3A20.8 20.8 0 0 1 202.3 172.8H202.3L500 470.5 797.8 172.7H797.8A20.8 20.8 0 0 1 827.2 202.2L529.5 500 827.2 797.8Z' fill='rgba(139, 139, 139, 0.99)'/%3E%3C/svg%3E");
    background: var(--flix-icon-close);
    background-size: 24px 24px;
    background-size: var(--flix-spacing-sm) var(--flix-spacing-sm);
    -webkit-appearance: none;
            appearance: none; }
  .flix-input [type='search']::-webkit-search-cancel-button {
    width: 24px;
    width: var(--flix-spacing-sm);
    height: 24px;
    height: var(--flix-spacing-sm);
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M827.2 797.8A20.8 20.8 0 0 1 797.8 827.2L500 529.5 202.3 827.2A20.8 20.8 0 0 1 172.8 797.8H172.8L470.5 500 172.8 202.3A20.8 20.8 0 0 1 202.3 172.8H202.3L500 470.5 797.8 172.7H797.8A20.8 20.8 0 0 1 827.2 202.2L529.5 500 827.2 797.8Z' fill='rgba(139, 139, 139, 0.99)'/%3E%3C/svg%3E");
    background: var(--flix-icon-close);
    background-size: 24px 24px;
    background-size: var(--flix-spacing-sm) var(--flix-spacing-sm);
    -webkit-appearance: none;
            appearance: none; }
  .flix-input [type='date']::-webkit-calendar-picker-indicator,
  .flix-input [type='datetime-local']::-webkit-calendar-picker-indicator,
  .flix-input [type='month']::-webkit-calendar-picker-indicator,
  .flix-input [type='week']::-webkit-calendar-picker-indicator {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M458.3 489.4A41.7 41.7 0 1 1 416.7 447.7 41.7 41.7 0 0 1 458.3 489.4ZM583.3 447.7A41.7 41.7 0 1 0 625 489.4 41.7 41.7 0 0 0 583.3 447.7ZM750 447.7A41.7 41.7 0 1 0 791.7 489.4 41.7 41.7 0 0 0 750 447.7ZM416.7 572.7A41.7 41.7 0 1 0 458.3 614.4 41.7 41.7 0 0 0 416.7 572.7ZM583.3 572.7A41.7 41.7 0 1 0 625 614.4 41.7 41.7 0 0 0 583.3 572.7ZM750 572.7A41.7 41.7 0 1 0 791.7 614.4 41.7 41.7 0 0 0 750 572.7ZM250 572.7A41.7 41.7 0 1 0 291.7 614.4 41.7 41.7 0 0 0 250 572.7ZM416.7 697.7A41.7 41.7 0 1 0 458.3 739.4 41.7 41.7 0 0 0 416.7 697.7ZM250 697.7A41.7 41.7 0 1 0 291.7 739.4 41.7 41.7 0 0 0 250 697.7ZM917.1 738.7A125.4 125.4 0 0 1 792.3 864L209 864.8A125.4 125.4 0 0 1 83.7 740L82.9 323.4A125.4 125.4 0 0 1 207.7 198.1H229.2V156A20.8 20.8 0 0 1 270.8 156V198L729.2 197.4V156A20.8 20.8 0 0 1 770.8 156V197.3H791A125.4 125.4 0 0 1 916.2 322L916.2 362.7C916.2 363.3 916.6 363.8 916.6 364.4S916.3 365.5 916.2 366ZM124.6 343.5H874.6V322.1A83.3 83.3 0 0 0 791.3 238.8H770.8V280.9A20.8 20.8 0 1 1 729.2 280.9V239L270.8 239.7V281A20.8 20.8 0 1 1 229.2 281V239.7H207.7A83.3 83.3 0 0 0 124.4 323.1ZM875.4 738.8L874.7 385.2H124.7L125.4 740A83.3 83.3 0 0 0 208.8 823.3L792.1 822.5A83.3 83.3 0 0 0 875.4 738.8Z' fill='rgba(139, 139, 139, 0.99)'/%3E%3C/svg%3E");
    background: var(--flix-icon-calendar);
    background-size: 24px 24px;
    background-size: var(--flix-spacing-sm) var(--flix-spacing-sm); }
  .flix-input [type='time']::-webkit-calendar-picker-indicator {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM500 875A375 375 0 1 1 875 500 375.4 375.4 0 0 1 500 875ZM639.7 610.3A20.8 20.8 0 0 1 610.2 639.8L485.2 514.8 485 514.3A20 20 0 0 1 480.8 508 20.3 20.3 0 0 1 479.2 500V208.3A20.8 20.8 0 0 1 520.8 208.3V491.4L639.7 610.2Z' fill='rgba(139, 139, 139, 0.99)'/%3E%3C/svg%3E");
    background: var(--flix-icon-time);
    background-size: 24px 24px;
    background-size: var(--flix-spacing-sm) var(--flix-spacing-sm); }
  .flix-input__field {
    position: relative;
    width: 100%;
    height: 44px;
    height: var(--flix-input-height-mobile);
    padding: 0 12px;
    padding: 0 var(--flix-spacing-xs);
    border: 1px solid #c8c8c8;
    border: 1px solid var(--flix-input-border-color);
    border-radius: 6px;
    border-radius: var(--flix-input-border-radius);
    background-color: #fff;
    background-color: var(--flix-input-bg-color);
    color: #444;
    color: var(--flix-primary-content-color);
    font-family: inherit;
    font-size: 16px;
    font-size: var(--flix-font-size-primary);
    font-weight: 400;
    font-weight: var(--flix-font-weight-normal);
    line-height: var(--flix-line-height-mobile);
    text-overflow: ellipsis;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none; }
    @media (min-width: 1200px) {
      .flix-input__field {
        height: 36px;
        height: var(--flix-input-height-desktop);
        line-height: var(--flix-line-height-desktop); } }
    .flix-input__field::-webkit-input-placeholder {
      color: #757575;
      color: var(--flix-input-placeholder-color);
      font-style: italic; }
    .flix-input__field::-moz-placeholder {
      color: #757575;
      color: var(--flix-input-placeholder-color);
      font-style: italic; }
    .flix-input__field:-ms-input-placeholder {
      color: #757575;
      color: var(--flix-input-placeholder-color);
      font-style: italic; }
    .flix-input__field::-ms-input-placeholder {
      color: #757575;
      color: var(--flix-input-placeholder-color);
      font-style: italic; }
    .flix-input__field::placeholder {
      color: #757575;
      color: var(--flix-input-placeholder-color);
      font-style: italic; }
    .flix-input--valid .flix-input__field {
      padding-right: 40px; }
    .flix-input--error .flix-input__field {
      padding-right: 40px;
      border: 1px solid #ff5700;
      border: 1px solid var(--flix-input-error-border-color); }
    .flix-input--active .flix-input__field, .flix-input__field:focus {
      border: 1px solid #757575;
      border: 1px solid var(--flix-input-active-border-color);
      outline: none; }
    .flix-input--disabled .flix-input__field {
      cursor: not-allowed; }
    .flix-input--has-icon .flix-input__field {
      padding-left: 40px; }
    .flix-input--loading .flix-input__field {
      padding-right: 48px;
      padding-right: var(--flix-spacing-lg); }
  .flix-input .flix-input__icon {
    display: block;
    position: absolute;
    left: 12px;
    left: var(--flix-spacing-xs);
    margin-top: calc(-1 * 44px / 2);
    margin-top: calc(-1 * var(--flix-input-height-mobile) / 2);
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    z-index: 2;
    pointer-events: none; }
    @media (min-width: 1200px) {
      .flix-input .flix-input__icon {
        margin-top: calc(-1 * 36px / 2);
        margin-top: calc(-1 * var(--flix-input-height-desktop) / 2); } }
    .flix-input .flix-input__icon::before {
      margin-left: 0; }
  .flix-input__spinner {
    display: block;
    position: absolute;
    right: 12px;
    right: var(--flix-spacing-xs);
    margin-top: calc(-1 * 44px / 2 - 12px);
    margin-top: calc(-1 * var(--flix-input-height-mobile) / 2 - var(--flix-spacing-xs));
    z-index: 2; }
    @media (min-width: 1200px) {
      .flix-input__spinner {
        margin-top: calc(-1 * 36px / 2 - 12px);
        margin-top: calc(-1 * var(--flix-input-height-desktop) / 2 - var(--flix-spacing-xs)); } }
  .flix-input--has-inline-label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .flix-input--has-inline-label .flix-input__label {
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0; }
  .flix-input__inline-label {
    position: relative;
    width: 100%;
    height: 44px;
    height: var(--flix-input-height-mobile);
    padding: 0 12px;
    padding: 0 var(--flix-spacing-xs);
    border: 1px solid #c8c8c8;
    border: 1px solid var(--flix-input-border-color);
    border-radius: 6px;
    border-radius: var(--flix-input-border-radius);
    background-color: #fff;
    background-color: var(--flix-input-bg-color);
    color: #444;
    color: var(--flix-primary-content-color);
    font-family: inherit;
    font-size: 16px;
    font-size: var(--flix-font-size-primary);
    font-weight: 400;
    font-weight: var(--flix-font-weight-normal);
    line-height: var(--flix-line-height-mobile);
    text-overflow: ellipsis;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
    width: auto;
    border-radius: 6px 0 0 6px;
    border-radius: var(--flix-input-border-radius) 0 0 var(--flix-input-border-radius); }
    @media (min-width: 1200px) {
      .flix-input__inline-label {
        height: 36px;
        height: var(--flix-input-height-desktop);
        line-height: var(--flix-line-height-desktop); } }
    .flix-input__inline-label::-webkit-input-placeholder {
      color: #757575;
      color: var(--flix-input-placeholder-color);
      font-style: italic; }
    .flix-input__inline-label::-moz-placeholder {
      color: #757575;
      color: var(--flix-input-placeholder-color);
      font-style: italic; }
    .flix-input__inline-label:-ms-input-placeholder {
      color: #757575;
      color: var(--flix-input-placeholder-color);
      font-style: italic; }
    .flix-input__inline-label::-ms-input-placeholder {
      color: #757575;
      color: var(--flix-input-placeholder-color);
      font-style: italic; }
    .flix-input__inline-label::placeholder {
      color: #757575;
      color: var(--flix-input-placeholder-color);
      font-style: italic; }
    .flix-input--active .flix-input__inline-label,
    .flix-input__field:focus ~ .flix-input__inline-label {
      border-color: #757575;
      border-color: var(--flix-input-active-border-color);
      border-right-color: #c8c8c8;
      border-right-color: var(--flix-input-border-color); }
    .flix-input--error .flix-input__inline-label {
      border-color: #ff5700;
      border-color: var(--flix-input-error-border-color);
      border-right-color: #c8c8c8;
      border-right-color: var(--flix-input-border-color); }
  .flix-input--has-inline-label .flix-input__icon {
    position: static;
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
    margin: 0 calc(1px - 24px) 0 0;
    margin: 0 calc(1px - var(--flix-spacing-sm)) 0 0;
    -webkit-transform: translate(12px, 0);
            transform: translate(12px, 0);
    -webkit-transform: translate(var(--flix-spacing-xs), 0);
            transform: translate(var(--flix-spacing-xs), 0); }
  .flix-input--has-inline-label .flix-input__field {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
    min-width: 0;
    border-left: 0;
    border-radius: 0 6px 6px 0;
    border-radius: 0 var(--flix-input-border-radius) var(--flix-input-border-radius) 0; }
  .flix-input--has-inline-label .flix-input__info {
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4; }

.flix-input .flix-input__spinner {
  display: block; }

.flix-label {
  display: block;
  position: absolute;
  top: -8px;
  left: 7px;
  padding: 1px 5px;
  border-radius: 6px;
  border-radius: var(--flix-primary-border-radius);
  background: #fff;
  background: var(--flix-primary-bg-color);
  color: #444;
  color: var(--flix-primary-content-color);
  font-family: inherit;
  font-size: 14px;
  font-size: var(--flix-font-size-small);
  font-weight: 400;
  font-weight: var(--flix-font-weight-normal);
  line-height: 1em;
  text-align: left;
  z-index: 1; }
  @media (min-width: 1200px) {
    .flix-label {
      position: static;
      top: auto;
      left: auto;
      padding: 0 0 2px;
      background: transparent;
      color: #757575;
      color: var(--flix-secondary-content-color);
      font-size: 16px;
      font-size: var(--flix-font-size-primary);
      line-height: 1.5em;
      line-height: var(--flix-line-height-primary); } }

.flix-legend {
  display: block;
  width: 100%;
  padding-bottom: 2px;
  color: #757575;
  color: var(--flix-secondary-content-color);
  font-family: inherit;
  font-size: 16px;
  font-size: var(--flix-font-size-primary);
  font-weight: 400;
  font-weight: var(--flix-font-weight-normal); }

.flix-link, .flix-text a {
  background-color: transparent;
  color: #00b5e2;
  color: var(--flix-link-color);
  font-weight: 400;
  font-weight: var(--flix-font-weight-semibold);
  text-decoration: none; }
  .flix-link:hover, .flix-text a:hover, .flix-link:active, .flix-text a:active, .flix-link:focus, .flix-text a:focus {
    color: #00b5e2;
    color: var(--flix-link-color);
    text-decoration: underline; }

.flix-list, .flix-text ul {
  margin: 0 0 12px;
  margin: 0 0 var(--flix-spacing-xs);
  padding-left: 12px;
  padding-left: var(--flix-spacing-xs);
  list-style-type: none; }

.flix-list .flix-list, .flix-text ul ul {
  margin: 0;
  padding-left: 0; }

.flix-list__item, .flix-text ul li {
  position: relative;
  padding-left: calc(12px + 3px);
  padding-left: calc(var(--flix-spacing-xs) + var(--flix-spacing-xxxs));
  color: #444;
  color: var(--flix-primary-content-color);
  line-height: 1.5em;
  line-height: var(--flix-line-height-primary); }
  .flix-list__item::before, .flix-text ul li::before {
    display: block;
    position: absolute;
    top: calc(1.5em / 2);
    top: calc(var(--flix-line-height-primary) / 2);
    left: 0;
    width: 3px;
    width: var(--flix-spacing-xxxs);
    height: 3px;
    height: var(--flix-spacing-xxxs);
    margin-top: -2px;
    margin-right: 12px;
    margin-right: var(--flix-spacing-xs);
    border-radius: 50%;
    background: #444;
    background: var(--flix-primary-content-color);
    content: ''; }

.flix-list-simple {
  margin: 0;
  padding-left: 0;
  list-style-type: none; }
  .flix-list-simple__item {
    min-height: 40px;
    padding: 8px 0;
    border-bottom: 1px solid #e1e1e1;
    border-bottom: 1px solid var(--flix-primary-line-color);
    color: #444;
    color: var(--flix-primary-content-color);
    font-size: 16px;
    font-size: var(--flix-font-size-primary); }
    .flix-list-simple__item:last-child {
      border-bottom: 0; }

.flix-list-linked {
  margin: 0;
  padding-left: 0;
  list-style-type: none; }
  .flix-list-linked__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
    min-height: 48px;
    min-height: var(--flix-spacing-lg);
    margin-top: -1px;
    border: 1px solid #e1e1e1;
    border: 1px solid var(--flix-primary-line-color); }
    .flix-list-linked__item::after {
      display: inline-block;
      width: 24px;
      width: var(--flix-spacing-sm);
      height: 24px;
      height: var(--flix-spacing-sm);
      content: '';
      vertical-align: middle;
      position: absolute;
      top: 50%;
      right: 12px;
      right: var(--flix-spacing-xs);
      margin-top: -11px;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M618 514.7L411.7 720.8A20.8 20.8 0 0 1 382.2 691.4L573.7 500 382 308.3A20.8 20.8 0 0 1 411.5 279.2L617.3 485C617.3 485.2 617.7 485.2 618 485.4A20.8 20.8 0 0 1 618 514.7Z' fill='rgba(139, 139, 139, 0.99)'/%3E%3C/svg%3E") no-repeat center;
      background: var(--flix-icon-arrow-right) no-repeat center; }
    .flix-list-linked__item:first-of-type {
      border-top-left-radius: 6px;
      border-top-left-radius: var(--flix-primary-border-radius);
      border-top-right-radius: 6px;
      border-top-right-radius: var(--flix-primary-border-radius); }
    .flix-list-linked__item:last-of-type {
      border-bottom-left-radius: 6px;
      border-bottom-left-radius: var(--flix-primary-border-radius);
      border-bottom-right-radius: 6px;
      border-bottom-right-radius: var(--flix-primary-border-radius); }
    .flix-list-linked__item--error {
      border-color: #ff5700;
      border-color: var(--flix-danger-color); }
      .flix-list-linked__item--error + .flix-list-linked__item {
        margin-top: 0; }
      .flix-list-linked__item--error + .flix-list-linked__item--error {
        margin-top: -1px; }
    .flix-list-linked__item--disabled {
      opacity: 0.5;
      opacity: var(--flix-disabled-element-opacity); }
  .flix-list-linked a, .flix-list-linked__link {
    display: block;
    width: 100%;
    padding: 12px 24px 12px 12px;
    padding: var(--flix-spacing-xs) var(--flix-spacing-sm) var(--flix-spacing-xs) var(--flix-spacing-xs);
    color: #444;
    color: var(--flix-primary-content-color);
    font-size: 16px;
    font-size: var(--flix-font-size-primary);
    font-weight: 400;
    font-weight: var(--flix-font-weight-semibold);
    line-height: 36px;
    line-height: var(--flix-spacing-md);
    text-decoration: none;
    cursor: pointer; }
    .flix-list-linked a:hover, .flix-list-linked__link:hover {
      background: #f7f7f7;
      background: var(--flix-secondary-bg-color); }
    .flix-list-linked a:active, .flix-list-linked a:focus, .flix-list-linked__link:active, .flix-list-linked__link:focus {
      text-decoration: none; }
    .flix-list-linked__item--disabled .flix-list-linked a, .flix-list-linked__item--disabled .flix-list-linked__link {
      cursor: not-allowed; }

.flix-list-numbered, .flix-text ol {
  margin: 0 0 12px;
  margin: 0 0 var(--flix-spacing-xs);
  padding-left: 12px;
  padding-left: var(--flix-spacing-xs);
  list-style-type: none;
  counter-reset: numbered-list-counter; }

.flix-list-numbered__item, .flix-text ol > li {
  display: table;
  position: relative;
  color: #444;
  color: var(--flix-primary-content-color); }
  .flix-list-numbered__item::before, .flix-text ol > li::before {
    display: table-cell;
    padding-right: 6px;
    font-weight: 700;
    font-weight: var(--flix-font-weight-bold);
    content: counters(numbered-list-counter, ".") ".";
    counter-increment: numbered-list-counter; }

.flix-list-numbered .flix-list-numbered, .flix-text ol ol {
  width: 100%;
  margin: 0;
  padding-left: 0; }

.flix-list-numbered .flix-list-numbered .flix-list-numbered__item::before, .flix-text ol ol > li::before {
  font-weight: 400;
  font-weight: var(--flix-font-weight-normal);
  content: counters(numbered-list-counter, "."); }

.flix-list-wrapper {
  margin: 0 0 12px;
  margin: 0 0 var(--flix-spacing-xs);
  padding-left: 0;
  list-style-type: none; }
  .flix-list-wrapper__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
    min-height: 48px;
    min-height: var(--flix-spacing-lg);
    border: solid #e1e1e1;
    border: solid var(--flix-primary-line-color);
    border-width: 0 1px 1px;
    color: #444;
    color: var(--flix-primary-content-color);
    font-size: 16px;
    font-size: var(--flix-font-size-primary); }
    .flix-list-wrapper__item:first-of-type {
      border-top-width: 1px;
      border-top-left-radius: 6px;
      border-top-left-radius: var(--flix-primary-border-radius);
      border-top-right-radius: 6px;
      border-top-right-radius: var(--flix-primary-border-radius); }
    .flix-list-wrapper__item:last-of-type {
      border-bottom-left-radius: 6px;
      border-bottom-left-radius: var(--flix-primary-border-radius);
      border-bottom-right-radius: 6px;
      border-bottom-right-radius: var(--flix-primary-border-radius); }
    .flix-list-wrapper__item--error {
      margin-top: -1px;
      border-width: 1px;
      border-color: #ff5700;
      border-color: var(--flix-danger-color); }
      .flix-list-wrapper__item--error + .flix-list-wrapper__item--error {
        border-width: 0 1px 1px; }
    .flix-list-wrapper__item--disabled {
      opacity: 0.5;
      opacity: var(--flix-disabled-element-opacity); }
  .flix-list-wrapper__content {
    display: block;
    width: 100%;
    padding: 12px 24px 12px 12px;
    padding: var(--flix-spacing-xs) var(--flix-spacing-sm) var(--flix-spacing-xs) var(--flix-spacing-xs);
    color: #444;
    color: var(--flix-primary-content-color);
    font-weight: 400;
    font-weight: var(--flix-font-weight-semibold);
    line-height: 36px;
    line-height: var(--flix-spacing-md); }
    .flix-list-wrapper__item--disabled .flix-list-wrapper__content {
      cursor: not-allowed; }
  .flix-list-wrapper a.flix-list-wrapper__content {
    text-decoration: none;
    cursor: pointer; }
    .flix-list-wrapper a.flix-list-wrapper__content:hover {
      background: #f7f7f7;
      background: var(--flix-secondary-bg-color); }
    .flix-list-wrapper a.flix-list-wrapper__content:active, .flix-list-wrapper a.flix-list-wrapper__content:focus {
      text-decoration: none; }
    .flix-list-wrapper a.flix-list-wrapper__content::after {
      display: inline-block;
      width: 24px;
      width: var(--flix-spacing-sm);
      height: 24px;
      height: var(--flix-spacing-sm);
      content: '';
      vertical-align: middle;
      position: absolute;
      top: 50%;
      right: 12px;
      right: var(--flix-spacing-xs);
      margin-top: -11px;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M618 514.7L411.7 720.8A20.8 20.8 0 0 1 382.2 691.4L573.7 500 382 308.3A20.8 20.8 0 0 1 411.5 279.2L617.3 485C617.3 485.2 617.7 485.2 618 485.4A20.8 20.8 0 0 1 618 514.7Z' fill='rgba(139, 139, 139, 0.99)'/%3E%3C/svg%3E") no-repeat center;
      background: var(--flix-icon-arrow-right) no-repeat center; }
  .flix-list-wrapper--small .flix-list-wrapper__item {
    min-height: 36px;
    min-height: var(--flix-spacing-md);
    padding: 6px;
    padding: var(--flix-spacing-xxs); }
  .flix-list-wrapper--small .flix-list-wrapper__content {
    margin: calc(6px * -1) 0;
    margin: calc(var(--flix-spacing-xxs) * -1) 0;
    padding: 6px 0;
    padding: var(--flix-spacing-xxs) 0;
    font-weight: 400;
    font-weight: var(--flix-font-weight-normal);
    line-height: 24px;
    line-height: var(--flix-spacing-sm); }

.flix-nav-tab-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  width: 100%;
  height: 60px;
  padding-right: 6px;
  padding-right: var(--flix-spacing-xxs);
  padding-left: 6px;
  padding-left: var(--flix-spacing-xxs);
  background-color: #fff;
  background-color: var(--flix-box-bg-color);
  -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.12);
          box-shadow: 0 0 12px rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: var(--flix-primary-box-shadow);
          box-shadow: var(--flix-primary-box-shadow);
  z-index: 1000; }
  .flix-nav-tab-bar__tab {
    display: inline-block;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    padding-right: 6px;
    padding-right: var(--flix-spacing-xxs);
    padding-left: 6px;
    padding-left: var(--flix-spacing-xxs);
    text-align: center;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }
    .flix-nav-tab-bar__tab--active .flix-nav-tab-bar__label,
    .flix-nav-tab-bar__tab--active .flix-nav-tab-bar__icon {
      color: #73d700;
      color: var(--flix-primary-ui-color); }
  .flix-nav-tab-bar__icon {
    color: #8b8b8b;
    color: var(--flix-grayscale-70-color);
    font-size: 1.5em; }
  .flix-nav-tab-bar__label {
    display: block;
    margin: 0;
    padding-top: 3px;
    padding-top: var(--flix-spacing-xxxs);
    color: #8b8b8b;
    color: var(--flix-grayscale-70-color);
    font-size: 12px;
    font-weight: 400;
    font-weight: var(--flix-font-weight-semibold);
    line-height: 1em;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }

.flix-nav-horizontal {
  margin: 0;
  margin-bottom: 24px;
  margin-bottom: var(--flix-spacing-sm);
  padding: 0; }
  .flix-nav-horizontal--centered {
    text-align: center; }
  .flix-nav-horizontal--right {
    text-align: right; }
  .flix-nav-horizontal--left {
    text-align: left; }
  .flix-nav-horizontal__items {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    margin: 0;
    padding: 0; }
  .flix-nav-horizontal__item {
    position: relative;
    max-width: 50%;
    text-align: center;
    white-space: nowrap;
    list-style-type: none; }
    .flix-nav-horizontal__item::before {
      display: inline-block;
      position: absolute;
      bottom: 12px;
      bottom: var(--flix-spacing-xs);
      left: 0;
      height: 16px;
      border-left: 1px solid #e1e1e1;
      border-left: 1px solid var(--flix-primary-line-color);
      content: ''; }
  .flix-nav-horizontal__item:first-child::before {
    display: none;
    content: none; }
  .flix-nav-horizontal__link {
    display: inline-block;
    width: 100%;
    padding: 9px 12px;
    padding: 9px var(--flix-spacing-xs);
    color: #444;
    color: var(--flix-primary-content-color);
    font-size: 16px;
    font-size: var(--flix-font-size-primary);
    font-weight: 400;
    font-weight: var(--flix-font-weight-semibold);
    line-height: 1.5em;
    line-height: var(--flix-line-height-primary);
    text-decoration: none;
    cursor: pointer; }
    .flix-nav-horizontal__link .flix-icon:first-child {
      margin-right: 3px;
      margin-right: var(--flix-spacing-xxxs); }
    .flix-nav-horizontal__link .flix-icon:last-child {
      margin-left: 3px;
      margin-left: var(--flix-spacing-xxxs); }
    .flix-nav-horizontal__link .flix-icon::before {
      margin: 0; }
    .flix-nav-horizontal__link:hover, .flix-nav-horizontal__link:active, .flix-nav-horizontal__link:focus, .flix-nav-horizontal__link--active {
      color: #ffad00;
      color: var(--flix-strong-highlight-color);
      text-decoration: none; }
      .flix-nav-horizontal__link:hover::before, .flix-nav-horizontal__link:active::before, .flix-nav-horizontal__link:focus::before, .flix-nav-horizontal__link--active::before {
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        height: 0;
        border-bottom: 3px solid #ffad00;
        border-bottom: var(--flix-spacing-xxxs) solid var(--flix-strong-highlight-color);
        content: ''; }

.flix-nav-side, .flix-nav-side-subnav {
  position: relative;
  margin: 0;
  padding: 0; }
  .flix-nav-side--centered {
    text-align: center; }
  .flix-nav-side__item, .flix-nav-side-subnav__item {
    display: block;
    position: relative; }
  .flix-nav-side__link, .flix-nav-side-subnav__link {
    display: inline-block;
    position: relative;
    width: 100%;
    padding: 5px 0;
    color: #444;
    color: var(--flix-primary-content-color);
    font-size: 16px;
    font-size: var(--flix-font-size-primary);
    font-weight: 400;
    font-weight: var(--flix-font-weight-semibold);
    line-height: 1.5em;
    line-height: var(--flix-line-height-primary);
    text-decoration: none;
    cursor: pointer; }
    .flix-nav-side__link .flix-icon:first-child, .flix-nav-side-subnav__link .flix-icon:first-child {
      margin-right: 3px;
      margin-right: var(--flix-spacing-xxxs); }
    .flix-nav-side__link .flix-icon:last-child, .flix-nav-side-subnav__link .flix-icon:last-child {
      margin-left: 3px;
      margin-left: var(--flix-spacing-xxxs); }
    .flix-nav-side__link .flix-icon::before, .flix-nav-side-subnav__link .flix-icon::before {
      margin: 0; }
    .flix-nav-side__link:hover, .flix-nav-side-subnav__link:hover, .flix-nav-side__link:active, .flix-nav-side-subnav__link:active, .flix-nav-side__link:focus, .flix-nav-side-subnav__link:focus {
      color: #ffad00;
      color: var(--flix-strong-highlight-color);
      text-decoration: none; }
  .flix-nav-side__link--active {
    color: #ffad00;
    color: var(--flix-strong-highlight-color); }
  .flix-nav-side__link--has-subnav:after {
    display: inline-block;
    width: 24px;
    width: var(--flix-spacing-sm);
    height: 24px;
    height: var(--flix-spacing-sm);
    content: '';
    vertical-align: middle;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M485.3 618L279.2 411.7A20.8 20.8 0 1 1 308.3 382.2L500 573.7 691.7 382A20.8 20.8 0 0 1 721.1 411.5L515.3 617.3C515.1 617.3 515.1 617.7 514.9 617.9A20.8 20.8 0 0 1 485.3 618Z' fill='rgba(139, 139, 139, 0.99)'/%3E%3C/svg%3E") no-repeat center;
    background: var(--flix-icon-arrow-down) no-repeat center; }
  .flix-nav-side__link--has-subnav:hover::after, .flix-nav-side__link--has-subnav:active::after, .flix-nav-side__link--has-subnav:focus::after, .flix-nav-side__link--has-subnav.flix-nav-side__link--active::after {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M485.3 618L279.2 411.7A20.8 20.8 0 1 1 308.3 382.2L500 573.7 691.7 382A20.8 20.8 0 0 1 721.1 411.5L515.3 617.3C515.1 617.3 515.1 617.7 514.9 617.9A20.8 20.8 0 0 1 485.3 618Z' fill='rgba(255, 173, 0, 0.99)'/%3E%3C/svg%3E") no-repeat center;
    background: var(--flix-icon-arrow-down-active) no-repeat center; }
  .flix-nav-side__link--has-subnav-open:after {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  .flix-nav-side__link--has-subnav-open:hover::after, .flix-nav-side__link--has-subnav-open:active::after, .flix-nav-side__link--has-subnav-open:focus::after {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  .flix-nav-side-subnav {
    padding-left: 12px;
    padding-left: var(--flix-spacing-xs); }
    .flix-nav-side-subnav__link--active {
      color: #ffad00;
      color: var(--flix-strong-highlight-color); }

.flix-notification {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  margin: 12px 0;
  margin: var(--flix-spacing-xs) 0;
  padding: 12px;
  padding: var(--flix-spacing-xs);
  border-radius: 6px;
  border-radius: var(--flix-primary-border-radius);
  background: #fff;
  background: var(--flix-box-bg-color);
  color: #444;
  color: var(--flix-primary-content-color);
  font-family: inherit;
  font-size: 16px;
  font-size: var(--flix-font-size-primary);
  font-weight: 700;
  font-weight: var(--flix-font-weight-bold);
  -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.12);
          box-shadow: 0 0 12px rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: var(--flix-primary-box-shadow);
          box-shadow: var(--flix-primary-box-shadow); }
  .flix-notification::before {
    display: inline-block;
    width: 24px;
    width: var(--flix-spacing-sm);
    height: 24px;
    height: var(--flix-spacing-sm);
    content: '';
    vertical-align: middle;
    -ms-flex-item-align: start;
        align-self: flex-start;
    min-width: 24px;
    min-width: var(--flix-spacing-sm);
    margin-right: 12px;
    margin-right: var(--flix-spacing-xs);
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM520.8 729.2A20.8 20.8 0 0 1 479.2 729.2V437.5A20.8 20.8 0 0 1 520.8 437.5ZM500 333.3A41.7 41.7 0 1 1 541.7 291.7 41.7 41.7 0 0 1 500 333.3Z' fill='rgba(0, 181, 226, 0.99)'/%3E%3C/svg%3E") no-repeat center;
    background: var(--flix-icon-info-solid) no-repeat center; }
  .flix-notification--error::before {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM702.2 672.8H702.2A20.8 20.8 0 0 1 672.8 702.2L500 529.5 327.3 702.2A20.8 20.8 0 0 1 297.8 672.8H297.8L470.5 500 297.8 327.3A20.8 20.8 0 0 1 327.3 297.8H327.3L500 470.5 672.8 297.7H672.8A20.8 20.8 0 0 1 702.2 327.2L529.5 500Z' fill='rgba(255, 87, 0, 0.99)'/%3E%3C/svg%3E") no-repeat center;
    background: var(--flix-icon-danger-solid) no-repeat center; }
  .flix-notification--warning::before {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM479.2 270.8A20.8 20.8 0 0 1 520.8 270.8V562.5A20.8 20.8 0 0 1 479.2 562.5ZM500 750A41.7 41.7 0 1 1 541.7 708.3 41.7 41.7 0 0 1 500 750Z' fill='rgba(255, 173, 0, 0.99)'/%3E%3C/svg%3E") no-repeat center;
    background: var(--flix-icon-warning-solid) no-repeat center; }
  .flix-notification--success::before {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM723.1 368.9L431.4 660.6A21.1 21.1 0 0 1 424.6 665 20.1 20.1 0 0 1 417 666.6L416.7 666.7 416.3 666.7A20 20 0 0 1 408.7 665.1 20.8 20.8 0 0 1 401.9 660.7L276.9 535.7A20.8 20.8 0 0 1 306.4 506.2H306.4L416.7 616.4 693.6 339.4H693.6A20.8 20.8 0 0 1 723.1 368.9Z' fill='rgba(115, 215, 0, 0.99)'/%3E%3C/svg%3E") no-repeat center;
    background: var(--flix-icon-success-solid) no-repeat center; }
  .flix-notification--toast {
    opacity: 0;
    z-index: 1;
    -webkit-animation: 4s sneak-peek-notification-top;
            animation: 4s sneak-peek-notification-top; }
    .flix-notification-container--bottom-left .flix-notification--toast,
    .flix-notification-container--bottom-right .flix-notification--toast {
      -webkit-animation: 4s sneak-peek-notification-bottom;
              animation: 4s sneak-peek-notification-bottom; }
  .flix-notification__content {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1; }
  .flix-notification__close {
    border: 0;
    background: transparent;
    font-size: 16px;
    font-size: var(--flix-font-size-primary);
    cursor: pointer;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    -ms-flex-item-align: start;
        align-self: flex-start;
    margin-left: 12px;
    margin-left: var(--flix-spacing-xs);
    display: inline-block;
    width: 24px;
    width: var(--flix-spacing-sm);
    height: 24px;
    height: var(--flix-spacing-sm);
    content: '';
    vertical-align: middle;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M827.2 797.8A20.8 20.8 0 0 1 797.8 827.2L500 529.5 202.3 827.2A20.8 20.8 0 0 1 172.8 797.8H172.8L470.5 500 172.8 202.3A20.8 20.8 0 0 1 202.3 172.8H202.3L500 470.5 797.8 172.7H797.8A20.8 20.8 0 0 1 827.2 202.2L529.5 500 827.2 797.8Z' fill='rgba(139, 139, 139, 0.99)'/%3E%3C/svg%3E") no-repeat center;
    background: var(--flix-icon-close) no-repeat center;
    background-size: 20px; }
    .flix-notification__close .flix-icon {
      display: none; }

.flix-notification-container {
  position: fixed;
  width: 100%;
  padding: 0 12px;
  padding: 0 var(--flix-spacing-xs);
  z-index: 1209; }
  @media (min-width: 600px) {
    .flix-notification-container {
      width: auto;
      padding: 24px 36px;
      padding: var(--flix-spacing-sm) var(--flix-spacing-md); } }
  .flix-notification-container, .flix-notification-container--top-right {
    top: 0;
    right: 0; }
  .flix-notification-container--top-left {
    top: 0;
    right: auto;
    left: 0; }
  .flix-notification-container--bottom-left {
    top: auto;
    right: auto;
    bottom: 0;
    left: 0; }
  .flix-notification-container--bottom-right {
    top: auto;
    right: 0;
    bottom: 0; }

@-webkit-keyframes sneak-peek-notification-top {
  0% {
    -webkit-transform: translateY(-var(--flix-spacing-sm));
            transform: translateY(-var(--flix-spacing-sm));
    opacity: 0; }
  7.5% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; }
  92.5% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; }
  100% {
    -webkit-transform: translateY(-var(--flix-spacing-sm));
            transform: translateY(-var(--flix-spacing-sm));
    opacity: 0; } }

@keyframes sneak-peek-notification-top {
  0% {
    -webkit-transform: translateY(-var(--flix-spacing-sm));
            transform: translateY(-var(--flix-spacing-sm));
    opacity: 0; }
  7.5% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; }
  92.5% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; }
  100% {
    -webkit-transform: translateY(-var(--flix-spacing-sm));
            transform: translateY(-var(--flix-spacing-sm));
    opacity: 0; } }

@-webkit-keyframes sneak-peek-notification-bottom {
  0% {
    -webkit-transform: translateY(24px);
            transform: translateY(24px);
    -webkit-transform: translateY(var(--flix-spacing-sm));
            transform: translateY(var(--flix-spacing-sm));
    opacity: 0; }
  7.5% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; }
  92.5% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; }
  100% {
    -webkit-transform: translateY(24px);
            transform: translateY(24px);
    -webkit-transform: translateY(var(--flix-spacing-sm));
            transform: translateY(var(--flix-spacing-sm));
    opacity: 0; } }

@keyframes sneak-peek-notification-bottom {
  0% {
    -webkit-transform: translateY(24px);
            transform: translateY(24px);
    -webkit-transform: translateY(var(--flix-spacing-sm));
            transform: translateY(var(--flix-spacing-sm));
    opacity: 0; }
  7.5% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; }
  92.5% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; }
  100% {
    -webkit-transform: translateY(24px);
            transform: translateY(24px);
    -webkit-transform: translateY(var(--flix-spacing-sm));
            transform: translateY(var(--flix-spacing-sm));
    opacity: 0; } }

.flix-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.15);
  z-index: 1100; }

.flix-pager {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  max-width: 100%;
  padding-top: 1px; }
  .flix-pager__item {
    display: block;
    min-width: 44px;
    height: 44px;
    margin-top: -1px;
    margin-left: -1px;
    border: 1px solid #c8c8c8;
    border: 1px solid var(--flix-input-border-color);
    color: #444;
    color: var(--flix-primary-content-color);
    font-size: 16px;
    font-size: var(--flix-font-size-primary);
    font-weight: 400;
    font-weight: var(--flix-font-weight-semibold);
    line-height: 42px;
    text-align: center;
    text-decoration: none; }
    .flix-pager__item:focus, .flix-pager__item:active {
      text-decoration: none; }
    .flix-pager__item:hover {
      background-color: #5cac00;
      background-color: var(--flix-primary-ui-dark-color);
      color: #fff; }
    @media (min-width: 1200px) {
      .flix-pager__item {
        min-width: 36px;
        height: 36px;
        line-height: 36px; } }
    .flix-pager__item:first-child {
      border-radius: 6px 0 0 6px;
      border-radius: var(--flix-primary-border-radius) 0 0 var(--flix-primary-border-radius); }
    .flix-pager__item:last-child {
      border-right: 1px solid #c8c8c8;
      border-right: 1px solid var(--flix-input-border-color);
      border-radius: 0 6px 6px 0;
      border-radius: 0 var(--flix-primary-border-radius) var(--flix-primary-border-radius) 0; }
    .flix-pager__item--active {
      background-color: #73d700;
      background-color: var(--flix-primary-ui-color);
      color: #fff; }
    .flix-pager__item--disabled {
      cursor: not-allowed;
      opacity: .5; }
      .flix-pager__item--disabled:hover {
        background-color: transparent;
        color: #444;
        color: var(--flix-primary-content-color); }
    .flix-pager__item--prev, .flix-pager__item--next {
      font-size: 0; }
      .flix-pager__item--prev::after, .flix-pager__item--prev.flix-pager__item--disabled:hover::after, .flix-pager__item--next::after, .flix-pager__item--next.flix-pager__item--disabled:hover::after {
        display: inline-block;
        width: 24px;
        width: var(--flix-spacing-sm);
        height: 24px;
        height: var(--flix-spacing-sm);
        content: '';
        vertical-align: middle;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M382 485.3L588.3 279.2A20.8 20.8 0 1 1 617.8 308.6L426.3 500 618 691.7A20.8 20.8 0 0 1 588.5 721.1L382.7 515.3C382.7 515.1 382.3 515.1 382.1 514.9A20.8 20.8 0 0 1 382 485.3Z' fill='rgba(139, 139, 139, 0.99)'/%3E%3C/svg%3E") no-repeat center;
        background: var(--flix-icon-arrow-left) no-repeat center; }
      .flix-pager__item--prev:hover::after, .flix-pager__item--next:hover::after {
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M382 485.3L588.3 279.2A20.8 20.8 0 1 1 617.8 308.6L426.3 500 618 691.7A20.8 20.8 0 0 1 588.5 721.1L382.7 515.3C382.7 515.1 382.3 515.1 382.1 514.9A20.8 20.8 0 0 1 382 485.3Z' fill='rgba(255, 255, 255, 0.99)'/%3E%3C/svg%3E") no-repeat center;
        background: var(--flix-icon-pager-arrow-left-active) no-repeat center; }
    .flix-pager__item--next::after, .flix-pager__item--next.flix-pager__item--disabled:hover::after {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }
    .flix-pager__item--next:hover::after {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }

.flix-panel {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  -webkit-transition: opacity 125ms, -webkit-transform 62.5ms 62.5ms;
  transition: opacity 125ms, -webkit-transform 62.5ms 62.5ms;
  transition: transform 62.5ms 62.5ms, opacity 125ms;
  transition: transform 62.5ms 62.5ms, opacity 125ms, -webkit-transform 62.5ms 62.5ms;
  opacity: 0;
  z-index: 1209; }
  .flix-panel--active {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transition: opacity 62.5ms 62.5ms, -webkit-transform 125ms;
    transition: opacity 62.5ms 62.5ms, -webkit-transform 125ms;
    transition: transform 125ms, opacity 62.5ms 62.5ms;
    transition: transform 125ms, opacity 62.5ms 62.5ms, -webkit-transform 125ms;
    opacity: 1; }
  .flix-panel__body {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 380px;
    max-width: 100%;
    -webkit-transform: translate(100%, 0);
            transform: translate(100%, 0);
    -webkit-transition: -webkit-transform 250ms ease;
    transition: -webkit-transform 250ms ease;
    transition: transform 250ms ease;
    transition: transform 250ms ease, -webkit-transform 250ms ease;
    background: #fff;
    background: var(--flix-box-bg-color);
    -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.12);
            box-shadow: 0 0 12px rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: var(--flix-primary-box-shadow);
            box-shadow: var(--flix-primary-box-shadow);
    overflow: hidden;
    z-index: 1209; }
    .flix-panel--left .flix-panel__body {
      right: auto;
      left: 0;
      -webkit-transform: translate(-100%, 0);
              transform: translate(-100%, 0); }
    .flix-panel--bottom .flix-panel__body {
      top: auto;
      width: 100%;
      max-height: 100vh;
      -webkit-transform: translate(0, 100%);
              transform: translate(0, 100%); }
    .flix-panel--full .flix-panel__body {
      width: 100%; }
    .flix-panel--full.flix-panel--bottom .flix-panel__body {
      height: 100vh; }
    .flix-panel--active .flix-panel__body {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
      -webkit-transition: -webkit-transform 250ms 250ms ease;
      transition: -webkit-transform 250ms 250ms ease;
      transition: transform 250ms 250ms ease;
      transition: transform 250ms 250ms ease, -webkit-transform 250ms 250ms ease; }
  .flix-panel__header, .flix-panel__content, .flix-panel__footer {
    width: 100%; }
  .flix-panel__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 60px;
    padding-right: 24px;
    padding-right: var(--flix-spacing-sm);
    padding-left: 24px;
    padding-left: var(--flix-spacing-sm);
    background: #73d700;
    background: var(--flix-primary-ui-color);
    z-index: 1000; }
  .flix-panel__title {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    font-weight: var(--flix-font-weight-semibold);
    line-height: 1.1;
    text-align: center; }
    .flix-panel__title:first-child {
      margin-left: calc(24px + 12px);
      margin-left: calc(var(--flix-spacing-sm) + var(--flix-spacing-xs)); }
    .flix-panel__title:last-child {
      margin-right: calc(24px + 12px);
      margin-right: calc(var(--flix-spacing-sm) + var(--flix-spacing-xs)); }
  .flix-panel__back, .flix-panel__close {
    margin: 0 12px 0 -2px;
    margin: 0 var(--flix-spacing-xs) 0 -2px;
    padding: 0;
    border: 0;
    background: none;
    color: #fff;
    font-size: 16px;
    font-size: var(--flix-font-size-primary);
    line-height: 60px;
    cursor: pointer;
    overflow: visible; }
  .flix-panel__close {
    margin: 0 -2px 0 12px;
    margin: 0 -2px 0 var(--flix-spacing-xs);
    display: inline-block;
    width: 24px;
    width: var(--flix-spacing-sm);
    height: 24px;
    height: var(--flix-spacing-sm);
    content: '';
    vertical-align: middle;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M827.2 797.8A20.8 20.8 0 0 1 797.8 827.2L500 529.5 202.3 827.2A20.8 20.8 0 0 1 172.8 797.8H172.8L470.5 500 172.8 202.3A20.8 20.8 0 0 1 202.3 172.8H202.3L500 470.5 797.8 172.7H797.8A20.8 20.8 0 0 1 827.2 202.2L529.5 500 827.2 797.8Z' fill='rgba(255, 255, 255, 0.99)'/%3E%3C/svg%3E") no-repeat center;
    background: var(--flix-icon-panel-close) no-repeat center;
    background-size: 20px; }
    .flix-panel__close .flix-icon {
      display: none; }
  .flix-panel__content {
    height: calc(100vh - 60px - 60px);
    max-height: 100%;
    padding: 12px;
    padding: var(--flix-spacing-xs);
    background: #fff;
    background: var(--flix-box-bg-color);
    overflow-y: auto; }
    .flix-panel--bottom .flix-panel__content {
      height: auto;
      max-height: calc(100vh - 60px - 60px); }
    .flix-panel--full.flix-panel--bottom .flix-panel__content {
      height: 100vh; }
    @media (min-width: 600px) {
      .flix-panel__content {
        padding: 24px;
        padding: var(--flix-spacing-sm); } }
  .flix-panel__footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    height: 60px;
    padding: 0 12px;
    padding: 0 var(--flix-spacing-xs);
    background: #fff;
    background: var(--flix-box-bg-color);
    text-align: center;
    -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.12);
            box-shadow: 0 0 12px rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: var(--flix-primary-box-shadow);
            box-shadow: var(--flix-primary-box-shadow); }
    @media (min-width: 600px) {
      .flix-panel__footer {
        padding: 0 24px;
        padding: 0 var(--flix-spacing-sm); } }
  .flix-panel__footer-section {
    margin: 0 calc(12px / 2);
    margin: 0 calc(var(--flix-spacing-xs) / 2); }
    @media (min-width: 0) {
      .flix-panel__footer-section {
        margin: 0 calc(12px / 2);
        margin: 0 calc(var(--flix-spacing-xs) / 2); } }
  .flix-panel__overlay {
    position: absolute; }

.flix-popup {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1100; }
  .flix-popup--active {
    display: block; }
  .flix-popup__body {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 330px;
    max-width: 100%;
    max-height: 100%;
    padding: 36px;
    padding: var(--flix-spacing-md);
    -webkit-transform: translateY(-50%) translateX(-50%);
            transform: translateY(-50%) translateX(-50%);
    border-radius: 6px;
    border-radius: var(--flix-primary-border-radius);
    background: #fff;
    background: var(--flix-box-bg-color);
    font-family: inherit;
    font-size: 16px;
    font-size: var(--flix-font-size-primary);
    font-weight: 400;
    font-weight: var(--flix-font-weight-normal);
    line-height: 1.5em;
    line-height: var(--flix-line-height-primary);
    text-align: center;
    z-index: 1101;
    overflow-y: auto; }
    @media (min-width: 600px) {
      .flix-popup__body {
        width: 600px;
        padding: 36px;
        padding: var(--flix-spacing-md); } }
  .flix-popup__icon {
    padding-bottom: 12px;
    padding-bottom: var(--flix-spacing-xs); }
  .flix-popup__title {
    color: #444;
    color: var(--flix-heading-font-color);
    font-size: 27px;
    font-size: var(--flix-font-size-h1);
    font-weight: 700;
    font-weight: var(--flix-font-weight-bold);
    line-height: 33px;
    line-height: var(--flix-line-height-h1);
    margin: 0;
    padding-bottom: 12px;
    padding-bottom: var(--flix-spacing-xs); }
  .flix-popup__content {
    color: #444;
    color: var(--flix-primary-content-color);
    font-size: 16px;
    font-size: var(--flix-font-size-primary);
    font-weight: 400;
    font-weight: var(--flix-font-weight-normal);
    line-height: 1.5em;
    line-height: var(--flix-line-height-primary);
    padding-bottom: 12px;
    padding-bottom: var(--flix-spacing-xs); }
  .flix-popup__actions {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    @media (min-width: 600px) {
      .flix-popup__actions .flix-btn + .flix-btn {
        margin-right: 12px;
        margin-right: var(--flix-spacing-xs);
        margin-left: 0; } }

.flix-progress-tracker {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 36px;
  padding-right: 18px;
  overflow: hidden;
  list-style-type: none; }
  .flix-progress-tracker__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    min-width: 60px;
    height: 36px;
    padding-right: 6px;
    padding-right: var(--flix-spacing-xxs);
    padding-left: calc(36px/2 + 6px);
    padding-left: calc(36px/2 + var(--flix-spacing-xxs));
    border: 1px solid #73d700;
    border: 1px solid var(--flix-primary-ui-color);
    border-right: 0;
    background: #fff;
    background: var(--flix-primary-bg-color);
    color: #73d700;
    color: var(--flix-primary-ui-color);
    font-weight: 400;
    font-weight: var(--flix-font-weight-semibold);
    line-height: 34px;
    text-decoration: none; }
    @media (min-width: 600px) {
      .flix-progress-tracker__item {
        -webkit-box-flex: 0;
            -ms-flex-positive: 0;
                flex-grow: 0;
        min-width: 145px; } }
    .flix-progress-tracker__item:first-child {
      border-radius: 6px 0 0 6px;
      border-radius: var(--flix-primary-border-radius) 0 0 var(--flix-primary-border-radius); }
    .flix-progress-tracker__item::before, .flix-progress-tracker__item::after {
      position: absolute;
      top: 50%;
      left: 100%;
      width: 0;
      height: 0;
      border: solid transparent;
      content: '';
      z-index: 1; }
    .flix-progress-tracker__item::after {
      margin-top: -17px;
      border-width: 17px;
      border-left-color: #fff;
      border-left-color: var(--flix-primary-bg-color); }
    .flix-progress-tracker__item::before {
      margin-top: -18px;
      border-width: 18px;
      border-left-color: #73d700;
      border-left-color: var(--flix-primary-ui-color); }
    .flix-progress-tracker__item--active {
      background: #73d700;
      background: var(--flix-primary-ui-color);
      color: #fff;
      color: var(--flix-primary-bg-color); }
      .flix-progress-tracker__item--active::after {
        border-left-color: #73d700;
        border-left-color: var(--flix-primary-ui-color); }
      .flix-progress-tracker__item--active::before {
        border-left-color: #fff;
        border-left-color: var(--flix-primary-bg-color); }
  .flix-progress-tracker__number {
    margin-right: .25em; }
    @media (min-width: 600px) {
      .flix-progress-tracker__number::after {
        content: '.'; } }
  .flix-progress-tracker__text {
    display: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }
    @media (min-width: 600px) {
      .flix-progress-tracker__text {
        display: block; } }

.flix-quantity__info {
  color: #444;
  color: var(--flix-primary-content-color);
  font-size: 14px;
  font-size: var(--flix-font-size-small);
  font-weight: 400;
  font-weight: var(--flix-font-weight-normal);
  line-height: 18px;
  line-height: var(--flix-line-height-small);
  display: block;
  padding-top: 3px;
  padding-top: var(--flix-spacing-xxxs); }

.flix-quantity--error .flix-quantity__info {
  color: #c64300;
  color: var(--flix-input-error-info-color); }

.flix-quantity__info ~ .flix-quantity__info {
  color: #444;
  color: var(--flix-primary-content-color); }

.flix-quantity__label {
  display: block;
  margin-bottom: 12px;
  margin-bottom: var(--flix-spacing-xs);
  color: #444;
  color: var(--flix-primary-content-color); }
  @media (min-width: 1200px) {
    .flix-quantity__label {
      margin-bottom: 2px;
      color: #757575;
      color: var(--flix-secondary-content-color); } }

.flix-quantity--active .flix-quantity__input {
  border: 1px solid #757575;
  border: 1px solid var(--flix-input-active-border-color); }

.flix-quantity--disabled {
  opacity: 0.5;
  opacity: var(--flix-disabled-element-opacity); }
  .flix-quantity--disabled .flix-quantity__input,
  .flix-quantity--disabled .flix-quantity__button {
    cursor: not-allowed; }

.flix-quantity--error .flix-quantity__input {
  border: 1px solid #ff5700;
  border: 1px solid var(--flix-input-error-border-color); }

.flix-quantity--inline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }

.flix-quantity--inline .flix-quantity__label {
  -ms-flex-item-align: center;
      align-self: center;
  margin-bottom: 0;
  color: #444;
  color: var(--flix-primary-content-color);
  font-size: 16px;
  font-size: var(--flix-font-size-primary);
  font-weight: 700;
  font-weight: var(--flix-font-weight-bold);
  line-height: 22px; }
  .flix-quantity--inline .flix-quantity__label small {
    display: block;
    font-size: 14px;
    font-size: var(--flix-font-size-small);
    font-weight: 400;
    font-weight: var(--flix-font-weight-normal);
    line-height: 17px; }

.flix-quantity--inline .flix-quantity__info {
  width: 100%;
  text-align: right; }

.flix-quantity__picker {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch; }

.flix-quantity__input {
  -ms-flex-item-align: center;
      align-self: center;
  position: relative;
  width: 100%;
  height: 44px;
  height: var(--flix-input-height-mobile);
  padding: 0 12px;
  padding: 0 var(--flix-spacing-xs);
  border: 1px solid #c8c8c8;
  border: 1px solid var(--flix-input-border-color);
  border-radius: 6px;
  border-radius: var(--flix-input-border-radius);
  background-color: #fff;
  background-color: var(--flix-input-bg-color);
  color: #444;
  color: var(--flix-primary-content-color);
  font-family: inherit;
  font-size: 16px;
  font-size: var(--flix-font-size-primary);
  font-weight: 400;
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-mobile);
  text-overflow: ellipsis;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  width: 60px;
  border-radius: 0;
  text-align: center;
  -moz-appearance: textfield; }
  @media (min-width: 1200px) {
    .flix-quantity__input {
      height: 36px;
      height: var(--flix-input-height-desktop);
      line-height: var(--flix-line-height-desktop); } }
  .flix-quantity__input::-webkit-input-placeholder {
    color: #757575;
    color: var(--flix-input-placeholder-color);
    font-style: italic; }
  .flix-quantity__input::-moz-placeholder {
    color: #757575;
    color: var(--flix-input-placeholder-color);
    font-style: italic; }
  .flix-quantity__input:-ms-input-placeholder {
    color: #757575;
    color: var(--flix-input-placeholder-color);
    font-style: italic; }
  .flix-quantity__input::-ms-input-placeholder {
    color: #757575;
    color: var(--flix-input-placeholder-color);
    font-style: italic; }
  .flix-quantity__input::placeholder {
    color: #757575;
    color: var(--flix-input-placeholder-color);
    font-style: italic; }
  .flix-quantity__input::-webkit-inner-spin-button, .flix-quantity__input::-webkit-outer-spin-button {
    margin: 0;
    -webkit-appearance: none; }
  .flix-quantity__input:focus {
    border: 1px solid #757575;
    border: 1px solid var(--flix-input-active-border-color); }
  .flix-quantity__input[readonly] {
    border: 0;
    font-weight: 700;
    font-weight: var(--flix-font-weight-bold); }
    .flix-quantity__input[readonly]:focus {
      outline: none; }

.flix-quantity .flix-quantity__button {
  padding: 0;
  font-size: 0;
  white-space: normal;
  cursor: pointer;
  z-index: 1; }
  .flix-quantity .flix-quantity__button:after {
    display: inline-block;
    width: 24px;
    width: var(--flix-spacing-sm);
    height: 24px;
    height: var(--flix-spacing-sm);
    content: '';
    vertical-align: middle; }
  .flix-quantity .flix-quantity__button:first-of-type, .flix-quantity .flix-quantity__button--minus {
    border-radius: 6px 0 0 6px;
    border-radius: var(--flix-primary-border-radius) 0 0 var(--flix-primary-border-radius); }
    .flix-quantity .flix-quantity__button:first-of-type:after, .flix-quantity .flix-quantity__button--minus:after {
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M916.7 500A20.8 20.8 0 0 1 895.8 520.8H104.2A20.8 20.8 0 0 1 104.2 479.2H895.8A20.8 20.8 0 0 1 916.7 500Z' fill='rgba(139, 139, 139, 0.99)'/%3E%3C/svg%3E") no-repeat center;
      background: var(--flix-icon-minus) no-repeat center; }
  .flix-quantity .flix-quantity__button:last-of-type, .flix-quantity .flix-quantity__button--plus {
    border-radius: 0 6px 6px 0;
    border-radius: 0 var(--flix-primary-border-radius) var(--flix-primary-border-radius) 0; }
    .flix-quantity .flix-quantity__button:last-of-type:after, .flix-quantity .flix-quantity__button--plus:after {
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M916.7 500A20.8 20.8 0 0 1 895.8 520.8H520.8V895.8A20.8 20.8 0 0 1 479.2 895.8V520.8H104.2A20.8 20.8 0 0 1 104.2 479.2H479.2V104.2A20.8 20.8 0 0 1 520.8 104.2V479.2H895.8A20.8 20.8 0 0 1 916.7 500Z' fill='rgba(139, 139, 139, 0.99)'/%3E%3C/svg%3E") no-repeat center;
      background: var(--flix-icon-plus) no-repeat center; }

.flix-radio {
  position: relative; }
  .flix-radio--sm .flix-radio__label {
    min-height: 18px; }
    .flix-radio--sm .flix-radio__label::before {
      width: 18px;
      height: 18px; }
  .flix-radio--sm .flix-radio__input:checked + .flix-radio__label::before {
    background-size: 10px; }
  .flix-radio__label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    min-height: 30px;
    color: #444;
    color: var(--flix-primary-content-color);
    font-family: inherit;
    cursor: pointer; }
    .flix-radio__label::before {
      display: block;
      -ms-flex-negative: 0;
          flex-shrink: 0;
      -ms-flex-item-align: baseline;
          align-self: baseline;
      width: 30px;
      height: 30px;
      margin-right: 12px;
      margin-right: var(--flix-spacing-xs);
      -webkit-transition: ease-in-out 200ms background-size;
      transition: ease-in-out 200ms background-size;
      border: 2px solid #c8c8c8;
      border: 2px solid var(--flix-input-border-color);
      border-radius: 100%;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3Z' fill='rgba(115, 215, 0, 0.99)'/%3E%3C/svg%3E") no-repeat center;
      background: var(--flix-icon-radio-checkmark) no-repeat center;
      background-size: 0;
      content: ''; }
  .flix-radio__input:checked + .flix-radio__label::before {
    background-size: 20px; }
  .flix-radio__input {
    position: absolute;
    opacity: 0; }
  .flix-radio__input:focus + .flix-radio__label::before {
    outline: 1px solid transparent;
    -webkit-box-shadow: 0 0 3px #00b5e2;
            box-shadow: 0 0 3px #00b5e2;
    -webkit-box-shadow: 0 0 3px var(--flix-neutral-color);
            box-shadow: 0 0 3px var(--flix-neutral-color); }
  .flix-radio__input:checked + .flix-radio__label::before {
    border-color: #73d700;
    border-color: var(--flix-primary-ui-color); }
  .flix-radio__info {
    color: #444;
    color: var(--flix-primary-content-color);
    font-size: 14px;
    font-size: var(--flix-font-size-small);
    font-weight: 400;
    font-weight: var(--flix-font-weight-normal);
    line-height: 18px;
    line-height: var(--flix-line-height-small);
    display: block;
    padding-top: 3px;
    padding-top: var(--flix-spacing-xxxs); }
  .flix-radio--disabled .flix-radio__label {
    cursor: not-allowed;
    opacity: 0.5;
    opacity: var(--flix-disabled-element-opacity); }
  .flix-radio--error .flix-radio__label:before {
    border-color: #ff5700;
    border-color: var(--flix-danger-color); }
  .flix-radio--error .flix-radio__info {
    color: #c64300;
    color: var(--flix-input-error-info-color); }

.flix-radio {
  position: relative; }
  .flix-radio--sm .flix-radio__label {
    min-height: 18px; }
    .flix-radio--sm .flix-radio__label::before {
      width: 18px;
      height: 18px; }
  .flix-radio--sm .flix-radio__input:checked + .flix-radio__label::before {
    background-size: 10px; }
  .flix-radio__label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    min-height: 30px;
    color: #444;
    color: var(--flix-primary-content-color);
    font-family: inherit;
    cursor: pointer; }
    .flix-radio__label::before {
      display: block;
      -ms-flex-negative: 0;
          flex-shrink: 0;
      -ms-flex-item-align: baseline;
          align-self: baseline;
      width: 30px;
      height: 30px;
      margin-right: 12px;
      margin-right: var(--flix-spacing-xs);
      -webkit-transition: ease-in-out 200ms background-size;
      transition: ease-in-out 200ms background-size;
      border: 2px solid #c8c8c8;
      border: 2px solid var(--flix-input-border-color);
      border-radius: 100%;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3Z' fill='rgba(115, 215, 0, 0.99)'/%3E%3C/svg%3E") no-repeat center;
      background: var(--flix-icon-radio-checkmark) no-repeat center;
      background-size: 0;
      content: ''; }
  .flix-radio__input:checked + .flix-radio__label::before {
    background-size: 20px; }
  .flix-radio__input {
    position: absolute;
    opacity: 0; }
  .flix-radio__input:focus + .flix-radio__label::before {
    outline: 1px solid transparent;
    -webkit-box-shadow: 0 0 3px #00b5e2;
            box-shadow: 0 0 3px #00b5e2;
    -webkit-box-shadow: 0 0 3px var(--flix-neutral-color);
            box-shadow: 0 0 3px var(--flix-neutral-color); }
  .flix-radio__input:checked + .flix-radio__label::before {
    border-color: #73d700;
    border-color: var(--flix-primary-ui-color); }
  .flix-radio__info {
    color: #444;
    color: var(--flix-primary-content-color);
    font-size: 14px;
    font-size: var(--flix-font-size-small);
    font-weight: 400;
    font-weight: var(--flix-font-weight-normal);
    line-height: 18px;
    line-height: var(--flix-line-height-small);
    display: block;
    padding-top: 3px;
    padding-top: var(--flix-spacing-xxxs); }
  .flix-radio--disabled .flix-radio__label {
    cursor: not-allowed;
    opacity: 0.5;
    opacity: var(--flix-disabled-element-opacity); }
  .flix-radio--error .flix-radio__label:before {
    border-color: #ff5700;
    border-color: var(--flix-danger-color); }
  .flix-radio--error .flix-radio__info {
    color: #c64300;
    color: var(--flix-input-error-info-color); }

.flix-radio-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: 0 0 24px;
  margin: 0 0 var(--flix-spacing-sm);
  border: 0; }
  .flix-radio-wrapper__item {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    border: solid #e1e1e1;
    border: solid var(--flix-primary-line-color); }
  .flix-radio-wrapper--error .flix-radio-wrapper__item {
    border-color: #ff5700;
    border-color: var(--flix-danger-color); }
  .flix-radio-wrapper--error .flix-radio-wrapper__info:first-of-type {
    color: #c64300;
    color: var(--flix-input-error-info-color); }
  .flix-radio-wrapper .flix-radio {
    height: 100%; }
    .flix-radio-wrapper .flix-radio__label {
      display: block;
      width: 100%;
      height: 100%;
      min-height: calc(30px + 12px * 2);
      min-height: calc(30px + var(--flix-spacing-xs) * 2);
      padding: 12px;
      padding: var(--flix-spacing-xs);
      padding-left: calc(30px + 12px * 2);
      padding-left: calc(30px + var(--flix-spacing-xs) * 2); }
      .flix-radio-wrapper .flix-radio__label:before {
        margin-left: calc(-1 * 30px - 12px);
        margin-left: calc(-1 * 30px - var(--flix-spacing-xs));
        float: left; }
  .flix-radio-wrapper__info {
    color: #444;
    color: var(--flix-primary-content-color);
    font-size: 14px;
    font-size: var(--flix-font-size-small);
    font-weight: 400;
    font-weight: var(--flix-font-weight-normal);
    line-height: 18px;
    line-height: var(--flix-line-height-small);
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    -ms-flex-negative: 0;
        flex-shrink: 0; }
  .flix-radio-wrapper:not(.flix-radio-wrapper--column) .flix-radio-wrapper__item {
    margin-bottom: 3px;
    margin-bottom: var(--flix-spacing-xxxs);
    border-width: 1px 1px 1px 0; }
    .flix-radio-wrapper:not(.flix-radio-wrapper--column) .flix-radio-wrapper__item:first-of-type {
      border-left-width: 1px;
      border-radius: 6px 0 0 6px;
      border-radius: var(--flix-primary-border-radius) 0 0 var(--flix-primary-border-radius);
      border-right-color: #e1e1e1;
      border-right-color: var(--flix-primary-line-color); }
    .flix-radio-wrapper:not(.flix-radio-wrapper--column) .flix-radio-wrapper__item:last-of-type {
      border-radius: 0 6px 6px 0;
      border-radius: 0 var(--flix-primary-border-radius) var(--flix-primary-border-radius) 0;
      border-left-color: #e1e1e1;
      border-left-color: var(--flix-primary-line-color); }
    .flix-radio-wrapper:not(.flix-radio-wrapper--column) .flix-radio-wrapper__item:not(:first-of-type):not(:last-of-type) {
      border-right-color: #e1e1e1;
      border-right-color: var(--flix-primary-line-color);
      border-left-color: #e1e1e1;
      border-left-color: var(--flix-primary-line-color); }
  .flix-radio-wrapper--column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
  .flix-radio-wrapper--column .flix-radio-wrapper__item {
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
    border-width: 0 1px 1px; }
    .flix-radio-wrapper--column .flix-radio-wrapper__item:first-of-type {
      border-top-width: 1px;
      border-radius: 6px 6px 0 0;
      border-radius: var(--flix-primary-border-radius) var(--flix-primary-border-radius) 0 0;
      border-bottom-color: #e1e1e1;
      border-bottom-color: var(--flix-primary-line-color); }
    .flix-radio-wrapper--column .flix-radio-wrapper__item:last-of-type {
      margin-bottom: 3px;
      margin-bottom: var(--flix-spacing-xxxs);
      border-radius: 0 0 6px 6px;
      border-radius: 0 0 var(--flix-primary-border-radius) var(--flix-primary-border-radius);
      border-top-color: #e1e1e1;
      border-top-color: var(--flix-primary-line-color); }
    .flix-radio-wrapper--column .flix-radio-wrapper__item:not(:first-of-type):not(:last-of-type) {
      border-top-color: #e1e1e1;
      border-top-color: var(--flix-primary-line-color);
      border-bottom-color: #e1e1e1;
      border-bottom-color: var(--flix-primary-line-color); }
    .flix-radio-wrapper--column .flix-radio-wrapper__item:not(:first-of-type) {
      margin-top: 0; }

.flix-range {
  position: relative;
  font-size: 0; }
  .flix-range__info {
    color: #444;
    color: var(--flix-primary-content-color);
    font-size: 14px;
    font-size: var(--flix-font-size-small);
    font-weight: 400;
    font-weight: var(--flix-font-weight-normal);
    line-height: 18px;
    line-height: var(--flix-line-height-small);
    display: block;
    padding-top: 3px;
    padding-top: var(--flix-spacing-xxxs); }
  .flix-range--error .flix-range__info {
    color: #c64300;
    color: var(--flix-input-error-info-color); }
  .flix-range__info ~ .flix-range__info {
    color: #444;
    color: var(--flix-primary-content-color); }
  .flix-range__field {
    position: relative;
    height: 30px;
    overflow: visible; }
    .flix-range__field::before {
      display: block;
      position: absolute;
      top: 50%;
      width: 100%;
      height: 2px;
      margin-top: -1px;
      border-radius: 5px;
      background: #ffad00;
      background: var(--flix-strong-highlight-color);
      content: ''; }
  .flix-range [type='range'] {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30px;
    margin: 0;
    padding: 0;
    outline: none;
    background: transparent;
    overflow: visible;
    z-index: 100;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none; }
    .flix-range [type='range']::-webkit-slider-runnable-track {
      border: 0;
      background: transparent;
      border: 0px solid #ffad00;
      border: 0px solid var(--flix-strong-highlight-color); }
    .flix-range [type='range']::-webkit-slider-thumb {
      width: 30px;
      height: 30px;
      border: 2px solid #e1e1e1;
      border: 2px solid var(--flix-primary-line-color);
      border-radius: 100%;
      background: #fff;
      cursor: pointer;
      position: relative;
      outline: 0;
      z-index: 1;
      -webkit-appearance: none;
      pointer-events: all; }
    .flix-range [type='range']::-moz-range-track {
      border: 0;
      background: transparent; }
    .flix-range [type='range']::-moz-range-thumb {
      width: 30px;
      height: 30px;
      border: 2px solid #e1e1e1;
      border: 2px solid var(--flix-primary-line-color);
      border-radius: 100%;
      background: #fff;
      cursor: pointer;
      pointer-events: all; }
    .flix-range [type='range']::-moz-focus-outer {
      border: 0; }
    .flix-range [type='range']::-ms-track {
      border: 0;
      background: transparent;
      color: transparent; }
    .flix-range [type='range']::-ms-fill-lower {
      border: 0;
      background: transparent; }
    .flix-range [type='range']::-ms-fill-upper {
      border: 0;
      background: transparent; }
    .flix-range [type='range']::-ms-thumb {
      width: 30px;
      height: 30px;
      border: 2px solid #e1e1e1;
      border: 2px solid var(--flix-primary-line-color);
      border-radius: 100%;
      background: #fff;
      cursor: pointer;
      margin-top: 0; }
    .flix-range [type='range']:focus::-webkit-slider-thumb {
      -webkit-box-shadow: 0 0 0 2px #ffad00;
              box-shadow: 0 0 0 2px #ffad00;
      -webkit-box-shadow: 0 0 0 2px var(--flix-strong-highlight-color);
              box-shadow: 0 0 0 2px var(--flix-strong-highlight-color); }
    .flix-range [type='range']:focus::-moz-range-thumb {
      box-shadow: 0 0 0 2px #ffad00;
      box-shadow: 0 0 0 2px var(--flix-strong-highlight-color); }
    .flix-range [type='range']:focus::-ms-thumb {
      box-shadow: 0 0 0 2px #ffad00;
      box-shadow: 0 0 0 2px var(--flix-strong-highlight-color); }
  .flix-range__value {
    display: inline-block;
    width: 50%;
    margin-bottom: 4px;
    color: #444;
    color: var(--flix-primary-content-color);
    font-size: 14px;
    font-size: var(--flix-font-size-small);
    word-break: break-all; }
    .flix-range__value--max {
      text-align: right; }
  .flix-range__track-range {
    position: absolute;
    bottom: 14px;
    height: 2px;
    margin-top: -1px;
    background: #ffad00;
    background: var(--flix-strong-highlight-color);
    z-index: 0; }
  .flix-range__label {
    display: block;
    margin-bottom: 6px;
    margin-bottom: var(--flix-spacing-xxs);
    color: #444;
    color: var(--flix-primary-content-color);
    font-size: 16px;
    font-size: var(--flix-font-size-primary);
    word-break: break-all; }
    @media (min-width: 1200px) {
      .flix-range__label {
        color: #757575;
        color: var(--flix-secondary-content-color); } }
  .flix-range__label--hidden {
    display: none; }
  .flix-range [type='range']:focus {
    outline: 0; }
  .flix-range--disabled {
    opacity: 0.5;
    opacity: var(--flix-disabled-element-opacity); }
  .flix-range--disabled .flix-range__field::before {
    background: #e1e1e1;
    background: var(--flix-primary-line-color); }
  .flix-range--disabled .flix-range__track-range {
    background: #e1e1e1;
    background: var(--flix-primary-line-color);
    opacity: 0; }
  .flix-range--disabled input::-moz-range-thumb,
  .flix-range input:disabled::-moz-range-thumb {
    cursor: not-allowed; }
  .flix-range--disabled input::-ms-thumb,
  .flix-range input:disabled::-ms-thumb {
    cursor: not-allowed; }
  .flix-range--disabled input::-webkit-slider-thumb,
  .flix-range input:disabled::-webkit-slider-thumb {
    cursor: not-allowed; }
  .flix-range--double {
    pointer-events: none; }
  .flix-range--double .flix-range__field::before {
    background: #e1e1e1;
    background: var(--flix-primary-line-color); }

.flix-select {
  position: relative; }
  .flix-select--error .flix-select__field,
  .flix-select--valid .flix-select__field {
    background-repeat: no-repeat;
    background-position: calc(100% - (25px + 24px)) center;
    background-position: calc(100% - (25px + var(--flix-spacing-sm))) center;
    background-size: 24px 24px;
    background-size: var(--flix-spacing-sm) var(--flix-spacing-sm); }
  .flix-select--error .flix-select__field {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM479.2 270.8A20.8 20.8 0 0 1 520.8 270.8V562.5A20.8 20.8 0 0 1 479.2 562.5ZM500 750A41.7 41.7 0 1 1 541.7 708.3 41.7 41.7 0 0 1 500 750Z' fill='rgba(255, 87, 0, 0.99)'/%3E%3C/svg%3E");
    background-image: var(--flix-icon-attention-solid); }
  .flix-select--valid .flix-select__field {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM723.1 368.9L431.4 660.6A21.1 21.1 0 0 1 424.6 665 20.1 20.1 0 0 1 417 666.6L416.7 666.7 416.3 666.7A20 20 0 0 1 408.7 665.1 20.8 20.8 0 0 1 401.9 660.7L276.9 535.7A20.8 20.8 0 0 1 306.4 506.2H306.4L416.7 616.4 693.6 339.4H693.6A20.8 20.8 0 0 1 723.1 368.9Z' fill='rgba(115, 215, 0, 0.99)'/%3E%3C/svg%3E");
    background-image: var(--flix-icon-checkmark-solid); }
  .flix-select--disabled {
    opacity: 0.5;
    opacity: var(--flix-disabled-element-opacity); }
  .flix-select::after {
    display: inline-block;
    width: 24px;
    width: var(--flix-spacing-sm);
    height: 24px;
    height: var(--flix-spacing-sm);
    content: '';
    vertical-align: middle;
    position: absolute;
    top: 10px;
    right: 12px;
    right: var(--flix-spacing-xs);
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M485.3 618L279.2 411.7A20.8 20.8 0 1 1 308.3 382.2L500 573.7 691.7 382A20.8 20.8 0 0 1 721.1 411.5L515.3 617.3C515.1 617.3 515.1 617.7 514.9 617.9A20.8 20.8 0 0 1 485.3 618Z' fill='rgba(139, 139, 139, 0.99)'/%3E%3C/svg%3E") no-repeat center;
    background: var(--flix-icon-arrow-down) no-repeat center;
    pointer-events: none; }
    @media (min-width: 1200px) {
      .flix-select::after {
        top: 30px; } }
  .flix-select--valid .flix-select__field {
    padding-right: 80px; }
  .flix-select--error .flix-select__field {
    padding-right: 80px;
    border: 1px solid #ff5700;
    border: 1px solid var(--flix-input-error-border-color); }
  .flix-select--active .flix-select__field {
    border: 1px solid #757575;
    border: 1px solid var(--flix-input-active-border-color);
    outline: none; }
  .flix-select--disabled .flix-select__field {
    cursor: not-allowed; }
  .flix-select__info {
    color: #444;
    color: var(--flix-primary-content-color);
    font-size: 14px;
    font-size: var(--flix-font-size-small);
    font-weight: 400;
    font-weight: var(--flix-font-weight-normal);
    line-height: 18px;
    line-height: var(--flix-line-height-small);
    display: block;
    padding-top: 3px;
    padding-top: var(--flix-spacing-xxxs); }
  .flix-select--error .flix-select__info {
    color: #c64300;
    color: var(--flix-input-error-info-color); }
  .flix-select__info ~ .flix-select__info {
    color: #444;
    color: var(--flix-primary-content-color); }
  @media (min-width: 1200px) {
    .flix-select--no-label::after {
      top: calc(10px - (44px - 36px)/2);
      top: calc(10px - (var(--flix-input-height-mobile) - var(--flix-input-height-desktop))/2); } }
  .flix-select__field {
    position: relative;
    width: 100%;
    height: 44px;
    height: var(--flix-input-height-mobile);
    padding: 0 12px;
    padding: 0 var(--flix-spacing-xs);
    border: 1px solid #c8c8c8;
    border: 1px solid var(--flix-input-border-color);
    border-radius: 6px;
    border-radius: var(--flix-input-border-radius);
    background-color: #fff;
    background-color: var(--flix-input-bg-color);
    color: #444;
    color: var(--flix-primary-content-color);
    font-family: inherit;
    font-size: 16px;
    font-size: var(--flix-font-size-primary);
    font-weight: 400;
    font-weight: var(--flix-font-weight-normal);
    line-height: var(--flix-line-height-mobile);
    text-overflow: ellipsis;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    padding-right: 6px;
    padding-right: var(--flix-spacing-xxs);
    background: transparent; }
    @media (min-width: 1200px) {
      .flix-select__field {
        height: 36px;
        height: var(--flix-input-height-desktop);
        line-height: var(--flix-line-height-desktop); } }
    .flix-select__field::-webkit-input-placeholder {
      color: #757575;
      color: var(--flix-input-placeholder-color);
      font-style: italic; }
    .flix-select__field::-moz-placeholder {
      color: #757575;
      color: var(--flix-input-placeholder-color);
      font-style: italic; }
    .flix-select__field:-ms-input-placeholder {
      color: #757575;
      color: var(--flix-input-placeholder-color);
      font-style: italic; }
    .flix-select__field::-ms-input-placeholder {
      color: #757575;
      color: var(--flix-input-placeholder-color);
      font-style: italic; }
    .flix-select__field::placeholder {
      color: #757575;
      color: var(--flix-input-placeholder-color);
      font-style: italic; }
    .flix-select__field:hover, .flix-select__field:active, .flix-select__field:focus {
      outline: 0; }
    .flix-select__field::-ms-expand {
      display: none; }
    .flix-select__field:focus {
      border: 1px solid #757575;
      border: 1px solid var(--flix-input-active-border-color);
      outline: none; }
    .flix-select__field[disabled] {
      cursor: not-allowed; }
    .flix-select__field option,
    .flix-select__field optgroup {
      background: #fff;
      background: var(--flix-box-bg-color);
      color: #444;
      color: var(--flix-primary-content-color); }

.flix-select-group {
  position: relative;
  border: 0; }
  .flix-select-group__info {
    color: #444;
    color: var(--flix-primary-content-color);
    font-size: 14px;
    font-size: var(--flix-font-size-small);
    font-weight: 400;
    font-weight: var(--flix-font-weight-normal);
    line-height: 18px;
    line-height: var(--flix-line-height-small);
    display: block;
    padding-top: 3px;
    padding-top: var(--flix-spacing-xxxs); }
  .flix-select-group--error .flix-select-group__info {
    color: #c64300;
    color: var(--flix-input-error-info-color); }
  .flix-select-group__info ~ .flix-select-group__info {
    color: #444;
    color: var(--flix-primary-content-color); }
  .flix-select-group--error .flix-select-group__item {
    border-color: #ff5700;
    border-color: var(--flix-danger-color); }
  .flix-select-group--disabled {
    opacity: 0.5;
    opacity: var(--flix-disabled-element-opacity); }
    .flix-select-group--disabled .flix-select-group__item label,
    .flix-select-group--disabled .flix-select-group__item input {
      cursor: not-allowed; }
  .flix-select-group__items {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    position: relative;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    padding-top: 1px;
    list-style-type: none; }
  .flix-select-group__item {
    min-width: 44px;
    min-width: var(--flix-input-height-mobile);
    height: 44px;
    height: var(--flix-input-height-mobile);
    margin-top: -1px;
    margin-left: -1px;
    border: 1px solid #c8c8c8;
    border: 1px solid var(--flix-input-border-color);
    line-height: 44px;
    line-height: var(--flix-input-height-mobile);
    text-align: center; }
    @media (min-width: 1200px) {
      .flix-select-group__item {
        min-width: 36px;
        min-width: var(--flix-input-height-desktop);
        height: 36px;
        height: var(--flix-input-height-desktop);
        line-height: 36px;
        line-height: var(--flix-input-height-desktop); } }
    .flix-select-group__item:first-of-type,
    .flix-select-group__item:first-of-type label {
      border-radius: 6px 0 0 6px;
      border-radius: var(--flix-primary-border-radius) 0 0 var(--flix-primary-border-radius); }
    .flix-select-group__item:last-of-type,
    .flix-select-group__item:last-of-type label {
      border-radius: 0 6px 6px 0;
      border-radius: 0 var(--flix-primary-border-radius) var(--flix-primary-border-radius) 0; }
    .flix-select-group__item input {
      position: absolute;
      cursor: pointer;
      opacity: 0; }
    .flix-select-group__item label {
      display: block;
      height: 100%;
      padding: 0 18px;
      padding: 0 var(--flix-spacing-smxs);
      background: #fff;
      background: var(--flix-primary-bg-color);
      font-weight: 400;
      font-weight: var(--flix-font-weight-semibold);
      cursor: pointer; }
      .flix-select-group__item label:hover {
        background: #f7f7f7;
        background: var(--flix-secondary-bg-color); }
    .flix-select-group__item input:focus + label {
      outline: 1px solid transparent;
      -webkit-box-shadow: 0 0 3px #00b5e2;
              box-shadow: 0 0 3px #00b5e2;
      -webkit-box-shadow: 0 0 3px var(--flix-neutral-color);
              box-shadow: 0 0 3px var(--flix-neutral-color); }
    .flix-select-group__item input:checked + label {
      padding: 0 12px;
      padding: 0 var(--flix-spacing-xs);
      border-bottom: 3px solid #73d700;
      border-bottom: var(--flix-spacing-xxxs) solid var(--flix-primary-ui-color);
      color: #73d700;
      color: var(--flix-primary-ui-color); }
      .flix-select-group__item input:checked + label::before {
        display: inline-block;
        width: 12px;
        width: var(--flix-spacing-xs);
        height: 12px;
        height: var(--flix-spacing-xs);
        margin-right: .2em;
        margin-left: -.2em;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M21,4.5a.988.988,0,0,0-.707.293L8,17.086,3.708,12.794A.989.989,0,0,0,3,12.5a1,1,0,0,0-.707,1.707l5,5a1,1,0,0,0,1.414,0l13-13A1,1,0,0,0,21,4.5Zm0,1h0Z' style='fill:%2373d700'/%3E%3C/svg%3E") no-repeat center;
        background: var(--flix-icon-checkmark) no-repeat center;
        content: ''; }
    .flix-select-group__item--disabled {
      opacity: 0.5;
      opacity: var(--flix-disabled-element-opacity); }
      .flix-select-group__item--disabled label,
      .flix-select-group__item--disabled input {
        cursor: not-allowed; }

.flix-skeleton {
  display: block;
  width: 100%;
  height: 15px;
  margin-bottom: 7px;
  border-radius: 6px;
  border-radius: var(--flix-primary-border-radius);
  background: #e1e1e1;
  background: var(--flix-grayscale-30-color);
  content: '';
  -webkit-animation: skeleton-blink 1.6s infinite linear;
          animation: skeleton-blink 1.6s infinite linear; }
  .flix-skeleton--w-xs {
    width: 25%; }
  .flix-skeleton--w-sm {
    width: 33%; }
  .flix-skeleton--w-md {
    width: 50%; }
  .flix-skeleton--w-lg {
    width: 75%; }
  .flix-skeleton--h-xs {
    height: 1px;
    margin-bottom: 12px;
    margin-bottom: var(--flix-spacing-xs); }
  .flix-skeleton--h-md {
    height: 26px;
    margin-bottom: 12px;
    margin-bottom: var(--flix-spacing-xs); }
  .flix-skeleton--h-lg {
    height: 44px;
    margin-bottom: 12px;
    margin-bottom: var(--flix-spacing-xs); }
    @media (min-width: 1200px) {
      .flix-skeleton--h-lg {
        height: 36px; } }
  .flix-skeleton--flush-bottom {
    margin-bottom: 0; }

@-webkit-keyframes skeleton-blink {
  0% {
    opacity: 1; }
  50% {
    opacity: .5; }
  100% {
    opacity: 1; } }

@keyframes skeleton-blink {
  0% {
    opacity: 1; }
  50% {
    opacity: .5; }
  100% {
    opacity: 1; } }

.flix-spinner {
  display: inline-block;
  width: 90px;
  height: 90px;
  border: 4px solid #a6ec55;
  border: 4px solid var(--flix-primary-ui-light-color);
  border-radius: 50%;
  border-left-color: #73d700;
  border-left-color: var(--flix-primary-ui-color);
  -webkit-animation: spinner-rotate 1s infinite linear;
          animation: spinner-rotate 1s infinite linear; }
  .flix-spinner--sm, .flix-spinner--md, .flix-spinner--lg, .flix-spinner--xl, .flix-spinner--xxl {
    border-left-color: #5cac00;
    border-left-color: var(--flix-primary-ui-dark-color); }
  .flix-spinner--sm {
    width: 24px;
    width: var(--flix-spacing-sm);
    height: 24px;
    height: var(--flix-spacing-sm); }
  .flix-spinner--md {
    width: 36px;
    width: var(--flix-spacing-md);
    height: 36px;
    height: var(--flix-spacing-md); }
  .flix-spinner--lg {
    width: 48px;
    width: var(--flix-spacing-lg);
    height: 48px;
    height: var(--flix-spacing-lg); }
  .flix-spinner--xl {
    width: calc(48px + 24px);
    width: calc(var(--flix-spacing-lg) + var(--flix-spacing-sm));
    height: calc(48px + 24px);
    height: calc(var(--flix-spacing-lg) + var(--flix-spacing-sm)); }
  .flix-spinner--xxl {
    width: calc(48px + 24px * 2);
    width: calc(var(--flix-spacing-lg) + var(--flix-spacing-sm) * 2);
    height: calc(48px + 24px * 2);
    height: calc(var(--flix-spacing-lg) + var(--flix-spacing-sm) * 2); }

@-webkit-keyframes spinner-rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spinner-rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.flix-switch {
  display: block;
  position: relative;
  line-height: 30px; }
  @media (min-width: 600px) {
    .flix-switch {
      display: inline-block; } }
  .flix-switch__info {
    color: #444;
    color: var(--flix-primary-content-color);
    font-size: 14px;
    font-size: var(--flix-font-size-small);
    font-weight: 400;
    font-weight: var(--flix-font-weight-normal);
    line-height: 18px;
    line-height: var(--flix-line-height-small);
    display: block;
    padding-top: 3px;
    padding-top: var(--flix-spacing-xxxs); }
  .flix-switch--error .flix-switch__info {
    color: #c64300;
    color: var(--flix-input-error-info-color); }
  .flix-switch__info ~ .flix-switch__info {
    color: #444;
    color: var(--flix-primary-content-color); }
  .flix-switch__input {
    position: absolute;
    opacity: 0; }
  .flix-switch__label {
    color: #444;
    color: var(--flix-primary-content-color);
    font-size: 16px;
    font-size: var(--flix-font-size-primary);
    font-weight: 400;
    font-weight: var(--flix-font-weight-normal);
    line-height: 1.5em;
    line-height: var(--flix-line-height-primary);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    min-height: 30px;
    padding-right: calc(48px + 12px);
    padding-right: calc(48px + var(--flix-spacing-xs));
    cursor: pointer; }
    @media (min-width: 600px) {
      .flix-switch__label {
        padding-right: 0;
        padding-left: calc(48px + 12px);
        padding-left: calc(48px + var(--flix-spacing-xs)); } }
    .flix-switch__label::before {
      position: absolute;
      top: 50%;
      right: 0;
      width: 48px;
      height: 30px;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      -webkit-transition: background-color 0.4s, -webkit-transform 0.4s;
      transition: background-color 0.4s, -webkit-transform 0.4s;
      transition: transform 0.4s, background-color 0.4s;
      transition: transform 0.4s, background-color 0.4s, -webkit-transform 0.4s;
      border-radius: 30px;
      background-color: #c8c8c8;
      background-color: var(--flix-input-border-color);
      content: ''; }
      @media (min-width: 600px) {
        .flix-switch__label::before {
          right: auto;
          left: 0; } }
    .flix-switch__label::after {
      position: absolute;
      top: 50%;
      right: 18px;
      width: 30px;
      height: 30px;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      -webkit-transition: background 0.4s, -webkit-transform 0.4s;
      transition: background 0.4s, -webkit-transform 0.4s;
      transition: transform 0.4s, background 0.4s;
      transition: transform 0.4s, background 0.4s, -webkit-transform 0.4s;
      border: 2px solid #c8c8c8;
      border: 2px solid var(--flix-input-border-color);
      border-radius: 100%;
      background: #fff url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Cpath d='M0,0h1v1H0' fill='transparent'/%3E%3C/svg%3E") no-repeat center;
      background-size: 15px;
      content: ''; }
      @media (min-width: 600px) {
        .flix-switch__label::after {
          right: auto;
          left: 0; } }
    .flix-switch__input:focus + .flix-switch__label::after {
      outline: 1px solid transparent;
      -webkit-box-shadow: 0 0 3px #00b5e2;
              box-shadow: 0 0 3px #00b5e2;
      -webkit-box-shadow: 0 0 3px var(--flix-neutral-color);
              box-shadow: 0 0 3px var(--flix-neutral-color); }
    .flix-switch__input:checked + .flix-switch__label::after {
      -webkit-transform: translateY(-50%) translateX(18px);
              transform: translateY(-50%) translateX(18px);
      border-color: #73d700;
      border-color: var(--flix-primary-ui-color);
      background: #fff url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M21,4.5a.988.988,0,0,0-.707.293L8,17.086,3.708,12.794A.989.989,0,0,0,3,12.5a1,1,0,0,0-.707,1.707l5,5a1,1,0,0,0,1.414,0l13-13A1,1,0,0,0,21,4.5Zm0,1h0Z' style='fill:%2373d700'/%3E%3C/svg%3E") no-repeat center;
      background: #fff var(--flix-icon-checkmark) no-repeat center;
      background-size: 15px; }
    .flix-switch__input:checked + .flix-switch__label::before {
      background-color: #73d700;
      background-color: var(--flix-primary-ui-color); }
  .flix-switch--sm {
    min-height: 18px;
    line-height: 18px; }
    .flix-switch--sm .flix-switch__label {
      padding-right: calc(30px + 12px);
      padding-right: calc(30px + var(--flix-spacing-xs)); }
      @media (min-width: 600px) {
        .flix-switch--sm .flix-switch__label {
          padding-right: 0;
          padding-left: calc(30px + 12px);
          padding-left: calc(30px + var(--flix-spacing-xs)); } }
      .flix-switch--sm .flix-switch__label::before {
        width: 30px;
        height: 18px;
        border-radius: 18px; }
      .flix-switch--sm .flix-switch__label::after {
        right: 12px;
        width: 18px;
        height: 18px;
        background-size: 9px; }
    .flix-switch--sm .flix-switch__input:checked + .flix-switch__label::after {
      -webkit-transform: translateY(-50%) translateX(12px);
              transform: translateY(-50%) translateX(12px);
      background-size: 9px; }
  .flix-switch--stacked .flix-switch__label {
    padding-bottom: calc(30px + 12px/2);
    padding-bottom: calc(30px + var(--flix-spacing-xs)/2);
    padding-left: 0; }
    .flix-switch--stacked .flix-switch__label::before, .flix-switch--stacked .flix-switch__label::after {
      top: calc(50% + 12px);
      top: calc(50% + var(--flix-spacing-xs));
      bottom: 0;
      left: 0; }
    @media (min-width: 1200px) {
      .flix-switch--stacked .flix-switch__label {
        color: #757575;
        color: var(--flix-secondary-content-color); } }
  .flix-switch--disabled {
    opacity: 0.5;
    opacity: var(--flix-disabled-element-opacity); }
    .flix-switch--disabled .flix-switch__label {
      cursor: not-allowed; }

.flix-table__wrap {
  display: inline-block;
  margin-bottom: 12px;
  margin-bottom: var(--flix-spacing-xs);
  padding: 12px 0;
  padding: var(--flix-spacing-xs) 0;
  border: 1px solid #e1e1e1;
  border: 1px solid var(--flix-primary-line-color);
  border-radius: 6px;
  border-radius: var(--flix-primary-border-radius); }

.flix-table {
  border-collapse: separate;
  border-spacing: 24px 0; }
  @supports (--css: variables) {
    .flix-table {
      border-spacing: 24px 0;
      border-spacing: var(--flix-spacing-sm) 0; } }
  .flix-table__header th,
  .flix-table__header td {
    color: #444;
    color: var(--flix-heading-font-color);
    font-size: 16px;
    font-size: var(--flix-font-size-h4);
    font-weight: 700;
    font-weight: var(--flix-font-weight-bold);
    line-height: 24px;
    line-height: var(--flix-line-height-h4); }
  .flix-table tr:last-child td {
    border-bottom: 0; }

.flix-table__header th,
.flix-table__header td, .flix-table td {
  color: #444;
  color: var(--flix-primary-content-color);
  font-size: 16px;
  font-size: var(--flix-font-size-primary);
  font-weight: 400;
  font-weight: var(--flix-font-weight-normal);
  line-height: 1.5em;
  line-height: var(--flix-line-height-primary);
  padding: 12px 0;
  padding: var(--flix-spacing-xs) 0;
  border-bottom: 1px solid #e1e1e1;
  border-bottom: 1px solid var(--flix-primary-line-color);
  text-align: left; }

.flix-tag {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  max-width: 100%;
  height: 30px;
  padding: 0 12px;
  padding: 0 var(--flix-spacing-xs);
  border: 1px solid #c8c8c8;
  border: 1px solid var(--flix-grayscale-50-color);
  border-radius: 30px;
  outline: 0;
  background: #fff;
  background: var(--flix-grayscale-0-color);
  color: #444;
  color: var(--flix-primary-content-color);
  font-family: inherit;
  font-size: 16px;
  font-size: var(--flix-font-size-primary);
  font-weight: 400;
  font-weight: var(--flix-font-weight-semibold);
  line-height: 28px;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: middle; }
  .flix-tag--success {
    border-color: #73d700;
    border-color: var(--flix-success-color);
    background: #73d700;
    background: var(--flix-success-color);
    color: #fff; }
    .flix-tag--success.flix-tag--outlined {
      background: #fff;
      background: var(--flix-grayscale-0-color);
      color: #73d700;
      color: var(--flix-success-color); }
  .flix-tag--warning {
    border-color: #ffad00;
    border-color: var(--flix-warning-color);
    background: #ffad00;
    background: var(--flix-warning-color);
    color: #fff; }
    .flix-tag--warning.flix-tag--outlined {
      background: #fff;
      background: var(--flix-grayscale-0-color);
      color: #ffad00;
      color: var(--flix-warning-color); }
  .flix-tag--danger {
    border-color: #ff5700;
    border-color: var(--flix-danger-color);
    background: #ff5700;
    background: var(--flix-danger-color);
    color: #fff; }
    .flix-tag--danger.flix-tag--outlined {
      background: #fff;
      background: var(--flix-grayscale-0-color);
      color: #ff5700;
      color: var(--flix-danger-color); }
  .flix-tag--small {
    height: 18px;
    padding: 0 6px;
    padding: 0 var(--flix-spacing-xxs);
    font-size: 14px;
    font-size: var(--flix-font-size-small);
    line-height: 16px; }
    .flix-tag--small .flix-tag__icon {
      font-size: 1em; }
  .flix-tag:disabled, .flix-tag--disabled {
    cursor: not-allowed;
    opacity: 0.5;
    opacity: var(--flix-disabled-element-opacity); }
  button.flix-tag {
    cursor: pointer; }
  .flix-tag__icon:first-child::before {
    margin-left: 0; }
  .flix-tag__text + .flix-tag__icon::before {
    margin-right: 0; }

.flix-text,
.flix-text p {
  color: #444;
  color: var(--flix-primary-content-color);
  font-size: 16px;
  font-size: var(--flix-font-size-primary);
  font-weight: 400;
  font-weight: var(--flix-font-weight-normal);
  line-height: 1.5em;
  line-height: var(--flix-line-height-primary);
  margin: 0 0 12px;
  margin: 0 0 var(--flix-spacing-xs); }

.flix-text--small,
.flix-text--small p {
  color: #444;
  color: var(--flix-primary-content-color);
  font-size: 14px;
  font-size: var(--flix-font-size-small);
  font-weight: 400;
  font-weight: var(--flix-font-weight-normal);
  line-height: 18px;
  line-height: var(--flix-line-height-small);
  margin: 0 0 12px;
  margin: 0 0 var(--flix-spacing-xs); }

.flix-textarea {
  position: relative; }
  .flix-textarea__info {
    color: #444;
    color: var(--flix-primary-content-color);
    font-size: 14px;
    font-size: var(--flix-font-size-small);
    font-weight: 400;
    font-weight: var(--flix-font-weight-normal);
    line-height: 18px;
    line-height: var(--flix-line-height-small);
    display: block;
    padding-top: 3px;
    padding-top: var(--flix-spacing-xxxs); }
  .flix-textarea--error .flix-textarea__info {
    color: #c64300;
    color: var(--flix-input-error-info-color); }
  .flix-textarea__info ~ .flix-textarea__info {
    color: #444;
    color: var(--flix-primary-content-color); }
  .flix-textarea--error .flix-textarea__field,
  .flix-textarea--valid .flix-textarea__field {
    background-repeat: no-repeat;
    background-position: calc(100% - (12px)) 10px;
    background-position: calc(100% - (var(--flix-spacing-xs))) 10px;
    background-size: 24px 24px;
    background-size: var(--flix-spacing-sm) var(--flix-spacing-sm); }
  .flix-textarea--error .flix-textarea__field {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM479.2 270.8A20.8 20.8 0 0 1 520.8 270.8V562.5A20.8 20.8 0 0 1 479.2 562.5ZM500 750A41.7 41.7 0 1 1 541.7 708.3 41.7 41.7 0 0 1 500 750Z' fill='rgba(255, 87, 0, 0.99)'/%3E%3C/svg%3E");
    background-image: var(--flix-icon-attention-solid); }
  .flix-textarea--valid .flix-textarea__field {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM723.1 368.9L431.4 660.6A21.1 21.1 0 0 1 424.6 665 20.1 20.1 0 0 1 417 666.6L416.7 666.7 416.3 666.7A20 20 0 0 1 408.7 665.1 20.8 20.8 0 0 1 401.9 660.7L276.9 535.7A20.8 20.8 0 0 1 306.4 506.2H306.4L416.7 616.4 693.6 339.4H693.6A20.8 20.8 0 0 1 723.1 368.9Z' fill='rgba(115, 215, 0, 0.99)'/%3E%3C/svg%3E");
    background-image: var(--flix-icon-checkmark-solid); }
  .flix-textarea--disabled {
    opacity: 0.5;
    opacity: var(--flix-disabled-element-opacity); }
  .flix-textarea__field {
    position: relative;
    width: 100%;
    min-height: calc(44px * 2 + 24px);
    min-height: calc(var(--flix-input-height-mobile) * 2 + var(--flix-spacing-sm));
    padding: 10px 12px;
    padding: 10px var(--flix-spacing-xs);
    border: 1px solid #c8c8c8;
    border: 1px solid var(--flix-input-border-color);
    border-radius: 6px;
    border-radius: var(--flix-input-border-radius);
    background-color: #fff;
    background-color: var(--flix-input-bg-color);
    color: #444;
    color: var(--flix-primary-content-color);
    font-family: inherit;
    font-size: 16px;
    font-size: var(--flix-font-size-primary);
    font-weight: 400;
    font-weight: var(--flix-font-weight-normal);
    line-height: 1.5em;
    line-height: var(--flix-line-height-primary);
    overflow: auto;
    vertical-align: top;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none; }
    @media (min-width: 1200px) {
      .flix-textarea__field {
        min-height: calc(36px * 2 + 24px + 24px);
        min-height: calc(var(--flix-input-height-desktop) * 2 + var(--flix-spacing-sm) + 24px);
        padding: 5px 12px;
        padding: 5px var(--flix-spacing-xs); }
        .flix-textarea--valid .flix-textarea__field,
        .flix-textarea--error .flix-textarea__field {
          background-position-y: 5px; } }
    .flix-textarea__field::-webkit-input-placeholder {
      color: #757575;
      color: var(--flix-secondary-content-color);
      font-style: italic; }
    .flix-textarea__field::-moz-placeholder {
      color: #757575;
      color: var(--flix-secondary-content-color);
      font-style: italic; }
    .flix-textarea__field:-ms-input-placeholder {
      color: #757575;
      color: var(--flix-secondary-content-color);
      font-style: italic; }
    .flix-textarea__field::-ms-input-placeholder {
      color: #757575;
      color: var(--flix-secondary-content-color);
      font-style: italic; }
    .flix-textarea__field::placeholder {
      color: #757575;
      color: var(--flix-secondary-content-color);
      font-style: italic; }
    .flix-textarea--valid .flix-textarea__field {
      padding-right: 40px; }
    .flix-textarea--error .flix-textarea__field {
      padding-right: 40px;
      border: 1px solid #ff5700;
      border: 1px solid var(--flix-input-error-border-color); }
    .flix-textarea__field:focus,
    .flix-textarea--active .flix-textarea__field {
      border: 1px solid #757575;
      border: 1px solid var(--flix-input-active-border-color);
      outline: none;
      color: #444;
      color: var(--flix-primary-content-color); }
    .flix-textarea__field[disabled],
    .flix-textarea--disabled .flix-textarea__field {
      cursor: not-allowed; }

.flix-time-input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  width: 100%;
  border: 0; }
  .flix-time-input__info {
    color: #444;
    color: var(--flix-primary-content-color);
    font-size: 14px;
    font-size: var(--flix-font-size-small);
    font-weight: 400;
    font-weight: var(--flix-font-weight-normal);
    line-height: 18px;
    line-height: var(--flix-line-height-small);
    display: block;
    padding-top: 3px;
    padding-top: var(--flix-spacing-xxxs); }
  .flix-time-input--error .flix-time-input__info {
    color: #c64300;
    color: var(--flix-input-error-info-color); }
  .flix-time-input__info ~ .flix-time-input__info {
    color: #444;
    color: var(--flix-primary-content-color); }
  .flix-time-input--error .flix-time-input__field {
    border-color: #ff5700;
    border-color: var(--flix-input-error-border-color); }
  .flix-time-input--disabled {
    opacity: 0.5;
    opacity: var(--flix-disabled-element-opacity); }
    .flix-time-input--disabled__field {
      cursor: not-allowed; }
  @media (min-width: 1200px) {
    .flix-time-input__label {
      width: 100%; } }
  .flix-time-input__select {
    position: relative;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    min-width: 72px; }
    .flix-time-input__select--error .flix-time-input__select__field,
    .flix-time-input__select--valid .flix-time-input__select__field {
      background-repeat: no-repeat;
      background-position: calc(100% - (25px + 24px)) center;
      background-position: calc(100% - (25px + var(--flix-spacing-sm))) center;
      background-size: 24px 24px;
      background-size: var(--flix-spacing-sm) var(--flix-spacing-sm); }
    .flix-time-input__select--error .flix-time-input__select__field {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM479.2 270.8A20.8 20.8 0 0 1 520.8 270.8V562.5A20.8 20.8 0 0 1 479.2 562.5ZM500 750A41.7 41.7 0 1 1 541.7 708.3 41.7 41.7 0 0 1 500 750Z' fill='rgba(255, 87, 0, 0.99)'/%3E%3C/svg%3E");
      background-image: var(--flix-icon-attention-solid); }
    .flix-time-input__select--valid .flix-time-input__select__field {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM723.1 368.9L431.4 660.6A21.1 21.1 0 0 1 424.6 665 20.1 20.1 0 0 1 417 666.6L416.7 666.7 416.3 666.7A20 20 0 0 1 408.7 665.1 20.8 20.8 0 0 1 401.9 660.7L276.9 535.7A20.8 20.8 0 0 1 306.4 506.2H306.4L416.7 616.4 693.6 339.4H693.6A20.8 20.8 0 0 1 723.1 368.9Z' fill='rgba(115, 215, 0, 0.99)'/%3E%3C/svg%3E");
      background-image: var(--flix-icon-checkmark-solid); }
    .flix-time-input__select--disabled {
      opacity: 0.5;
      opacity: var(--flix-disabled-element-opacity); }
    .flix-time-input__select::after {
      display: inline-block;
      width: 24px;
      width: var(--flix-spacing-sm);
      height: 24px;
      height: var(--flix-spacing-sm);
      content: '';
      vertical-align: middle;
      position: absolute;
      top: 10px;
      right: 12px;
      right: var(--flix-spacing-xs);
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M485.3 618L279.2 411.7A20.8 20.8 0 1 1 308.3 382.2L500 573.7 691.7 382A20.8 20.8 0 0 1 721.1 411.5L515.3 617.3C515.1 617.3 515.1 617.7 514.9 617.9A20.8 20.8 0 0 1 485.3 618Z' fill='rgba(139, 139, 139, 0.99)'/%3E%3C/svg%3E") no-repeat center;
      background: var(--flix-icon-arrow-down) no-repeat center;
      pointer-events: none; }
      @media (min-width: 1200px) {
        .flix-time-input__select::after {
          top: 30px; } }
    .flix-time-input__select--valid .flix-time-input__select__field {
      padding-right: 80px; }
    .flix-time-input__select--error .flix-time-input__select__field {
      padding-right: 80px;
      border: 1px solid #ff5700;
      border: 1px solid var(--flix-input-error-border-color); }
    .flix-time-input__select--active .flix-time-input__select__field {
      border: 1px solid #757575;
      border: 1px solid var(--flix-input-active-border-color);
      outline: none; }
    .flix-time-input__select--disabled .flix-time-input__select__field {
      cursor: not-allowed; }
    @media (min-width: 1200px) {
      .flix-time-input__select::after {
        top: 50%;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%); } }
  .flix-time-input__field {
    position: relative;
    width: 100%;
    height: 44px;
    height: var(--flix-input-height-mobile);
    padding: 0 12px;
    padding: 0 var(--flix-spacing-xs);
    border: 1px solid #c8c8c8;
    border: 1px solid var(--flix-input-border-color);
    border-radius: 6px;
    border-radius: var(--flix-input-border-radius);
    background-color: #fff;
    background-color: var(--flix-input-bg-color);
    color: #444;
    color: var(--flix-primary-content-color);
    font-family: inherit;
    font-size: 16px;
    font-size: var(--flix-font-size-primary);
    font-weight: 400;
    font-weight: var(--flix-font-weight-normal);
    line-height: var(--flix-line-height-mobile);
    text-overflow: ellipsis;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    padding-right: 6px;
    padding-right: var(--flix-spacing-xxs);
    background: transparent;
    padding-right: 36px;
    border-radius: 0; }
    @media (min-width: 1200px) {
      .flix-time-input__field {
        height: 36px;
        height: var(--flix-input-height-desktop);
        line-height: var(--flix-line-height-desktop); } }
    .flix-time-input__field::-webkit-input-placeholder {
      color: #757575;
      color: var(--flix-input-placeholder-color);
      font-style: italic; }
    .flix-time-input__field::-moz-placeholder {
      color: #757575;
      color: var(--flix-input-placeholder-color);
      font-style: italic; }
    .flix-time-input__field:-ms-input-placeholder {
      color: #757575;
      color: var(--flix-input-placeholder-color);
      font-style: italic; }
    .flix-time-input__field::-ms-input-placeholder {
      color: #757575;
      color: var(--flix-input-placeholder-color);
      font-style: italic; }
    .flix-time-input__field::placeholder {
      color: #757575;
      color: var(--flix-input-placeholder-color);
      font-style: italic; }
    .flix-time-input__field:hover, .flix-time-input__field:active, .flix-time-input__field:focus {
      outline: 0; }
    .flix-time-input__field::-ms-expand {
      display: none; }
    .flix-time-input__field:focus {
      border: 1px solid #757575;
      border: 1px solid var(--flix-input-active-border-color);
      outline: none; }
    .flix-time-input__field[disabled] {
      cursor: not-allowed; }
    .flix-time-input__field option,
    .flix-time-input__field optgroup {
      background: #fff;
      background: var(--flix-box-bg-color);
      color: #444;
      color: var(--flix-primary-content-color); }
    .flix-time-input__select:first-of-type .flix-time-input__field {
      margin-left: 0;
      border-radius: 6px 0 0 6px;
      border-radius: var(--flix-input-border-radius) 0 0 var(--flix-input-border-radius); }
    .flix-time-input__select:last-of-type .flix-time-input__field {
      border-radius: 0 6px 6px 0;
      border-radius: 0 var(--flix-input-border-radius) var(--flix-input-border-radius) 0; }
    .flix-time-input__select:only-of-type .flix-time-input__field {
      border-radius: 6px;
      border-radius: var(--flix-input-border-radius); }
  .flix-time-input__info {
    display: block;
    width: 100%; }

.flix-tooltip {
  position: absolute;
  width: 250px;
  min-height: 44px;
  padding: 12px;
  padding: var(--flix-spacing-xs);
  color: #444;
  color: var(--flix-primary-content-color);
  font-family: inherit;
  font-size: 16px;
  font-size: var(--flix-font-size-primary);
  text-decoration: none;
  z-index: 10;
  left: 100%;
  padding-left: 24px;
  padding-left: var(--flix-spacing-sm);
  top: 50%;
  bottom: auto;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  display: none;
  -webkit-transition: opacity 200ms;
  transition: opacity 200ms;
  opacity: 0; }
  .flix-tooltip::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 6px;
    border-radius: var(--flix-primary-border-radius);
    background: #fff;
    background: var(--flix-box-bg-color);
    -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.12);
            box-shadow: 0 0 12px rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: var(--flix-primary-box-shadow);
            box-shadow: var(--flix-primary-box-shadow);
    content: ''; }
  .flix-tooltip::before {
    left: 12px;
    left: var(--flix-spacing-xs); }
  .flix-tooltip::before {
    z-index: 0; }
  .flix-tooltip::after {
    position: absolute;
    z-index: 0;
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-right: 6px solid #fff;
    border-right: 6px solid var(--flix-box-bg-color);
    border-bottom: 6px solid transparent;
    border-left: 0;
    content: ''; }
  .flix-tooltip--small {
    width: 150px;
    min-height: auto; }
    @supports ((width: -webkit-max-content) or (width: -moz-max-content) or (width: max-content)) {
      .flix-tooltip--small {
        width: auto;
        white-space: nowrap; } }
  .flix-tooltip--error {
    color: #fff;
    color: var(--flix-primary-bg-color); }
    .flix-tooltip--error::before {
      background: #ff5700;
      background: var(--flix-danger-color); }
    .flix-tooltip--error::after {
      width: 0;
      height: 0;
      border-top: 6px solid transparent;
      border-right: 6px solid #ff5700;
      border-right: 6px solid var(--flix-danger-color);
      border-bottom: 6px solid transparent;
      border-left: 0;
      content: ''; }
  .flix-tooltip::after {
    right: auto;
    left: 6px;
    left: var(--flix-spacing-xxs);
    top: 50%;
    bottom: auto;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
  .flix-tooltip--start {
    top: calc(-1 * 6px);
    top: calc(-1 * var(--flix-spacing-xxs));
    bottom: auto;
    -webkit-transform: none;
            transform: none; }
    .flix-tooltip--start::after {
      top: 18px;
      top: var(--flix-spacing-smxs);
      -webkit-transform: none;
              transform: none; }
  .flix-tooltip--end {
    top: auto;
    bottom: calc(-1 * 6px);
    bottom: calc(-1 * var(--flix-spacing-xxs));
    -webkit-transform: none;
            transform: none; }
    .flix-tooltip--end::after {
      top: auto;
      bottom: 18px;
      bottom: var(--flix-spacing-smxs);
      -webkit-transform: none;
              transform: none; }
  .flix-tooltip--active {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    opacity: 1; }
  .flix-tooltip--left {
    right: 100%;
    left: auto;
    padding-right: 24px;
    padding-right: var(--flix-spacing-sm);
    padding-left: 12px;
    padding-left: var(--flix-spacing-xs);
    top: 50%;
    bottom: auto;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
    .flix-tooltip--left::before {
      right: 12px;
      right: var(--flix-spacing-xs);
      left: 0; }
    .flix-tooltip--left::after {
      width: 0;
      height: 0;
      border-top: 6px solid transparent;
      border-right: 0;
      border-bottom: 6px solid transparent;
      border-left: 6px solid #fff;
      border-left: 6px solid var(--flix-box-bg-color);
      content: '';
      right: 6px;
      right: var(--flix-spacing-xxs);
      left: auto;
      top: 50%;
      bottom: auto;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%); }
    .flix-tooltip--left.flix-tooltip--error::after {
      width: 0;
      height: 0;
      border-top: 6px solid transparent;
      border-right: 0;
      border-bottom: 6px solid transparent;
      border-left: 6px solid #ff5700;
      border-left: 6px solid var(--flix-danger-color);
      content: ''; }
    .flix-tooltip--left.flix-tooltip--start {
      top: calc(-1 * 6px);
      top: calc(-1 * var(--flix-spacing-xxs));
      bottom: auto;
      -webkit-transform: none;
              transform: none; }
      .flix-tooltip--left.flix-tooltip--start::after {
        top: 18px;
        top: var(--flix-spacing-smxs);
        -webkit-transform: none;
                transform: none; }
    .flix-tooltip--left.flix-tooltip--end {
      top: auto;
      bottom: calc(-1 * 6px);
      bottom: calc(-1 * var(--flix-spacing-xxs));
      -webkit-transform: none;
              transform: none; }
      .flix-tooltip--left.flix-tooltip--end::after {
        top: auto;
        bottom: 18px;
        bottom: var(--flix-spacing-smxs);
        -webkit-transform: none;
                transform: none; }
  .flix-tooltip--top {
    top: auto;
    bottom: 100%;
    padding-bottom: 24px;
    padding-bottom: var(--flix-spacing-sm);
    padding-left: 12px;
    padding-left: var(--flix-spacing-xs);
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); }
    .flix-tooltip--top::before {
      bottom: 12px;
      bottom: var(--flix-spacing-xs);
      left: 0; }
    .flix-tooltip--top::after {
      width: 0;
      height: 0;
      border-top: 6px solid #fff;
      border-top: 6px solid var(--flix-box-bg-color);
      border-right: 6px solid transparent;
      border-bottom: 0;
      border-left: 6px solid transparent;
      content: '';
      top: auto;
      bottom: 6px;
      bottom: var(--flix-spacing-xxs);
      right: auto;
      left: 50%;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%); }
    .flix-tooltip--top.flix-tooltip--error::after {
      width: 0;
      height: 0;
      border-top: 6px solid #ff5700;
      border-top: 6px solid var(--flix-danger-color);
      border-right: 6px solid transparent;
      border-bottom: 0;
      border-left: 6px solid transparent;
      content: ''; }
    .flix-tooltip--top.flix-tooltip--start {
      left: 50%;
      -webkit-transform: translateX(calc(-1 * 24px));
              transform: translateX(calc(-1 * 24px));
      -webkit-transform: translateX(calc(-1 * var(--flix-spacing-sm)));
              transform: translateX(calc(-1 * var(--flix-spacing-sm))); }
      .flix-tooltip--top.flix-tooltip--start::after {
        left: 18px;
        left: var(--flix-spacing-smxs);
        -webkit-transform: none;
                transform: none; }
    .flix-tooltip--top.flix-tooltip--end {
      right: 50%;
      left: auto;
      -webkit-transform: translateX(24px);
              transform: translateX(24px);
      -webkit-transform: translateX(var(--flix-spacing-sm));
              transform: translateX(var(--flix-spacing-sm)); }
      .flix-tooltip--top.flix-tooltip--end::after {
        right: 18px;
        right: var(--flix-spacing-smxs);
        left: auto;
        -webkit-transform: none;
                transform: none; }
  .flix-tooltip--bottom {
    top: 100%;
    bottom: auto;
    padding-top: 24px;
    padding-top: var(--flix-spacing-sm);
    padding-left: 12px;
    padding-left: var(--flix-spacing-xs);
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); }
    .flix-tooltip--bottom::before {
      top: 12px;
      top: var(--flix-spacing-xs);
      left: 0; }
    .flix-tooltip--bottom::after {
      width: 0;
      height: 0;
      border-top: 0;
      border-right: 6px solid transparent;
      border-bottom: 6px solid #fff;
      border-bottom: 6px solid var(--flix-box-bg-color);
      border-left: 6px solid transparent;
      content: '';
      top: 6px;
      top: var(--flix-spacing-xxs);
      bottom: auto;
      right: auto;
      left: 50%;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%); }
    .flix-tooltip--bottom.flix-tooltip--error::after {
      width: 0;
      height: 0;
      border-top: 0;
      border-right: 6px solid transparent;
      border-bottom: 6px solid #ff5700;
      border-bottom: 6px solid var(--flix-danger-color);
      border-left: 6px solid transparent;
      content: ''; }
    .flix-tooltip--bottom.flix-tooltip--start {
      left: 50%;
      -webkit-transform: translateX(calc(-1 * 24px));
              transform: translateX(calc(-1 * 24px));
      -webkit-transform: translateX(calc(-1 * var(--flix-spacing-sm)));
              transform: translateX(calc(-1 * var(--flix-spacing-sm))); }
      .flix-tooltip--bottom.flix-tooltip--start::after {
        left: 18px;
        left: var(--flix-spacing-smxs);
        -webkit-transform: none;
                transform: none; }
    .flix-tooltip--bottom.flix-tooltip--end {
      right: 50%;
      left: auto;
      -webkit-transform: translateX(24px);
              transform: translateX(24px);
      -webkit-transform: translateX(var(--flix-spacing-sm));
              transform: translateX(var(--flix-spacing-sm)); }
      .flix-tooltip--bottom.flix-tooltip--end::after {
        right: 18px;
        right: var(--flix-spacing-smxs);
        left: auto;
        -webkit-transform: none;
                transform: none; }
  .flix-tooltip__content {
    position: relative;
    -webkit-box-flex: 1;
        -ms-flex: auto;
            flex: auto;
    text-align: left;
    z-index: 1; }
    .flix-tooltip--small .flix-tooltip__content {
      margin: calc(-1 * 6px);
      margin: calc(-1 * var(--flix-spacing-xxs)); }
  .flix-tooltip__close {
    border: 0;
    background: transparent;
    font-size: 16px;
    font-size: var(--flix-font-size-primary);
    cursor: pointer;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    display: inline-block;
    width: 24px;
    width: var(--flix-spacing-sm);
    height: 24px;
    height: var(--flix-spacing-sm);
    content: '';
    vertical-align: middle;
    position: relative;
    -ms-flex-item-align: start;
        align-self: flex-start;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M827.2 797.8A20.8 20.8 0 0 1 797.8 827.2L500 529.5 202.3 827.2A20.8 20.8 0 0 1 172.8 797.8H172.8L470.5 500 172.8 202.3A20.8 20.8 0 0 1 202.3 172.8H202.3L500 470.5 797.8 172.7H797.8A20.8 20.8 0 0 1 827.2 202.2L529.5 500 827.2 797.8Z' fill='rgba(139, 139, 139, 0.99)'/%3E%3C/svg%3E") no-repeat center;
    background: var(--flix-icon-close) no-repeat center;
    background-size: 20px;
    z-index: 1; }
    .flix-tooltip__close .flix-icon {
      display: none; }
  .flix-tooltip--error .flix-tooltip__close {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M827.2 797.8A20.8 20.8 0 0 1 797.8 827.2L500 529.5 202.3 827.2A20.8 20.8 0 0 1 172.8 797.8H172.8L470.5 500 172.8 202.3A20.8 20.8 0 0 1 202.3 172.8H202.3L500 470.5 797.8 172.7H797.8A20.8 20.8 0 0 1 827.2 202.2L529.5 500 827.2 797.8Z' fill='rgba(255, 255, 255, 0.99)'/%3E%3C/svg%3E");
    background-image: var(--flix-icon-panel-close); }

.flix-tooltip-target {
  position: relative; }
  .flix-tooltip-target--inline {
    display: inline-block; }

.flix-grid-container {
  padding-right: 6px;
  padding-left: 6px; }

.flix-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  width: calc(100% + 12px);
  margin-right: -6px;
  margin-left: -6px; }
  .flix-grid--align-bottom {
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end; }
  .flix-grid--align-top {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start; }
  .flix-grid--align-center {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .flix-grid--justify-center {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
  .flix-grid--justify-left {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
  .flix-grid--justify-right {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end; }
  .flix-grid--justify-space-between {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }

.flix-col-1 {
  width: 8.33333%;
  padding-right: 6px;
  padding-left: 6px; }

.flix-push-1 {
  margin-left: 8.33333%; }

.flix-col-2 {
  width: 16.66667%;
  padding-right: 6px;
  padding-left: 6px; }

.flix-push-2 {
  margin-left: 16.66667%; }

.flix-col-3 {
  width: 25%;
  padding-right: 6px;
  padding-left: 6px; }

.flix-push-3 {
  margin-left: 25%; }

.flix-col-4 {
  width: 33.33333%;
  padding-right: 6px;
  padding-left: 6px; }

.flix-push-4 {
  margin-left: 33.33333%; }

.flix-col-5 {
  width: 41.66667%;
  padding-right: 6px;
  padding-left: 6px; }

.flix-push-5 {
  margin-left: 41.66667%; }

.flix-col-6 {
  width: 50%;
  padding-right: 6px;
  padding-left: 6px; }

.flix-push-6 {
  margin-left: 50%; }

.flix-col-7 {
  width: 58.33333%;
  padding-right: 6px;
  padding-left: 6px; }

.flix-push-7 {
  margin-left: 58.33333%; }

.flix-col-8 {
  width: 66.66667%;
  padding-right: 6px;
  padding-left: 6px; }

.flix-push-8 {
  margin-left: 66.66667%; }

.flix-col-9 {
  width: 75%;
  padding-right: 6px;
  padding-left: 6px; }

.flix-push-9 {
  margin-left: 75%; }

.flix-col-10 {
  width: 83.33333%;
  padding-right: 6px;
  padding-left: 6px; }

.flix-push-10 {
  margin-left: 83.33333%; }

.flix-col-11 {
  width: 91.66667%;
  padding-right: 6px;
  padding-left: 6px; }

.flix-push-11 {
  margin-left: 91.66667%; }

.flix-col-12 {
  width: 100%;
  padding-right: 6px;
  padding-left: 6px; }

.flix-push-12 {
  margin-left: 100%; }

@media (min-width: 600px) {
  .flix-col-1-sm {
    width: 8.33333%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-1-sm {
    margin-left: 8.33333%; }
  .flix-col-2-sm {
    width: 16.66667%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-2-sm {
    margin-left: 16.66667%; }
  .flix-col-3-sm {
    width: 25%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-3-sm {
    margin-left: 25%; }
  .flix-col-4-sm {
    width: 33.33333%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-4-sm {
    margin-left: 33.33333%; }
  .flix-col-5-sm {
    width: 41.66667%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-5-sm {
    margin-left: 41.66667%; }
  .flix-col-6-sm {
    width: 50%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-6-sm {
    margin-left: 50%; }
  .flix-col-7-sm {
    width: 58.33333%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-7-sm {
    margin-left: 58.33333%; }
  .flix-col-8-sm {
    width: 66.66667%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-8-sm {
    margin-left: 66.66667%; }
  .flix-col-9-sm {
    width: 75%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-9-sm {
    margin-left: 75%; }
  .flix-col-10-sm {
    width: 83.33333%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-10-sm {
    margin-left: 83.33333%; }
  .flix-col-11-sm {
    width: 91.66667%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-11-sm {
    margin-left: 91.66667%; }
  .flix-col-12-sm {
    width: 100%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-12-sm {
    margin-left: 100%; } }

@media (min-width: 768px) {
  .flix-col-1-md {
    width: 8.33333%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-1-md {
    margin-left: 8.33333%; }
  .flix-col-2-md {
    width: 16.66667%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-2-md {
    margin-left: 16.66667%; }
  .flix-col-3-md {
    width: 25%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-3-md {
    margin-left: 25%; }
  .flix-col-4-md {
    width: 33.33333%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-4-md {
    margin-left: 33.33333%; }
  .flix-col-5-md {
    width: 41.66667%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-5-md {
    margin-left: 41.66667%; }
  .flix-col-6-md {
    width: 50%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-6-md {
    margin-left: 50%; }
  .flix-col-7-md {
    width: 58.33333%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-7-md {
    margin-left: 58.33333%; }
  .flix-col-8-md {
    width: 66.66667%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-8-md {
    margin-left: 66.66667%; }
  .flix-col-9-md {
    width: 75%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-9-md {
    margin-left: 75%; }
  .flix-col-10-md {
    width: 83.33333%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-10-md {
    margin-left: 83.33333%; }
  .flix-col-11-md {
    width: 91.66667%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-11-md {
    margin-left: 91.66667%; }
  .flix-col-12-md {
    width: 100%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-12-md {
    margin-left: 100%; } }

@media (min-width: 1024px) {
  .flix-col-1-lg {
    width: 8.33333%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-1-lg {
    margin-left: 8.33333%; }
  .flix-col-2-lg {
    width: 16.66667%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-2-lg {
    margin-left: 16.66667%; }
  .flix-col-3-lg {
    width: 25%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-3-lg {
    margin-left: 25%; }
  .flix-col-4-lg {
    width: 33.33333%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-4-lg {
    margin-left: 33.33333%; }
  .flix-col-5-lg {
    width: 41.66667%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-5-lg {
    margin-left: 41.66667%; }
  .flix-col-6-lg {
    width: 50%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-6-lg {
    margin-left: 50%; }
  .flix-col-7-lg {
    width: 58.33333%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-7-lg {
    margin-left: 58.33333%; }
  .flix-col-8-lg {
    width: 66.66667%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-8-lg {
    margin-left: 66.66667%; }
  .flix-col-9-lg {
    width: 75%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-9-lg {
    margin-left: 75%; }
  .flix-col-10-lg {
    width: 83.33333%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-10-lg {
    margin-left: 83.33333%; }
  .flix-col-11-lg {
    width: 91.66667%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-11-lg {
    margin-left: 91.66667%; }
  .flix-col-12-lg {
    width: 100%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-12-lg {
    margin-left: 100%; } }

@media (min-width: 1200px) {
  .flix-col-1-xl {
    width: 8.33333%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-1-xl {
    margin-left: 8.33333%; }
  .flix-col-2-xl {
    width: 16.66667%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-2-xl {
    margin-left: 16.66667%; }
  .flix-col-3-xl {
    width: 25%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-3-xl {
    margin-left: 25%; }
  .flix-col-4-xl {
    width: 33.33333%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-4-xl {
    margin-left: 33.33333%; }
  .flix-col-5-xl {
    width: 41.66667%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-5-xl {
    margin-left: 41.66667%; }
  .flix-col-6-xl {
    width: 50%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-6-xl {
    margin-left: 50%; }
  .flix-col-7-xl {
    width: 58.33333%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-7-xl {
    margin-left: 58.33333%; }
  .flix-col-8-xl {
    width: 66.66667%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-8-xl {
    margin-left: 66.66667%; }
  .flix-col-9-xl {
    width: 75%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-9-xl {
    margin-left: 75%; }
  .flix-col-10-xl {
    width: 83.33333%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-10-xl {
    margin-left: 83.33333%; }
  .flix-col-11-xl {
    width: 91.66667%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-11-xl {
    margin-left: 91.66667%; }
  .flix-col-12-xl {
    width: 100%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-12-xl {
    margin-left: 100%; } }

.flix-col {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin: 0 6px; }
  .flix-col--inline {
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0; }

.flix-main-wrapper {
  position: relative;
  min-height: 100%;
  margin-top: 0;
  padding-bottom: 0;
  background: #fff;
  background: var(--flix-primary-bg-color); }
  .flix-main-wrapper--full {
    min-height: 100vh; }

.flix-page-container {
  min-width: 320px;
  min-width: var(--flix-page-min-width);
  max-width: 1200px;
  max-width: var(--flix-page-max-width);
  margin: 0 auto;
  padding: 0 12px;
  padding: 0 var(--flix-spacing-xs); }

.flix-sr-only, .flix-sr-only-focusable {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  border-width: 0 !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  -webkit-clip-path: inset(50%) !important;
          clip-path: inset(50%) !important; }

.flix-sr-only-focusable:focus, .flix-sr-only-focusable:active {
  position: initial !important;
  width: auto !important;
  height: auto !important;
  margin: auto !important;
  white-space: normal !important;
  overflow: visible !important;
  clip: auto !important;
  -webkit-clip-path: none !important;
          clip-path: none !important; }

.flix-has-text-centered {
  text-align: center; }

.flix-has-text-left {
  text-align: left; }

.flix-has-text-right {
  text-align: right; }

.flix-hidden {
  display: none; }

@media (min-width: 0) and (max-width: 599px) {
  .flix-hidden-xs {
    display: none; } }

@media (min-width: 600px) and (max-width: 767px) {
  .flix-hidden-sm {
    display: none; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .flix-hidden-md {
    display: none; } }

@media (min-width: 1024px) and (max-width: 1199px) {
  .flix-hidden-lg {
    display: none; } }

@media (min-width: 1200px) {
  .flix-hidden-xl {
    display: none; } }

.flix-space-xxxs-top {
  margin-top: 3px;
  margin-top: var(--flix-spacing-xxxs); }

.flix-space-xxxs-bottom {
  margin-bottom: 3px;
  margin-bottom: var(--flix-spacing-xxxs); }

.flix-space-xxxs-left {
  margin-left: 3px;
  margin-left: var(--flix-spacing-xxxs); }

.flix-space-xxxs-right {
  margin-right: 3px;
  margin-right: var(--flix-spacing-xxxs); }

.flix-space-xxs-top {
  margin-top: 6px;
  margin-top: var(--flix-spacing-xxs); }

.flix-space-xxs-bottom {
  margin-bottom: 6px;
  margin-bottom: var(--flix-spacing-xxs); }

.flix-space-xxs-left {
  margin-left: 6px;
  margin-left: var(--flix-spacing-xxs); }

.flix-space-xxs-right {
  margin-right: 6px;
  margin-right: var(--flix-spacing-xxs); }

.flix-space-xs-top {
  margin-top: 12px;
  margin-top: var(--flix-spacing-xs); }

.flix-space-xs-bottom {
  margin-bottom: 12px;
  margin-bottom: var(--flix-spacing-xs); }

.flix-space-xs-left {
  margin-left: 12px;
  margin-left: var(--flix-spacing-xs); }

.flix-space-xs-right {
  margin-right: 12px;
  margin-right: var(--flix-spacing-xs); }

.flix-space-smxs-top {
  margin-top: 18px;
  margin-top: var(--flix-spacing-smxs); }

.flix-space-smxs-bottom {
  margin-bottom: 18px;
  margin-bottom: var(--flix-spacing-smxs); }

.flix-space-smxs-left {
  margin-left: 18px;
  margin-left: var(--flix-spacing-smxs); }

.flix-space-smxs-right {
  margin-right: 18px;
  margin-right: var(--flix-spacing-smxs); }

.flix-space-sm-top {
  margin-top: 24px;
  margin-top: var(--flix-spacing-sm); }

.flix-space-sm-bottom {
  margin-bottom: 24px;
  margin-bottom: var(--flix-spacing-sm); }

.flix-space-sm-left {
  margin-left: 24px;
  margin-left: var(--flix-spacing-sm); }

.flix-space-sm-right {
  margin-right: 24px;
  margin-right: var(--flix-spacing-sm); }

.flix-space-md-top {
  margin-top: 36px;
  margin-top: var(--flix-spacing-md); }

.flix-space-md-bottom {
  margin-bottom: 36px;
  margin-bottom: var(--flix-spacing-md); }

.flix-space-md-left {
  margin-left: 36px;
  margin-left: var(--flix-spacing-md); }

.flix-space-md-right {
  margin-right: 36px;
  margin-right: var(--flix-spacing-md); }

.flix-space-lg-top {
  margin-top: 48px;
  margin-top: var(--flix-spacing-lg); }

.flix-space-lg-bottom {
  margin-bottom: 48px;
  margin-bottom: var(--flix-spacing-lg); }

.flix-space-lg-left {
  margin-left: 48px;
  margin-left: var(--flix-spacing-lg); }

.flix-space-lg-right {
  margin-right: 48px;
  margin-right: var(--flix-spacing-lg); }

.flix-space-xl-top {
  margin-top: 72px;
  margin-top: var(--flix-spacing-xl); }

.flix-space-xl-bottom {
  margin-bottom: 72px;
  margin-bottom: var(--flix-spacing-xl); }

.flix-space-xl-left {
  margin-left: 72px;
  margin-left: var(--flix-spacing-xl); }

.flix-space-xl-right {
  margin-right: 72px;
  margin-right: var(--flix-spacing-xl); }

.flix-space-flush-top {
  margin-top: 0; }

.flix-space-flush-bottom {
  margin-bottom: 0; }

.flix-space-flush-left {
  margin-left: 0; }

.flix-space-flush-right {
  margin-right: 0; }


